export const changedFilter = (dataPath, payload) => ({
  type: `CHANGED_${dataPath}_QUERY`.toUpperCase(),
  payload: {
    receivedAt: Date.now(),
    ...payload,
  },
});

export const updatedQuery = payload => ({
  type: 'UPDATED_QUERY',
  payload: {
    receivedAt: Date.now(),
    ...payload,
  },
});

const requestData = dataPath => ({
  type: `REQUEST_${dataPath}`.toUpperCase(),
  payload: {
    isFetching: true,
  },
});

const receiveData = (dataPath, data) => ({
  type: `RECEIVE_${dataPath}`.toUpperCase(),
  payload: {
    data,
    isFetching: false,
    didInvalidate: false,
    receivedAt: Date.now(),
  },
});

export const patchData = (dataPath, dataWithNulls) => {
  const data = Array.isArray(dataWithNulls)
    ? dataWithNulls
    : Object.entries(dataWithNulls).reduce((result, [key, value]) => {
        if (value === null) {
          return result;
        }
        return {
          ...result,
          [key]: value,
        };
      }, {});

  return {
    type: `RECEIVE_${dataPath}`.toUpperCase(),
    payload: {
      data,
      isFetching: false,
      didInvalidate: false,
      receivedAt: Date.now(),
    },
  };
};

const receiveError = ({ statusCode, message }) => ({
  type: 'RECEIVE_ERROR',
  payload: {
    isFetching: false,
    didInvalidate: false,
    receivedAt: Date.now(),
    statusCode,
    message,
  },
});

export const invalidateStore = dataPath => {
  return {
    type: `INVALIDATE_${dataPath}`.toUpperCase(),
    payload: {
      isFetching: false,
      didInvalidate: true,
      receivedAt: 0,
    },
  };
};

export const revalidateStore = dataPath => {
  return {
    type: `REVALIDATE_${dataPath}`.toUpperCase(),
    payload: {
      didInvalidate: false,
    },
  };
};

const getUrlData = (dataPath, { payload, query, user }) => {
  switch (dataPath) {
    case 'user':
      return ['/api/centralgrading/v1/getAuthUserInfo'];
    case 'filters':
      return [
        '/api/centralgrading/v1/prepareSubmissionList',
        {
          role: user?.data?.role,
        },
      ];
    case 'adminFilters':
      return ['/api/centralgrading/v1/prepareUserAdmin'];
    case 'searchStudents':
      return [
        '/api/centralgrading/v1/searchStudents',
        {
          searchText: payload,
          role: user?.data?.role,
        },
      ];
    case 'students':
      return [
        '/api/centralgrading/v1/listStudents',
        {
          idList: query.students,
        },
      ];
    case 'graders':
      return [
        '/api/centralgrading/v1/listGrader',
        {
          searchText: payload || '',
        },
      ];
    case 'queue':
      const claimed = query.pathname.endsWith('claimed');
      const resultsPerPage = claimed ? 200 : 10;
      return [
        claimed
          ? '/api/centralgrading/v1/myClaimedSubmissions'
          : '/api/centralgrading/v1/submissions',
        {
          assignments: query.assignments,
          claimerId: query.claimerId,
          courses: query.courses,
          externalAppIds: query.selectedExternalAppIds,
          offset: query.offset,
          programs: query.programs,
          resultsPerPage,
          role: user?.data?.role,
          statusList: query.statusList,
          students: query.students,
          submissionDateFrom: query.startDate,
          submissionDateTo: query.endDate,
        },
      ];
    case 'userlist':
      return [
        '/api/centralgrading/v1/listUser',
        {
          searchUsername: payload.searchString,
          offset: payload.offset,
          resultsPerPage: payload.pageSize,
          programs: query.programs,
          courses: query.courses,
          roles: query.roles,
          column: payload.column,
          direction: payload.direction,
        },
      ];
    case 'submission':
      return [
        '/api/centralgrading/v1/submissionDetail',
        {
          role: user?.data?.role,
          submissionId: query.submissionId,
        },
      ];
    case 'userDetail':
      return [
        '/api/centralgrading/v1/findUser',
        {
          userId: payload,
        },
      ];
    case 'ssm':
      return [
        '/api/centralgrading/v1/listSsm',
        {
          courseId: payload,
        },
      ];
    default:
      throw new Error(`${dataPath} is not mapped to a data url`);
  }
};

export const postData = async (url = '', data = {}) => {
  return fetch(url, {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
};

const fetchData = ({ dataPath, state, payload }) => async dispatch => {
  await dispatch(requestData(dataPath));
  try {
    const response = await postData(
      ...getUrlData(dataPath, { ...state, payload })
    );

    if (response.status === 200) {
      const json = await response.json();
      // strip null fields
      const data = Array.isArray(json)
        ? json
        : Object.entries(json).reduce((result, [key, value]) => {
            if (value === null) {
              return result;
            }
            return {
              ...result,
              [key]: value,
            };
          }, {});

      return dispatch(receiveData(dataPath, data));
    }

    dispatch(
      receiveError({
        statusCode: response.status,
        message: `Failure requesting ${dataPath}`,
      })
    );

    if (response.status === 401) {
      dispatch({
        type: 'INVALIDATE_USER',
      });
    }

    if (response.status >= 500 && response.status <= 511) {
      dispatch({
        type: 'SET_ERRORBOUNDARY',
        payload: { message: response.statusText },
      });
      throw new Error(response.statusText);
    }
  } catch (e) {
    console.error(e);
  }
};

const shouldFetchData = (dataPath, state) => {
  const store = state[dataPath];
  const hasData = Object.keys(store.data).length;
  if (store.isFetching) {
    return false;
  } else if (!hasData) {
    return store.receivedAt === 0;
  } else {
    return store.didInvalidate;
  }
};

export const fetchDataIfNeeded = (dataPath, payload, force = false) => {
  return (dispatch, getState) => {
    const state = getState();
    if (force || shouldFetchData(dataPath, state)) {
      return dispatch(fetchData({ dataPath, state, payload }));
    }
  };
};
