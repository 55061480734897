import moment from 'moment';

const getUserRole = () => {
  const { name = '' } = JSON.parse(localStorage.getItem('role')) || {};
  return name;
};

const STATE = {
  receivedAt: Date.now(),
  pathname: '',
  search: '',
  offset: 0,
  pageSize: 10,
  programs: [],
  externalAppList: [],
  courses: [],
  assignments: [],
  students: [],
  claimerId: undefined,
  startDate: undefined, // moment
  endDate: undefined, // moment
  statusList: [],
  submissionId: undefined,
  userId: undefined,
  selectedExternalAppIds: [],
  externalAppIds: [],
  roles: [],
};

const query = (state = STATE, action) => {
  switch (action.type) {
    case 'INVALIDATE_USER':
    case 'INVALIDATE_QUERY':
      return {
        ...STATE,
        ...action.payload,
      };
    case 'CHANGED_PROGRAMS_QUERY':
      // clear dependent filters
      return {
        ...state,
        offset: 0,
        courses: [],
        assignments: [],
        ...action.payload,
      };
    case 'CHANGED_SELECTEDEXTERNALAPPIDS_QUERY':
      return {
        ...state,
        offset: 0,
        courses: [],
        assignments: [],
        ...action.payload,
      };

    case 'CHANGED_COURSES_QUERY':
      // clear dependent filters
      return {
        ...state,
        offset: 0,
        assignments: [],
        ...action.payload,
      };
    case 'RECEIVE_QUEUE':
      const pageSize = action?.payload?.data?.resultsPerPage ?? 10;
      return {
        ...state,
        pageSize,
      };
    case 'UPDATED_QUERY':
      const updated = {
        ...state,
        offset: 0,
        ...action.payload,
      };

      return Object.entries(updated).reduce((result, [key, value]) => {
        if (!Object.keys(STATE).includes(key)) {
          return result;
        }
        if (
          getUserRole() !== 'Manager of Central Graders' &&
          key === 'statusList'
        ) {
          return result;
        }
        // parse Integer values
        if (
          [
            'offset',
            'pageSize',
            'submissionId',
            'receivedAt',
            'claimerId',
          ].includes(key)
        ) {
          const valueInt = Number.parseInt(value, 10);

          if (Number.isNaN(valueInt)) {
            return result;
          }

          return {
            ...result,
            [key]: valueInt,
          };
        }

        // remove empty objects i.e. {}, [], ''
        if (!value || Object.values(value).length === 0) {
          return result;
        }

        // cast Moment values from date strings
        if (['startDate', 'endDate'].includes(key)) {
          const valueMoment = moment(value);
          if (valueMoment.isValid()) {
            return {
              ...result,
              [key]: valueMoment,
            };
          }

          return result;
        }

        // preserve string values as strings
        if (['pathname', 'search'].includes(key)) {
          return {
            ...result,
            [key]: value,
          };
        }

        // convert lists to arrays i.e. url search params are comma lists by config
        const valuesArray = Array.isArray(value)
          ? value
          : value.split(',').map(v => {
              if (v === '') {
                return undefined;
              }
              const int = Number.parseInt(v, 10);
              return Number.isNaN(int) ||
                ['students', 'assignments'].includes(key)
                ? v
                : int;
            });
        return {
          ...result,
          [key]: valuesArray,
        };
      }, {});
    default:
      return state;
  }
};

export default query;
