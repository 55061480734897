import ErrorUnauthorized from './components/helpers/ErrorUnauthorized';
import React from 'react';
import { isEmpty } from 'lodash';
import { useSelector } from 'hooks';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { data } = useSelector('user');
  const { role, roles } = data ?? {};

  return (
    <Route
      {...rest}
      render={props => {
        if (isEmpty(data)) {
          return <Redirect to="/" />;
        }
        if (isEmpty(role) && !isEmpty(roles)) {
          return <Redirect to="/roleSelector" />;
        }
        return (
          <ErrorUnauthorized>
            <Component {...props} />
          </ErrorUnauthorized>
        );
      }}
    />
  );
};

export default PrivateRoute;
