import React, { useState, useEffect } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Container, Table, Message, Grid } from 'semantic-ui-react';
import EditUsersRow from './EditUsersRow';
import { fetchDataIfNeeded, postData } from '../../actions/fetchData';
import { isValidEmail } from '../../utils';

export const EditUsers = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { state: { usersToEdit } } = location;
  const [users, setUsers] = useState(new Map());
  const [loading, setLoading] = useState(false);
  const [usersSubmitted, setUsersSubmitted] = useState([]);
  const [error, setError] = useState([]);

  const { push } = useHistory();

  useEffect(() => {
    dispatch(fetchDataIfNeeded('filters'));
  }, [dispatch]);

  useEffect(() => {
    const newUsers = new Map(
      usersToEdit.map(user => [user.id, user])
    );
    setUsers(newUsers);
  }, [usersToEdit]);

  const { courseList = [], programList = [] } = useSelector(state => {
    const { filters = {} } = state;
    let f = {};
    if (!!filters && !!filters.data) {
      f = filters.data;
    }
    return f;
  });

  const handleSubmit = async () => {
    setLoading(true);
    let profilesToUpsert = [];
    let completedUpserts = [];
    let err = [];
    let errProfile = [];

    for (const [, value] of users.entries()) {
      profilesToUpsert.push(value);
    }

    await Promise.all(
      profilesToUpsert.map(async profile => {
        const {
          courses,
          firstName,
          instructorCourses,
          lastName,
          programs,
          roleCodeList,
          username,
        } = profile;
        const isValid = f => f !== null && typeof f !== 'undefined' && f !== '';

        if (!isValidEmail(username)) {
          err.push(`${username}: Invalid email format`);
          errProfile.push(profile);
          return;
        }

        if (
          isValid(username) &&
          isValid(firstName) &&
          isValid(lastName) &&
          isValid(roleCodeList)
        ) {
          try {
            const rawResponse = await postData(
              '/api/centralgrading/v1/upsertUser',
              {
                active: true,
                courses,
                firstName,
                instructorCourses,
                lastName,
                programs,
                roleCodeList,
                username,
              }
            );
            const responseBody = await rawResponse.json();
            if (responseBody.ok === false || !!responseBody.errorCode) {
              throw responseBody;
            }
            completedUpserts.push(username);
          } catch (e) {
            console.warn(e);
            let errorMessage = `${username}: There was an error with the request.`;
            if (e.errorCode === 'BCS_ACCOUNT_MISSING') {
              errorMessage = `${username}: A new account with BCS courses needs to be created in Salesforce first.`;
            } else if (e.errorCode === 'BCS_COURSE_ENROLLMENT_MISSING') {
              errorMessage = `${username}: This account doesn't have the selected courses as enrollments in BCS and needs to be updated in Salesforce.`;
            }
            err.push(errorMessage);
            errProfile.push(profile);
          }
        } else {
          err.push(`${username}: Please complete all the required fields.`);
          errProfile.push(profile);
        }
      })
    );

    setError(err);
    setLoading(false);
    setUsersSubmitted(completedUpserts);

    if (errProfile.length) {
      const errorMap = errProfile.map((profile, i) => {
        return [i + i, profile];
      });
      setUsers(new Map(errorMap));
    } else {
      setUsers(new Map());
    }

    if (!err.length) {
      push('/admin');
    }
  };

  const setInput = (key, { target: { name, value } }) => {
    setUsers(prevState => {
      const m = new Map(prevState);
      m.set(key, {
        ...prevState.get(key),
        [name]: value,
      });

      return m;
    });
  };

  const setDropdown = (key, data, name) => {
    setUsers(prevState => {
      const m = new Map(prevState);
      m.set(key, {
        ...prevState.get(key),
        [name]: data,
      });

      return m;
    });
  };

  const returnRowsToRender = () => {
    let arrayOfRows = [];

    for (const [key, value] of users.entries()) {
      arrayOfRows.push(
        <EditUsersRow
          key={key}
          mapKey={key}
          handleInputChange={setInput}
          handleDropdownChange={setDropdown}
          courseList={courseList}
          programList={programList}
          userInfo={value}
        />
      );
    }

    return arrayOfRows;
  };

  return (
    <Container style={{ marginTop: '7em', padding: '0em 1em', width: '100%' }}>
      <Button
        as={Link}
        icon="left arrow"
        content="Back"
        labelPosition="left"
        to={{ pathname: '/admin' }}
      />
      <Table celled columns={6}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Program</Table.HeaderCell>
            <Table.HeaderCell>TA classes</Table.HeaderCell>
            <Table.HeaderCell>Instructor classes</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{users.size > 0 && returnRowsToRender()}</Table.Body>
      </Table>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            {!!error.length && (
              <Grid.Row>
                <Message
                  header="Error"
                  error
                  compact
                  list={error}
                  onDismiss={() => setError([])}
                />
              </Grid.Row>
            )}
            {!!usersSubmitted.length && (
              <Message
                header="Users uploaded successfully"
                success
                compact
                list={usersSubmitted}
                onDismiss={() => setUsersSubmitted([])}
              />
            )}
          </Grid.Column>
          <Grid.Column floated="right">
            <Button
              color="blue"
              icon="save"
              content="Update All Users"
              labelPosition="left"
              floated="right"
              disabled={loading}
              onClick={handleSubmit}
              style={{
                marginBottom: '1em',
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default EditUsers;
