import { combineReducers } from 'redux';

import user from './user.js';
import query from './query';
import submission from './submission';
import queue from './queue';
import filters from './filters';
import students from './students';
import graders from './graders';
import userlist from './userlist';
import userDetail from './userdetail';
import searchStudents from './searchStudents';
import ssm from './ssm';
import adminFilters from './adminFilters';
import error from './error';

const rootReducer = combineReducers({
  user,
  query,
  queue,
  submission,
  filters,
  students,
  graders,
  userlist,
  userDetail,
  searchStudents,
  ssm,
  adminFilters,
  error,
});

export default rootReducer;
