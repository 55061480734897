import React from 'react';
import { Grid, Header, Segment } from 'semantic-ui-react';

const DisabledMessage = () => (
  <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
    <Grid.Column style={{ maxWidth: 800 }}>
      <Header as="h2" style={{ color: '#333' }} textAlign="center">
        Centralized Grading
      </Header>
      <Segment>
        BCSgrading will be offline this morning due to administrative process
        updates
      </Segment>
    </Grid.Column>
  </Grid>
);

export default DisabledMessage;
