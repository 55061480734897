import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchDataIfNeeded, invalidateStore } from '../../actions/fetchData';
import {
  Dropdown,
  Grid,
  Header,
  Message,
  Segment,
  Table,
} from 'semantic-ui-react';

export class SsmList extends Component {
  state = {
    selectedCourse: null,
  };

  componentDidMount() {
    this.props.prepare();
    this.props.listSsm();
  }

  handleCourseChange = (e, { value }) => {
    this.setState({ selectedCourse: value });
    if (value) {
      this.props.listSsm(value);
    } else {
      this.props.listSsm();
    }
  };

  render() {
    const { ssmList = [], coursesList = [], loading } = this.props;
    const { selectedCourse } = this.state;

    return (
      <div style={{ marginTop: '1em' }} ref={this.contextRef}>
        <Grid>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'row-reverse',
              }}>
              <Dropdown
                style={{ marginLeft: '1em', width: '250px' }}
                placeholder="Select Course"
                loading={loading}
                search
                fluid
                selection
                clearable
                options={coursesList.map(({ id, name }) => ({
                  key: id,
                  value: id,
                  text: name,
                }))}
                onChange={this.handleCourseChange}
                value={selectedCourse}
              />
              <Header size="small" style={{ color: '#333', marginTop: '0px' }}>
                Course
              </Header>
            </div>

            <Segment
              style={{
                border: '1px solid #ddd',
                marginTop: '1em',
              }}>
              <Table celled fixed>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Last Name</Table.HeaderCell>
                    <Table.HeaderCell>First Name</Table.HeaderCell>
                    <Table.HeaderCell>Email</Table.HeaderCell>
                    <Table.HeaderCell>Courses</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {ssmList.map(
                    ({ id, firstName, lastName, email, courseList }) => {
                      return (
                        <Table.Row key={id}>
                          <Table.Cell>{lastName}</Table.Cell>
                          <Table.Cell>{firstName}</Table.Cell>
                          <Table.Cell>{email}</Table.Cell>
                          <Table.Cell>{courseList}</Table.Cell>
                        </Table.Row>
                      );
                    }
                  )}
                </Table.Body>
              </Table>
              {ssmList.length === 0 && <Message>There are no ssm.</Message>}
            </Segment>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { courseList = [] } = state.filters.data;
      const { ssmCourseList = [], ssmList = [] } = state.ssm.data;
      return {
        loading: state.filters.isFetching || state.ssm.isFetching,
        coursesList: courseList,
        ssmList: ssmList.map(ssm => {
          const courseIds = ssmCourseList
            .filter(x => x.ssmId === ssm.id)
            .map(x => x.courseId);

          return {
            ...ssm,
            courseList: courseList
              .filter(course => courseIds.includes(course.id))
              .map(x => x.name)
              .join(', '),
          };
        }),
        ssmCourseList: state.ssm.data.courseList,
      };
    },
    dispatch => ({
      listSsm: async payload => {
        dispatch(invalidateStore('ssm'));
        await dispatch(fetchDataIfNeeded('ssm', payload));
      },
      prepare: async () => {
        await dispatch(fetchDataIfNeeded('filters'));
      },
    })
  )(SsmList)
);
