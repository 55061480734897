import React, { Component, createRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Menu, Icon } from 'semantic-ui-react';
import Queue from '../Queue';
import {
  fetchDataIfNeeded,
  invalidateStore,
  updatedQuery,
} from '../../actions/fetchData';
import qs from 'qs';
import moment from 'moment';

export class QueueNavigator extends Component {
  contextRef = createRef();

  addUrlSearchParams(
    { pathname, search },
    {
      offset,
      statusList,
      courses,
      programs,
      students,
      claimerId,
      assignments,
      startDate,
      endDate,
    },
    { overrideQueryProps = false }
  ) {
    const urlSearchParams = qs.parse(search, {
      ignoreQueryPrefix: true,
    });

    return {
      pathname: pathname,
      search: qs.stringify(
        {
          ...(!overrideQueryProps ? urlSearchParams : {}),
          offset,
          statusList,
          courses,
          programs,
          students,
          claimerId,
          assignments,
          startDate: moment.isMoment(startDate)
            ? startDate.format()
            : undefined,
          endDate: moment.isMoment(endDate) ? endDate.format() : undefined,
          ...(overrideQueryProps ? urlSearchParams : {}),
        },
        { arrayFormat: 'comma', encode: false, format: 'RFC1738' }
      ),
    };
  }

  componentDidMount() {
    const { filterQueueFromUrl, loadFilters } = this.props;
    const { location, history, query } = this.props;

    history.replace(
      this.addUrlSearchParams(location, query, {
        overrideQueryProps: true,
      })
    );

    loadFilters();
    filterQueueFromUrl();
  }

  componentDidUpdate(prevProps) {
    const {
      filterQueueFromUrl,
      loadQueue,
      clearFilters,
      statusCode,
    } = this.props;
    const { location, history, query, isFetching } = this.props;

    if (location.search === '' && !query.didInvalidate) {
      clearFilters();
    } else if (location.search === '') {
      history.replace(
        this.addUrlSearchParams(
          location,
          {
            ...query,
            offset: 0,
          },
          { overrideQueryProps: false }
        )
      );
    } else if (
      location.search !== prevProps.location.search ||
      location.pathname !== prevProps.location.pathname
    ) {
      filterQueueFromUrl();
    } else if (query.receivedAt > prevProps.query.receivedAt) {
      history.push(
        this.addUrlSearchParams(location, query, {
          overrideQueryProps: false,
        })
      );
    } else if (!isFetching && statusCode !== 500) {
      loadQueue();
    }
  }

  getStyleForTab(isSelected) {
    if (isSelected) {
      return {
        paddingTop: 18,
        fontWeight: 'bold',
        textDecoration: 'underline',
      };
    } else {
      return { paddingTop: 18, fontWeight: 'regular' };
    }
  }

  render() {
    const { pathname } = this.props.query;
    const userRole = this.props.user?.data?.role?.name;
    const pastDueClaimCount = this.props.queue.data.pastDueClaimCount;
    return (
      <div key={userRole} ref={this.contextRef}>
        <Container
          style={{ marginTop: '87px', width: '80%', maxWidth: '1127px' }}>
          <Menu
            style={{
              border: 'none',
              borderBottom: '2px solid #e2e2e3',
              boxShadow: 'none',
            }}>
            <Link to={{ pathname: '/queue', search: 'offset=0' }}>
              <Menu.Item style={this.getStyleForTab(pathname === '/queue')}>
                All
              </Menu.Item>
            </Link>
            {['Central Grader', 'Manager of Central Graders'].includes(
              userRole
            ) && (
              <Link to={{ pathname: '/claimed', search: 'offset=0' }}>
                <Menu.Item style={this.getStyleForTab(pathname === '/claimed')}>
                  Claimed by Me
                  {pastDueClaimCount > 0 && (
                    <Icon
                      name="bell"
                      size="tiny"
                      inverted
                      circular
                      color="red"
                      style={{
                        position: 'absolute',
                        top: '0.5rem',
                        right: '0.25rem',
                      }}
                    />
                  )}
                </Menu.Item>
              </Link>
            )}
            <Link
              to={`/overTargetTurnaround?endDate=${moment()
                .subtract(72, 'hours')
                .format()}&statusList=New,In Progress,Ready for Review`}>
              <Menu.Item
                style={this.getStyleForTab(
                  pathname === '/overTargetTurnaround'
                )}>
                Over Target Turnaround
              </Menu.Item>
            </Link>
            {['Manager of Central Graders'].includes(userRole) && (
              <React.Fragment>
                <Link to={'/taMessages'}>
                  <Menu.Item
                    style={this.getStyleForTab(pathname === '/taMessages')}>
                    TA Messages
                  </Menu.Item>
                </Link>
              </React.Fragment>
            )}
            {['Manager of Central Graders', 'Support'].includes(userRole) && (
              <React.Fragment>
                <Link to={'/admin'} style={{ float: 'right' }}>
                  <Menu.Item style={this.getStyleForTab(pathname === '/admin')}>
                    User Admin
                  </Menu.Item>
                </Link>
                <Link to={'/metrics'} style={{ float: 'right' }}>
                  <Menu.Item
                    style={this.getStyleForTab(pathname === '/metrics')}>
                    Metrics
                  </Menu.Item>
                </Link>
              </React.Fragment>
            )}
            {['Manager of Central Graders', 'Support'].includes(userRole) && (
              <Link to={'/ssm'} style={{ float: 'right' }}>
                <Menu.Item style={this.getStyleForTab(pathname === '/ssm')}>
                  SSM list
                </Menu.Item>
              </Link>
            )}
            {['Classroom TA', 'Instructor'].includes(userRole) && (
              <Link to={'/contact'}>
                <Menu.Item style={this.getStyleForTab(pathname === '/contact')}>
                  Contact
                </Menu.Item>
              </Link>
            )}
          </Menu>
          <Queue />
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { query, user, queue } = state;
      const { isFetching } = state.queue;
      return {
        query,
        isFetching,
        statusCode: user?.error?.statusCode,
        user,
        queue,
      };
    },
    (dispatch, { location }) => ({
      loadFilters: async () => {
        dispatch(fetchDataIfNeeded('filters'));
      },
      loadQueue: async () => {
        dispatch(fetchDataIfNeeded('queue'));
      },
      filterQueueFromUrl: async payload => {
        const urlSearchParams = qs.parse(location.search, {
          ignoreQueryPrefix: true,
        });
        const { statusList } = urlSearchParams;
        if (statusList) {
          urlSearchParams.statusList = statusList.split(',');
        }
        dispatch(
          updatedQuery({
            ...payload,
            ...urlSearchParams,
            ...location,
          })
        );
      },
      clearFilters: async () => {
        dispatch(invalidateStore('query'));
      },
    })
  )(QueueNavigator)
);
