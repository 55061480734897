import { Button } from 'semantic-ui-react';
import React from 'react';
import { revalidateStore } from 'actions/fetchData';
import { useHistory } from 'react-router-dom';
import GoogleLogo from 'assets/img/google-logo.svg';
import { useDispatch, useSelector } from 'hooks';

const GoogleLoginButton = () => {
  const dispatch = useDispatch();
  const { go, push } = useHistory();
  const { isFetching } = useSelector('user');

  const handleLogin = () => {
    dispatch(revalidateStore('user'));
    push('/api/centralgrading/v1/oauth/login');
    go(0);
  };

  const BORDER_WIDTH = 2;
  const BORDER_RADIUS = '.28571429rem';

  return (
    <Button
      fluid
      color="blue"
      disabled={isFetching}
      loading={isFetching}
      position="relative"
      size="large"
      style={{ position: 'relative' }}
      onClick={handleLogin}>
      <div
        style={{
          alignItems: 'center',
          backgroundColor: '#FFFFFF',
          borderRadius: `${BORDER_RADIUS} 0px 0px ${BORDER_RADIUS}`,
          display: 'flex',
          height: `calc(100% - ${BORDER_WIDTH * 2}px)`,
          justifyContent: 'center',
          left: BORDER_WIDTH,
          position: 'absolute',
          top: BORDER_WIDTH,
          width: 36,
        }}>
        <img alt="Google Logo" src={GoogleLogo} style={{ height: 20 }} />
      </div>
      Sign in with Google
    </Button>
  );
};

export default GoogleLoginButton;
