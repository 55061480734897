import React, { useState } from 'react';
import {
  Icon,
  Table,
  Grid,
  Search,
  Dropdown,
  Input,
  Popup,
} from 'semantic-ui-react';
import { postData } from '../../actions/fetchData';
import '../../assets/css/search.css';
import '../../assets/css/helpers.css';

const roles = [
  { id: 1, name: 'Classroom TA' },
  { id: 6, name: 'Instructor' },
  { id: 2, name: 'Manager of Central Graders' },
  { id: 3, name: 'Central Grader' },
  { id: 4, name: 'Support' },
];

export const AddUsersRow = ({
  mapKey,
  courseList,
  programList,
  handleDeleteRow,
  handleInputChange,
  handleDropdownChange,
  handleSetUsername,
  handleSetUserInfo,
  userInfo: {
    courses,
    firstName,
    instructorCourses,
    lastName,
    programs,
    roleCodeList,
    username,
  },
}) => {
  const [loading, setLoading] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [disablePopup, setDisablePopup] = useState(true);

  const handleFindUserOnKeyDown = e => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      return handleFindUser();
    }
  };

  const handleFindUser = async () => {
    try {
      setLoading(true);
      const rawResponse = await postData('/api/centralgrading/v1/findUser', {
        username,
      });
      const responseBody = await rawResponse.json();
      if (responseBody.ok === false) {
        throw responseBody;
      }

      let active,
        courses,
        instructorCourses,
        lastName,
        programs,
        roleCodeList,
        userName,
      firstName;

      if (!!responseBody) {
        if (!!responseBody.userData && !!responseBody.userData.roles) {
          roleCodeList = responseBody.userData.roles.map(r => r.name);
          if (!!responseBody.courses) {
            courses = responseBody.courses.map(c => c.id);
          } else {
            courses = [];
          }
          if (!!responseBody.instructorCourses) {
            instructorCourses = responseBody.instructorCourses.map(c => c.id);
          } else {
            instructorCourses = [];
          }
          if (!!responseBody.programs) {
            programs = responseBody.programs.map(p => p.id);
          } else {
            programs = [];
          }
        } else {
          roleCodeList = [];
          courses = [];
          instructorCourses = [];
          programs = [];
        }
        if (!!responseBody.userAccount) {
          active = responseBody.userAccount.active;
          userName = responseBody.userAccount.userName;
          firstName = responseBody.userAccount.firstName;
          lastName = responseBody.userAccount.lastName;
        } else {
          active = true;
          userName = username;
          firstName = '';
          lastName = '';
        }

        handleSetUserInfo(mapKey, {
          active,
          courses,
          firstName,
          instructorCourses,
          lastName,
          programs,
          roleCodeList,
          username: userName,
        });
      }

      setFormDisabled(false);
      setDisablePopup(true);
    } catch (e) {
      console.warn(e);

      handleSetUserInfo(mapKey, {
        active: true,
        courses: [],
        firstName: '',
        instructorCourses,
        lastName: '',
        programs: [],
        roleCodeList: [],
        username: '',
      });
      setFormDisabled(true);
      setDisablePopup(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (e, data) => {
    handleSetUsername(mapKey, data.value);
  };

  const parseDropdown = data =>
    data.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));

  const parseRolesDropdown = data =>
    data.map(({ id, name }) => ({
      key: id,
      value: name,
      text: name,
    }));

  return (
    <Table.Row>
      <Table.Cell width={2}>
        <Popup
          content="Email not found"
          position="right center"
          disabled={disablePopup}
          on={['focus', 'hover']}
          trigger={
            <Search
              loading={loading}
              disabled={loading}
              placeholder="Search user by email"
              onKeyDown={handleFindUserOnKeyDown}
              className="test"
              icon={
                <Icon
                  name="search"
                  loading={loading}
                  circular
                  inverted
                  color="green"
                  link
                  disabled={loading}
                  onClick={() => handleFindUser()}
                />
              }
              onSearchChange={handleSearchChange}
              value={username}
              showNoResults={false}
            />
          }
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Input
          disabled={formDisabled}
          fluid
          name="lastName"
          value={lastName}
          onChange={e => handleInputChange(mapKey, e)}
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Input
          disabled={formDisabled}
          fluid
          name="firstName"
          value={firstName}
          onChange={e => handleInputChange(mapKey, e)}
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Dropdown
          disabled={formDisabled}
          selection
          search
          fluid
          multiple
          value={programs}
          options={parseDropdown(programList)}
          name="program"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'programs')
          }
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Dropdown
          disabled={formDisabled}
          selection
          search
          fluid
          multiple
          value={courses}
          options={parseDropdown(courseList)}
          name="class"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'courses')
          }
        />
      </Table.Cell>
      <Table.Cell width={2}>
        <Dropdown
          disabled={formDisabled}
          selection
          search
          fluid
          multiple
          value={instructorCourses}
          options={parseDropdown(courseList)}
          name="class"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'instructorCourses')
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Grid verticalAlign="middle">
          <Grid.Row columns={2}>
            <Grid.Column width="12" className="padding-right-none">
              <Dropdown
                disabled={formDisabled}
                selection
                fluid
                search
                multiple
                value={roleCodeList}
                options={parseRolesDropdown(roles)}
                name="role"
                onChange={(e, { value }) =>
                  handleDropdownChange(mapKey, value, 'roleCodeList')
                }
              />
            </Grid.Column>
            <Grid.Column width="4" className="padding-left-none">
              <Icon
                name="trash"
                color="red"
                className="float-right"
                link
                onClick={() => handleDeleteRow(mapKey)}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Table.Cell>
    </Table.Row>
  );
};

export default AddUsersRow;

AddUsersRow.defaultProps = {
  userInfo: {
    active: true,
    courses: [],
    firstName: '',
    instructorCourses: [],
    lastName: '',
    programs: [],
    roleCodeList: [],
    username: '',
  },
  courseList: [],
  programList: [],
};
