import React, { useState } from 'react';
import {
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  List,
  Segment,
} from 'semantic-ui-react';
import moment from 'moment';
import Metric from './Metric';

const humanizeSeconds = sec => {
  const parse = {
    days: Math.floor(sec / 86400),
    hours: Math.floor(sec / 3600) % 24,
    minutes: Math.floor(sec / 60) % 60,
    seconds: Math.floor(sec) % 60,
  };

  return Object.keys(parse)
    .reduce((result, key) => {
      if (parse[key] !== 0) return [...result, `${parse[key]}${key[0]}`];
      return result;
    }, [])
    .join(' ');
};

const GraderList = ({ graders, activeGrader, selectGrader }) => (
  <React.Fragment>
    <Header floated="left">Graders</Header>
    <Header floated="right">{graders.length}</Header>
    <Divider style={{ clear: 'both' }} />
    <List link relaxed>
      {graders.map(grader => (
        <List.Item
          as="a"
          key={grader.username}
          active={grader.username === activeGrader}
          onClick={selectGrader(grader)}>
          {grader.lastName}, {grader.firstName}
        </List.Item>
      ))}
    </List>
  </React.Fragment>
);

const GraderMetrics = ({ grader }) => (
  <React.Fragment>
    <Container>
      <Header>
        {grader.firstName} {grader.lastName}
      </Header>
    </Container>
    <Segment>
      <Grid relaxed celled="internally" columns={2}>
        <Grid.Row textAlign="center">
          <Grid.Column>
            <Metric
              label="Avg. Turnaround"
              metric={humanizeSeconds(grader.averageTurnaround)}
            />
          </Grid.Column>
          <Grid.Column>
            <Metric
              label="Submissions Graded"
              metric={grader.submissionsGraded}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </React.Fragment>
);

const WeeklyMetrics = ({
  processed,
  potentialPlagiarism,
  averageTurnaround,
  totalActiveCohorts,
}) => (
  <React.Fragment>
    <Container>
      <Header floated="left">Week Overview</Header>
      <Header floated="right">{totalActiveCohorts} cohorts</Header>
    </Container>
    <Segment style={{ clear: 'both' }}>
      <Grid relaxed celled="internally">
        <Grid.Row textAlign="center" columns={3}>
          <Grid.Column>
            <Metric
              label="Avg. Turnaround"
              metric={humanizeSeconds(averageTurnaround)}
            />
          </Grid.Column>
          <Grid.Column>
            <Metric label="Submissions Processed" metric={processed} />
          </Grid.Column>
          <Grid.Column>
            <Metric label="Potential Plagiarism" metric={potentialPlagiarism} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Segment>
  </React.Fragment>
);

const Weekly = ({ metrics, offsetWeek, range }) => {
  const [activeGrader, setActiveGrader] = useState();
  const today = moment();

  return (
    <Grid divided="vertically">
      <Grid.Row>
        <Grid.Column>
          <List horizontal>
            <List.Item>
              <Icon link name="arrow left" onClick={() => offsetWeek(-1)} />
            </List.Item>
            <List.Item>
              <Header>
                {moment(range.start).format('MMM Do')} -{' '}
                {moment(range.end).format('MMM Do')} {moment(range.end).year()}
              </Header>
            </List.Item>
            <List.Item>
              <Icon
                link={moment(range.start).week() !== today.week()}
                disabled={moment(range.start).week() === today.week()}
                onClick={() => offsetWeek(1)}
                name="arrow right"
              />
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={2}>
        <Grid.Column width={4}>
          <GraderList
            graders={metrics.graderMetrics}
            activeGrader={{}}
            selectGrader={grader => () =>
              setActiveGrader((prev = {}) => {
                if (grader.username === prev.username) return undefined;
                return grader;
              })}
          />
        </Grid.Column>
        <Grid.Column width={12}>
          <Segment>
            {activeGrader && <GraderMetrics grader={activeGrader} />}
            <WeeklyMetrics
              processed={metrics.weekMetrics.markedDone}
              potentialPlagiarism={metrics.weekMetrics.markedPlagiarism}
              averageTurnaround={metrics.weekMetrics.averageTurnaround}
              totalActiveCohorts={metrics.weekMetrics.courses}
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default Weekly;
