export const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const pressOnlyNumbers = e => {
  !/[0-9]/.test(e.key) && e.preventDefault();
};

export const someElement = array => array.some(e => !!e);

export const BCS = 'bcs';

export const insertSubstringAtIndex = (str, substring, index) =>
  str.slice(0, index) + substring + str.slice(index);
