import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const ErrorUnauthorized = ({ user, children }) => {
  if (user.error.statusCode === 401) {
    return <Redirect to="/login" />;
  }

  return children;
};

export default withRouter(connect(({ user }) => ({ user }))(ErrorUnauthorized));
