import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Grid,
  Segment,
  Label,
  Loader,
} from 'semantic-ui-react';
import '../../assets/css/assignment.css';
import { fetchDataIfNeeded, postData } from '../../actions/fetchData';
import moment from 'moment';

class TaMessageDetails extends Component {
  state = {
    fromName: '',
    dateTime: '',
    message: '',
    courseList: [],
  };

  async componentDidMount() {
    this.setState({ loading: true, error: null, success: null });
    const messageId = this.props.match.params.messageId;
    try {
      const response = await postData('/api/centralgrading/v1/messageDetail', {
        messageId: parseInt(messageId, 10),
      });
      if (response.ok === false) {
        throw response;
      }
      const responseBody = await response.json();
      this.setState({
        success: true,
        loading: false,
        error: null,
        fromName:
          responseBody.from.firstName + ' ' + responseBody.from.lastName,
        dateTime: moment(responseBody.date).format('LLLL'),
        message: responseBody.message,
        courseList: responseBody.courseList,
      });
    } catch (e) {
      console.warn(e);
      this.setState({
        error: 'There was an error trying to get the message.',
        loading: false,
      });
    }
  }

  render() {
    return (
      <div>
        <Container style={{ marginTop: '7em', width: '70%' }}>
          <Button as={Link} color="grey" to={{ pathname: '/taMessages' }}>
            <i className="left arrow icon" />
            Back
          </Button>
          <Segment style={{ border: '1px solid #ddd' }}>
            <Grid>
              <div style={{ padding: '0em', width: '100%' }}>
                <Grid.Row
                  computer={16}
                  mobile={16}
                  tablet={16}
                  style={{ clear: 'both' }}>
                  <Grid.Column
                    computer={4}
                    mobile={16}
                    tablet={16}
                    style={{
                      width: '100%',
                      float: 'left',
                      borderBottom: '1px solid #ddd',
                    }}>
                    <div style={{ padding: '2em' }}>
                      <span>
                        <Header size="large">Message</Header>
                        {this.state.loading && <Loader active={true} />}
                      </span>
                    </div>
                  </Grid.Column>
                </Grid.Row>
                {!this.state.loading && (
                  <React.Fragment>
                    <Grid.Row
                      computer={16}
                      mobile={16}
                      tablet={16}
                      style={{ clear: 'both' }}>
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{
                          width: '100%',
                          float: 'left',
                          borderBottom: '1px solid #ddd',
                        }}>
                        <div style={{ padding: '2em' }}>
                          <Form>
                            <Form.Field style={{ float: 'left' }}>
                              <label>From</label>
                              {this.state.fromName}
                            </Form.Field>
                            <Form.Field
                              style={{ float: 'left', marginBottom: '1em' }}>
                              <label>Sent</label>
                              {moment(this.state.dateTime).format('LLLL')}
                            </Form.Field>
                          </Form>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row
                      computer={16}
                      mobile={16}
                      tablet={16}
                      style={{ clear: 'both' }}>
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{
                          width: '100%',
                          float: 'left',
                          borderBottom: '1px solid #ddd',
                        }}>
                        <div style={{ padding: '2em' }}>
                          <p>{this.state.message}</p>

                          <Form>
                            <Form.Field
                              style={{ float: 'left', marginBottom: '2em' }}>
                              <label>Applicable Classes</label>
                              {this.state.courseList.map(c => (
                                <Label key={c.id}>{c.name}</Label>
                              ))}
                            </Form.Field>
                          </Form>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                  </React.Fragment>
                )}
              </div>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { filters = {} } = state;
      let f = null;
      if (!!filters && !!filters.data) {
        f = filters.data;
      }
      return { filters: f };
    },
    (dispatch, { data, ...props }) => ({
      loadFilters: async () => {
        dispatch(fetchDataIfNeeded('filters'));
      },
    })
  )(TaMessageDetails)
);
