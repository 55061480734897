import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import {
  Button,
  Checkbox,
  Grid,
  Modal,
  Search,
  Table,
} from 'semantic-ui-react';
import { fetchDataIfNeeded, invalidateStore } from '../../actions/fetchData';
import AdminFilters from '../AdminFilters';
import { IsRoleStaff } from '../helpers/IsRoleStaff';
import { ReactComponent as UserAddCustomSvg } from '../../assets/img/user-add-cust.svg';
import { ReactComponent as UserAddMultipleCustomSvg } from '../../assets/img/users-medical.svg';
import { ReactComponent as UserEditCustomSvg } from '../../assets/img/user-edit.svg';
import { ReactComponent as UserEditButtonCustomSvg } from '../../assets/img/users-edit-cust.svg';
import { useArray, useDispatch, useObjectState, useSelector } from 'hooks';

const Admin = () => {
  const dispatch = useDispatch();
  const [classesModal, setClassesModal] = useState(null);
  const [
    usersToEdit,
    ,
    { includes: usersToEditIncludes, push: pushUser, remove: removeUser },
  ] = useArray([], { type: 'object' });

  const {
    data: { users = [], ...meta } = {},
    isFetching: loading = false,
  } = useSelector('userlist');
  const user = useSelector('user');

  const [searchQuery, setSearchQuery, setSearchQueryValue] = useObjectState({
    column: '',
    direction: '',
    offset: 0,
    pageSize: 50,
    searchString: '',
  });

  const clearFilters = useCallback(
    () => dispatch(invalidateStore('userlist')),
    [dispatch]
  );

  const loadUsers = useCallback(
    payload => dispatch(fetchDataIfNeeded('userlist', payload)),
    [dispatch]
  );

  useEffect(() => {
    clearFilters();
    loadUsers(searchQuery);
    //eslint-disable-next-line
  }, [clearFilters, loadUsers]);

  const handleSort = clickedColumn => () => {
    const newSearchQuery = { ...searchQuery };
    const { column, direction } = newSearchQuery;
    if (column !== clickedColumn) {
      newSearchQuery.column = clickedColumn;
      newSearchQuery.direction = 'ascending';
    } else {
      newSearchQuery.direction =
        direction === 'ascending' ? 'descending' : 'ascending';
    }
    setSearchQuery(newSearchQuery);
    clearFilters();
    loadUsers(newSearchQuery);
  };

  const handleSearch = e => {
    if (e?.key !== 'Enter') return;
    const newSearchQuery = {
      ...searchQuery,
      offset: 0,
    };
    setSearchQuery(newSearchQuery);
    clearFilters();
    loadUsers(newSearchQuery);
  };

  const handleCheck = user => () => {
    const exists = usersToEditIncludes(user?.id);
    if (!exists) {
      pushUser(user);
    } else {
      removeUser('id', user?.id);
    }
  };

  const handlePageChange = page => {
    const { offset, pageSize } = searchQuery;
    const newSearchQuery = {
      ...searchQuery,
      offset: offset + pageSize * (page === 'next' ? 1 : -1),
    };
    setSearchQuery(newSearchQuery);
    clearFilters();
    loadUsers(newSearchQuery);
  };

  const handleNext = () => handlePageChange('next');
  const handlePrev = () => handlePageChange('prev');

  const userRole = user?.data?.role?.name;
  const disableNextButton =
    meta?.totalResults !== undefined &&
    searchQuery?.offset + searchQuery?.pageSize > meta?.totalResults - 1;
  const columnWidth = 3;
  const { column, direction } = searchQuery;

  return (
    <div style={{ marginTop: '1em' }}>
      <Modal
        open={!isEmpty(classesModal)}
        onClose={() => setClassesModal(null)}>
        <Modal.Header>{classesModal?.role} classes</Modal.Header>
        <Modal.Content>
          <ul>
            {(classesModal?.classes ?? []).map(({ name }, index) => (
              <li key={`class-${index}`}>{name}</li>
            ))}
          </ul>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setClassesModal(null)}>Close</Button>
        </Modal.Actions>
      </Modal>
      <Grid>
        <Grid.Column
          computer={4}
          mobile={16}
          tablet={16}
          style={{ marginTop: '1em' }}>
          <AdminFilters />
        </Grid.Column>
        <Grid.Column computer={12} mobile={16} tablet={16}>
          <Button
            content="Add Single User"
            icon={
              <i aria-hidden="true" className="icon">
                <UserAddCustomSvg
                  height="16"
                  style={{
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    width: '100%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </i>
            }
            disabled={!IsRoleStaff(userRole)}
            as={Link}
            to={{
              pathname: '/adduser',
            }}
            labelPosition="left"></Button>

          <Button
            content="Add Multiple Users"
            icon={
              <i aria-hidden="true" className="icon">
                <UserAddMultipleCustomSvg
                  height="16"
                  style={{
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    width: '100%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </i>
            }
            as={Link}
            to={{
              pathname: '/addusers',
            }}
            labelPosition="left"></Button>

          <Button
            content="Edit Checked Users"
            icon={
              <i aria-hidden="true" className="icon">
                <UserEditButtonCustomSvg
                  height="16"
                  style={{
                    display: 'block',
                    position: 'absolute',
                    top: '50%',
                    width: '100%',
                    transform: 'translateY(-50%)',
                  }}
                />
              </i>
            }
            as={Link}
            to={{
              pathname: '/editusers',
              state: { usersToEdit },
            }}
            labelPosition="left"></Button>

          <Search
            style={{
              float: 'right',
            }}
            loading={loading}
            disabled={loading}
            onKeyDown={handleSearch}
            onSearchChange={(e, s) =>
              setSearchQueryValue('searchString', s.value)
            }
            showNoResults={false}
            placeholder="Search user by email"
          />
          <Table sortable celled fixed>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell
                  colSpan="2"
                  sorted={column === 'role' ? direction : null}
                  onClick={handleSort('role')}></Table.HeaderCell>
                <Table.HeaderCell
                  colSpan={columnWidth}
                  sorted={column === 'lastName' ? direction : null}
                  onClick={handleSort('lastName')}>
                  Last Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  colSpan={columnWidth}
                  sorted={column === 'firstName' ? direction : null}
                  onClick={handleSort('firstName')}>
                  First Name
                </Table.HeaderCell>
                <Table.HeaderCell
                  colSpan={columnWidth}
                  sorted={column === 'email' ? direction : null}
                  onClick={handleSort('email')}>
                  Email
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={columnWidth}>
                  Program
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={columnWidth}>
                  Classes
                </Table.HeaderCell>
                <Table.HeaderCell colSpan={columnWidth}>Roles</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map(
                ({
                  courses = [],
                  instructorCourses = [],
                  programs,
                  userAccount,
                  userData,
                }) => {
                  const {
                    id,
                    lastName,
                    firstName,
                    email,
                    userName,
                    programList = programs === null
                      ? []
                      : programs.map(p => p.name).join(', '),
                    active,
                  } = userAccount;
                  const { roles = [] } = userData;
                  return (
                    <Table.Row key={id}>
                      <Table.Cell colSpan="2">
                        {IsRoleStaff(userRole) && (
                          <div>
                            <Checkbox
                              onClick={handleCheck({
                                active,
                                courses: (courses ?? []).map(c => c.id),
                                email,
                                firstName,
                                id,
                                instructorCourses: (instructorCourses ?? []).map(ic => ic.id),
                                lastName,
                                programs: (programs ?? []).map(p => p.id),
                                roleCodeList: roles.map(r => r.name),
                                username: userName,
                              })}
                            />
                            <a
                              href={`/edituser/${id}`}
                              style={{ float: 'right' }}>
                              <i aria-hidden="true" className="icon">
                                <UserEditCustomSvg height="16" />
                              </i>
                            </a>
                          </div>
                        )}
                      </Table.Cell>
                      <Table.Cell colSpan={columnWidth}>{lastName}</Table.Cell>
                      <Table.Cell colSpan={columnWidth}>{firstName}</Table.Cell>
                      <Table.Cell colSpan={columnWidth}>{email}</Table.Cell>
                      <Table.Cell colSpan={columnWidth}>
                        {programList}
                      </Table.Cell>
                      <Table.Cell colSpan={columnWidth}>
                        {!isEmpty(courses) && (
                          <Button
                            size="tiny"
                            onClick={() =>
                              setClassesModal({
                                classes: courses,
                                role: 'Classroom TA',
                              })
                            }>
                            TA
                          </Button>
                        )}
                        {!isEmpty(instructorCourses) && (
                          <Button
                            size="tiny"
                            onClick={() =>
                              setClassesModal({
                                classes: instructorCourses,
                                role: 'Instructor',
                              })
                            }>
                            Instructor
                          </Button>
                        )}
                      </Table.Cell>
                      <Table.Cell colSpan={columnWidth}>
                        {roles.map(({ name }) => name).join(', ')}
                      </Table.Cell>
                    </Table.Row>
                  );
                }
              )}
            </Table.Body>
          </Table>
          <div
            className="row"
            style={{
              display: '-webkit-flex',
              marginLeft: '100px',
              marginRight: '100px',
            }}>
            <div
              style={{
                maxWidth: '50%',
                flexBasis: '50%',
                textAlign: 'left',
              }}>
              <Button
                disabled={searchQuery?.offset === 0}
                loading={loading}
                onClick={handlePrev}
                style={{ backgroundColor: '#1B313A', color: 'white' }}>
                Prev
              </Button>
            </div>
            <div
              style={{
                maxWidth: '50%',
                flexBasis: '50%',
                textAlign: 'right',
              }}>
              <Button
                onClick={handleNext}
                loading={loading}
                disabled={disableNextButton}
                style={{ backgroundColor: '#1B313A', color: 'white' }}>
                Next
              </Button>
            </div>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Admin;
