import MenuButton from './MenuButton';
import React from 'react';
import { invalidateStore } from 'actions/fetchData';
import image from 'assets/img/bcs-grading-logo-dark-bg.svg';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Container,
  Divider,
  Icon,
  Image,
  Menu,
  Popup,
} from 'semantic-ui-react';
import { useDispatch, useSelector } from 'hooks';

const PageHeader = () => {
  const dispatch = useDispatch();
  const { go, push } = useHistory();
  const user = useSelector('user');
  const { data: { role, roles = [] } = {} } = user ?? {};

  const hasMultipleRoles = roles.length > 1;

  const handleLogout = () => {
    dispatch(invalidateStore('user'));
    push('/api/centralgrading/v1/logout');
    go(0);
  };

  const handleSwitchRoles = () => {
    dispatch({ payload: null, type: 'USER_SET_ROLE' });
    push('/roleSelector');
  };

  return (
    <div>
      <Menu fixed="top" style={{ backgroundColor: '#1B313A' }}>
        <Container style={{ width: '80%' }}>
          <Menu.Item header>
            <Image
              size="small"
              src={image}
              style={{
                marginRight: '1.5em',
                paddingTop: 0,
                paddingBottom: 0,
              }}
            />
          </Menu.Item>
          {!isEmpty(user?.data) ? (
            <div
              style={{
                position: 'absolute',
                right: '10%',
                display: 'flex',
                height: '100%',
              }}>
              <Menu.Item style={{ float: 'right', color: 'white' }}>
                Hello, {user?.data?.userAccount?.userName}
              </Menu.Item>
              {hasMultipleRoles && !isEmpty(role) ? (
                <Popup
                  basic
                  flowing
                  on="click"
                  position="bottom right"
                  style={{
                    padding: 0,
                  }}
                  trigger={
                    <Menu.Item style={{ float: 'right', color: 'white' }}>
                      <Icon name="user" />
                    </Menu.Item>
                  }>
                  <Button.Group vertical>
                    <MenuButton onClick={handleSwitchRoles}>
                      Switch roles
                    </MenuButton>
                    <Divider style={{ margin: 0, padding: 0 }} />
                    <MenuButton onClick={handleLogout}>Logout</MenuButton>
                  </Button.Group>
                </Popup>
              ) : (
                <Menu.Item
                  onClick={handleLogout}
                  style={{ float: 'right', color: 'white' }}>
                  Logout
                </Menu.Item>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </Container>
      </Menu>
    </div>
  );
};

PageHeader.propTypes = {};

export default PageHeader;
