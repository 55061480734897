import '../../assets/css/assignment.css';
import Alert from '../Alert';
import { isEmpty } from 'lodash';
import {
  Button,
  Checkbox,
  Container,
  Form,
  Header,
  Grid,
  Segment,
} from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import { fetchDataIfNeeded, postData } from '../../actions/fetchData';
import { useArray, useDispatch, useObjectState, useSelector } from 'hooks';

const roles = {
  GRADER: 'Central Grader',
  INSTRUCTOR: 'Instructor',
  MANAGER: 'Manager of Central Graders',
  SUPPORT: 'Support',
  TA: 'Classroom TA',
};

const AddUser = () => {

  const dispatch = useDispatch();
  const { push } = useHistory();
  const { data: filters } = useSelector('filters');

  const [courses, setCourses, { includes: includesCourses, toggle: toggleCourse }] = useArray([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [instructorCourses, setInstructorCourses, { includes: includesInstructorCourses, toggle: toggleInstructorCourse }] = useArray([]);
  const [programs, setPrograms, { includes: includesPrograms, toggle: toggleProgram }] = useArray([]);
  const [userDetail, setUserDetail] = useState(null);
  const [
    roleCodeList,
    setRoleCodeList,
    { flatBy: flatRoleCodeList, includes: includesRoleList, push: pushRoleList, remove: removeRoleList },
  ] = useArray([], { type: 'object' });
  const [showRestOfForm, setShowRestOfForm] = useState(false);
  const [form, , setFormValue, setFormValues] = useObjectState({
    firstName: '',
    lastName: '',
    username: '',
  });
  const { firstName, lastName, username } = form;

  const loadFilters = useCallback(() => {
    dispatch(fetchDataIfNeeded('filters'));
  },[dispatch]);

  useEffect(() => {
    loadFilters();
  }, [loadFilters]);

  const isValidEmail = email =>
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      String(email).toLowerCase()
    );

  const handleChange = event => {
    const {
      target: { name, value },
    } = event;
    setFormValue(name, value);
    setError(null);
  };

  const handleCheckBoxChange = (type, id) => {
    if (type === 'courses') return toggleCourse(id);
    if (type === 'instructorCourse') return toggleInstructorCourse(id);
    if (type === 'program') return toggleProgram(id);
  };

  const handleFindUser = async () => {
    try {
      setError(null);
      setLoading(true);
      const rawResponse = await postData('/api/centralgrading/v1/findUser', {
        username,
      });
      const responseBody = await rawResponse.json();
      if (responseBody.ok === false) {
        throw responseBody;
      }
      if (!!responseBody) {
        if (!!responseBody.userData) {
          setRoleCodeList(responseBody.userData.roles ?? []);
          if (!!responseBody?.courses) {
            setCourses((responseBody?.courses ?? []).map(({ id }) => id));
          }
          if (!!responseBody?.instructorCourses) {
            setInstructorCourses((responseBody?.instructorCourses ?? []).map(({ id }) => id));
          }
          if (!!responseBody?.programs) {
            setPrograms((responseBody?.programs ?? []).map(({ id }) => id));
          }
        }
        if (!!responseBody.userAccount) {
          setFormValues({
            firstName: responseBody?.userAccount?.firstName,
            lastName: responseBody?.userAccount?.lastName,
          });
        }
      }
      setShowRestOfForm(true);
      setUserDetail(responseBody);
    } catch (e) {
      console.warn(e);
      setError('There was an error in the request findUser');
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async () => {
    const isValid = f => f !== null && typeof f !== 'undefined' && f !== '';
    const { firstName, lastName, username } = form;
    if (!isValidEmail(username)) return setError('Invalid email format');
    if (
      !isValid(username) ||
      !isValid(firstName) ||
      !isValid(lastName) ||
      roleCodeList.length === 0
    ) {
      return setError('Please complete all the required fields.');
    }

    try {
      setLoading(true);
      const rawResponse = await postData(
        '/api/centralgrading/v1/upsertUser',
        {
          active: true,
          courses,
          firstName,
          instructorCourses,
          lastName,
          programs,
          roleCodeList: flatRoleCodeList('name'),
          username,
        }
      );
      const responseBody = await rawResponse.json();
      if (responseBody.ok === false || !!responseBody.errorCode) {
        throw responseBody;
      }
      push('/admin');
    } catch (e) {
      console.warn(e);
      const errorMap = {
        BCS_ACCOUNT_MISSING:
          'A new account with BCS courses needs to be created in Salesforce first.',
        BCS_COURSE_ENROLLMENT_MISSING:
          "This account doesn't have the selected courses as enrollments in BCS and needs to be updated in Salesforce.",
      };
      setError(
        errorMap[e?.errorCode] ?? 'There was an error with the request.'
      );
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = role => {
    const roleExists = includesRoleList(role?.name, 'name');
    if (!roleExists) {
      pushRoleList(role);
    } else {
      removeRoleList('name', role?.name);
    }
    setError(null);
  };

  const roleArray = [
    { id: 1, name: 'Central Grader' },
    { id: 2, name: 'Manager of Central Graders' },
    { id: 3, name: 'Classroom TA' },
    { id: 6, name: 'Instructor' },
    { id: 5, name: 'Support' },
  ];

  const disableUserAccountInput = !!userDetail?.userAccount;

  const classesContainerStyle = {
    color: '#333',
    marginBottom: 10,
    maxHeight: 300,
    overflowY: 'auto',
  };

  return (
    <div>
      <Container style={{ marginTop: '7em', width: '70%' }}>
        <Button as={Link} color="grey" to={{ pathname: '/admin' }}>
          <i className="left arrow icon" />
          Back to Home
        </Button>
        <Segment style={{ border: '1px solid #ddd' }}>
          <Grid style={{ padding: '0.5em' }}>
            <div style={{ padding: '0em', width: '100%' }}>
              <Grid.Row
                computer={16}
                mobile={16}
                tablet={16}
                style={{ padding: '1.5em', clear: 'both' }}>
                <Grid.Column
                  computer={4}
                  mobile={16}
                  tablet={16}
                  style={{ width: '100%', float: 'left' }}>
                  <Header size="large">Add a User</Header>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row
                computer={16}
                mobile={16}
                tablet={16}
                style={{ padding: '2em 1.5em', clear: 'both' }}>
                <div>
                  <Form>
                    <div>
                      <Grid.Column
                        computer={8}
                        mobile={16}
                        tablet={16}
                        style={{ float: 'left' }}>
                        <Form.Field style={{ float: 'left' }} required>
                          <label>Email</label>
                          <input
                            placeholder="Email"
                            name={'username'}
                            onChange={handleChange}
                            value={username}
                          />
                        </Form.Field>
                      </Grid.Column>
                      <Grid.Column
                        computer={8}
                        mobile={16}
                        tablet={16}
                        style={{ float: 'right', marginTop: '25px' }}>
                        {!showRestOfForm ? (
                          <Button
                            type="submit"
                            style={{
                              float: 'right',
                              background: 'blue',
                              color: '#fff',
                            }}
                            disabled={
                              !form?.username || form?.username?.length === 0
                            }
                            loading={loading}
                            onClick={handleFindUser}>
                            Search
                            <i className="right arrow icon" />
                          </Button>
                        ) : (
                          <div />
                        )}
                      </Grid.Column>
                    </div>
                  </Form>
                </div>
              </Grid.Row>
              {showRestOfForm ? (
                <div>
                  <Grid.Row
                    computer={16}
                    mobile={16}
                    tablet={16}
                    style={{
                      padding: '1em 1.5em',
                      marginTop: '3em',
                      borderTop: '1px solid #ddd',
                      background: '#fff',
                      height: '100px',
                    }}>
                    <React.Fragment>
                      <Form>
                        <div>
                          <Grid.Column
                            computer={6}
                            mobile={16}
                            tablet={16}
                            style={{ float: 'left' }}>
                            <Form.Field style={{ float: 'left' }} required>
                              <label>First Name</label>
                              <input
                                disabled={disableUserAccountInput}
                                placeholder="First Name"
                                name="firstName"
                                onChange={handleChange}
                                value={firstName}
                              />
                            </Form.Field>
                          </Grid.Column>
                          <Grid.Column
                            computer={6}
                            mobile={16}
                            tablet={16}
                            style={{ float: 'left', marginLeft: '1em' }}>
                            <Form.Field style={{ float: 'left' }} required>
                              <label>Last Name</label>
                              <input
                                disabled={disableUserAccountInput}
                                placeholder="Last Name"
                                name="lastName"
                                onChange={handleChange}
                                value={lastName}
                              />
                            </Form.Field>
                          </Grid.Column>
                        </div>
                      </Form>
                    </React.Fragment>
                  </Grid.Row>
                  <Grid.Row
                    computer={16}
                    mobile={16}
                    tablet={16}
                    style={{
                      padding: '1em 1.5em',
                      marginTop: '0em',
                      borderTop: '1px solid #ddd',
                      background: '#fff',
                      height: '300px',
                    }}>
                    {
                      <React.Fragment>
                        <Form>
                          <Form.Field>
                            Roles:{' '}
                            {roleCodeList
                              .map(curRole => curRole.name)
                              .join(' - ')}
                          </Form.Field>
                          {roleArray.map(curRole => {
                            return (
                              <Form.Field key={`role-${curRole.id}`}>
                                <Checkbox
                                  label={curRole.name}
                                  name={curRole.name}
                                  value={curRole.id}
                                  checked={includesRoleList(
                                    curRole.name,
                                    'name'
                                  )}
                                  onClick={() => handleRoleChange(curRole)}
                                />
                              </Form.Field>
                            );
                          })}
                          {includesRoleList(roles.TA, 'name') && (
                            <div style={{ width: '100%' }}>
                              <Header size="small" style={{ color: '#333' }}>
                                Classroom TA - Classes
                              </Header>
                              {!isEmpty(filters?.courseList) && (
                                <Container style={classesContainerStyle}>
                                  {filters.courseList.map(c => (
                                    <Checkbox
                                      key={c.id}
                                      style={{
                                        style: 'both',
                                        width: '100%',
                                        marginTop: '1em',
                                      }}
                                      checked={includesCourses(c.id)}
                                      onChange={e =>
                                        handleCheckBoxChange('courses', c.id)
                                      }
                                      label={c.name}
                                    />
                                  ))}
                                </Container>
                              )}
                            </div>
                          )}
                          {includesRoleList(roles.INSTRUCTOR, 'name') && (
                            <div style={{ width: '100%' }}>
                              <Header size="small" style={{ color: '#333' }}>
                                Instructor - Classes
                              </Header>
                              {!isEmpty(filters?.courseList) && (
                                <Container style={classesContainerStyle}>
                                  {filters.courseList.map(c => (
                                    <Checkbox
                                      key={c.id}
                                      style={{
                                        style: 'both',
                                        width: '100%',
                                        marginTop: '1em',
                                      }}
                                      checked={includesInstructorCourses(c.id)}
                                      onChange={e =>
                                        handleCheckBoxChange('instructorCourse', c.id)
                                      }
                                      label={c.name}
                                    />
                                  ))}
                                </Container>
                              )}
                            </div>
                          )}
                          {includesRoleList(roles.GRADER, 'name') && (
                            <div style={{ width: '100%' }}>
                              <Header size="small" style={{ color: '#333' }}>
                                Programs
                              </Header>
                              {!!filters && !!filters.programList ? (
                                filters.programList.map(f => (
                                  <Checkbox
                                    key={f.id}
                                    style={{
                                      style: 'both',
                                      width: '100%',
                                      marginTop: '1em',
                                    }}
                                    checked={includesPrograms(f.id)}
                                    onChange={() =>
                                      handleCheckBoxChange('programs', f.id)
                                    }
                                    label={f.name}
                                  />
                                ))
                              ) : (
                                <div>No available programs</div>
                              )}
                            </div>
                          )}

                          {!!error && (
                            <div style={{ marginTop: '0.5em' }}>
                              <Alert type="error">{error}</Alert>
                            </div>
                          )}

                          <Grid.Column
                            computer={8}
                            mobile={16}
                            tablet={16}
                            style={{ float: 'right', marginTop: '25px' }}>
                            <Button
                              type="submit"
                              style={{
                                float: 'right',
                                background: 'blue',
                                color: '#fff',
                              }}
                              onClick={handleSubmit}
                              loading={loading}>
                              {'Create'}
                              <i className="right arrow icon" />
                            </Button>
                          </Grid.Column>
                        </Form>
                      </React.Fragment>
                    }
                  </Grid.Row>
                </div>
              ) : (
                <div />
              )}
            </div>
          </Grid>
        </Segment>
      </Container>
    </div>
  );
};

export default AddUser;
