import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  Button,
  Container,
  Form,
  Header,
  Grid,
  Segment,
  TextArea,
  Dropdown,
  Message,
} from 'semantic-ui-react';
import '../../assets/css/assignment.css';
import { fetchDataIfNeeded, postData } from '../../actions/fetchData';
import Alert from '../Alert';

class Contact extends Component {
  state = {
    selectedClasses: [],
    invalidMessageField: false,
    message: '',
    loading: false,
    success: null,
    error: null,
  };

  componentDidMount() {
    const { loadFilters } = this.props;
    loadFilters();
  }

  async submitMessage(message, courseIdList) {
    this.setState({ loading: true, error: null, success: null });
    try {
      const response = await postData(
        '/api/centralgrading/v1/sendMessageToManager',
        {
          content: message,
          courseIdList: courseIdList,
        }
      );
      if (response.ok === false) {
        throw response;
      }
      this.setState({ success: true, loading: false, error: null });
    } catch (e) {
      console.warn(e);
      this.setState({
        error: 'There was an error trying to send the message.',
        loading: false,
      });
    }
  }

  render() {
    const { filters } = this.props;
    let courseList = [];
    if (!!filters && !!filters.courseList) {
      const list = filters.courseList;
      courseList = list.map(l => {
        return { key: l.id, value: l.id, text: l.name };
      });
    }

    const { message, selectedClasses, invalidMessageField } = this.state;
    return (
      <div>
        <Container style={{ marginTop: '7em', width: '70%' }}>
          <Button as={Link} color="grey" to={{ pathname: '/' }}>
            <i className="left arrow icon" />
            Back
          </Button>
          <Segment style={{ border: '1px solid #ddd' }}>
            <Grid style={{ padding: '0em' }}>
              <div style={{ padding: '0em', width: '100%' }}>
                <Header
                  as={'h2'}
                  style={{ paddingTop: '0.8em', paddingLeft: '0.5em' }}>
                  Message Central Grading Manager
                </Header>

                <Grid.Column
                  computer={16}
                  mobile={16}
                  tablet={16}
                  style={{
                    padding: '2em 1.5em',
                    clear: 'both',
                    borderTop: '1px solid #ddd',
                  }}>
                  {!this.state.success ? (
                    <React.Fragment>
                      <p>
                        Enter a message below to contact the Central Grading
                        Manager. Optionally, you may select classes that apply
                        specifically to your message.
                      </p>

                      <Form>
                        <Form.Field
                          required
                          label={'Message'}
                          control={TextArea}
                          placeholder="Message"
                          value={message || ''}
                          onChange={(e, { value: message }) => {
                            this.setState({
                              message,
                              invalidMessageField: false,
                              error: null,
                            });
                          }}
                        />

                        <Form.Field style={{ width: 500 }}>
                          <label>Applicable Classes</label>
                          <Dropdown
                            placeholder="Select Classes"
                            fluid
                            selection
                            search
                            multiple
                            value={selectedClasses}
                            options={courseList}
                            name="class"
                            onChange={(e, { value }) => {
                              this.setState({
                                selectedClasses: value,
                                error: null,
                              });
                            }}
                          />
                        </Form.Field>

                        {invalidMessageField && (
                          <Alert type={'error'}>
                            Please complete the "Message" field.
                          </Alert>
                        )}
                        {this.state.error && (
                          <Alert type={'error'}>{this.state.error}</Alert>
                        )}

                        <Button
                          disabled={!message || message.length === 0}
                          loading={this.state.loading}
                          style={{
                            float: 'right',
                            marginBottom: '1em',
                            marginTop: '1em',
                          }}
                          onClick={() => {
                            if (!message || message.length === 0) {
                              this.setState({ invalidMessageField: true });
                            } else {
                              this.submitMessage(message, selectedClasses);
                            }
                          }}
                          color="teal">
                          Send Message
                          <i className="right arrow icon" />
                        </Button>
                      </Form>
                    </React.Fragment>
                  ) : (
                    <Message positive> Message sent successfully</Message>
                  )}
                </Grid.Column>
              </div>
            </Grid>
          </Segment>
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { filters = {} } = state;
      let f = null;
      if (!!filters && !!filters.data) {
        f = filters.data;
      }
      return { filters: f };
    },
    dispatch => ({
      loadFilters: async () => {
        dispatch(fetchDataIfNeeded('filters'));
      },
    })
  )(Contact)
);
