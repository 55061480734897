import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import NavigationWrapper from './components/NavigationWrapper';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, message: '' };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, message: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
    this.props.clearUserErrors();
    this.props.clearErrorBoundary();
  }

  componentDidMount() {
    const { history } = this.props;
    history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  render() {
    const { history, children } = this.props;
    const { hasError, message } = this.state;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <NavigationWrapper>
          <Button
            icon="left arrow"
            content="Back"
            labelPosition="left"
            onClick={() => history.goBack()}
          />
          <h1>Something went wrong.</h1>
          <p>
            Please contact{' '}
            <a href="mailto: support@bootcampspot.com">
              support@bootcampspot.com
            </a>{' '}
            for assistance.
          </p>
          {message && <p>Error: {message}</p>}
        </NavigationWrapper>
      );
    }

    return children;
  }
}

export default withRouter(
  connect(undefined, dispatch => ({
    clearUserErrors: () => {
      dispatch({ type: 'CLEAR_ERRORS' });
    },
    clearErrorBoundary: () => {
      dispatch({ type: 'CLEAR_ERRORBOUNDARY' });
    },
  }))(ErrorBoundary)
);
