import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { Grid, Header, Dropdown, Icon } from 'semantic-ui-react';
import {
  fetchDataIfNeeded,
  updatedQuery,
  changedFilter,
  invalidateStore,
} from '../../actions/fetchData';
import QueueDateFilter from '../QueueDateFilter';
import { debounce } from 'lodash';

export class QueueFilters extends Component {
  state = {
    receivedAt: 0,
    programList: [],
    courseList: [],
    statusList: [],
    externalAppList: [],
    assignmentNames: [],
    selectedAssignments: [],
    studentSearchQuery: '',
    lastStudentSearchQuery: null,
    selectedExternalAppIds: [],
  };

  handleAssignmentValueChange = (e, { value }) => {
    this.setState({ selectedAssignments: value });

    const { assignmentList = [] } = this.props.filters;

    const selectedAssignmentsIds = assignmentList.reduce((s, c) => {
      if (value.includes(c.name)) {
        s.push(c.id);
      }
      return s;
    }, []);

    this.props.filterQueue({
      assignments: selectedAssignmentsIds,
    });
  };

  componentDidMount() {
    const {
      user: { data },
    } = this.props;
    if (!!data && !!data.role) {
      const isManager = data.role.name === 'Manager of Central Graders';
      if (isManager) {
        this.props.loadGraders();
      }
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { loadStudents, statusCode } = this.props;
    if (statusCode !== 500) {
      loadStudents();
    }
    // NOTE: weird issue with batched updates not showing each timestamp thus we are keeping state for receivedAt
    if (this.props.receivedAt > prevState.receivedAt) {
      const { filters, query, studentList = [], receivedAt } = this.props;
      const {
        programs = [],
        courses = [],
        assignments = [],
        selectedExternalAppIds = [],
      } = query;
      const {
        programList = [],
        courseList = [],
        assignmentList = [],
        statusList = [],
        externalAppList = [],
      } = filters;

      const assignmentNames = assignmentList
        .reduce((s, value) => {
          if (
            (courses.length === 0 || courses.includes(value.courseId)) &&
            (programs.length === 0 || programs.includes(value.programId)) &&
            !s.includes(value.name)
          ) {
            s.push(value.name);
          }
          return s;
        }, [])
        .sort();

      const selectedAssignments = assignmentList
        .reduce((s, value) => {
          if (assignments.includes(value.id) && !s.includes(value.name)) {
            s.push(value.name);
          }
          return s;
        }, [])
        .sort();

      this.setState({
        receivedAt,
        programList,
        courseList: courseList.filter(({ programId }) => {
          return programs.length === 0 || programs.includes(programId);
        }),
        assignmentNames,
        selectedAssignments,
        studentList,
        statusList,
        selectedExternalAppIds,
        externalAppList,
      });
    }
  }

  handleStudentValueChange = (e, { value }) => {
    let { studentList = [] } = this.props;
    studentList = studentList.filter(x => value.includes(x.id));

    this.props.updateStudents(studentList);
    this.props.filterQueue({
      students: value,
    });
  };

  searchStudent = debounce(searchQuery => {
    const { lastStudentSearchQuery } = this.state;

    if (!this.props.searchingStudents) {
      if (
        lastStudentSearchQuery == null ||
        searchQuery.indexOf(lastStudentSearchQuery) !== 0 ||
        this.props.searchStudentHasMoreResults
      ) {
        this.props.searchStudent(searchQuery);
        this.setState({ lastStudentSearchQuery: searchQuery });
      }
    }
  }, 300);

  handleSearchChange = (e, { searchQuery }) => {
    this.searchStudent(searchQuery);
    this.setState({ studentSearchQuery: searchQuery });
  };

  handleSearchGraders = debounce((_, { searchQuery }) => {
    this.props.loadGraders(searchQuery);
  }, 500);

  render() {
    const {
      filterQueue,
      changedProgramsFilter,
      changedPlatformFilter,
      changedCoursesFilter,
      clearFilters,
    } = this.props;
    const {
      totalResults,
      query,
      studentList = [],
      graderList = [],
    } = this.props;
    const {
      programList,
      selectedExternalAppIds,
      courseList,
      statusList,
      assignmentNames,
      selectedAssignments,
      externalAppList,
    } = this.state;
    const {
      startDate,
      endDate,
      programs = [],
      courses = [],
      students = [],
      claimerId = null,
      statusList: statusListValues = [],
    } = query;
    const userRole = this.props.user?.data?.role?.name;
    const isEmpty =
      !statusListValues.length &&
      !programs.length &&
      !courses.length &&
      !students.length &&
      !claimerId &&
      !startDate &&
      !endDate;

    return (
      <React.Fragment>
        <Header>
          Filter Queue{' '}
          <small>
            <em>
              {totalResults || 0} results{' '}
              {!isEmpty && (
                <Icon
                  name="times circle outline"
                  onClick={() => {
                    clearFilters();
                  }}
                />
              )}
            </em>
          </small>
        </Header>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333' }}>
            Program
          </Header>
          <Dropdown
            placeholder="Program"
            fluid
            selection
            multiple
            value={programs}
            options={programList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            name="program"
            onChange={(e, { value }) => {
              changedProgramsFilter({
                programs: value,
              });
            }}
          />
        </Grid.Column>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Class
          </Header>
          <Dropdown
            placeholder="Class"
            fluid
            selection
            search
            multiple
            value={courses}
            options={courseList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            name="class"
            onChange={(e, { value }) => {
              changedCoursesFilter({
                courses: value,
              });
            }}
          />
        </Grid.Column>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Assignment
          </Header>
          <Dropdown
            placeholder="Assignment"
            fluid
            selection
            search
            multiple
            value={selectedAssignments}
            options={assignmentNames.map(value => ({
              key: value,
              value: value,
              text: value,
            }))}
            name="assignment"
            onChange={this.handleAssignmentValueChange}
          />
        </Grid.Column>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Student
          </Header>
          <Dropdown
            fluid
            multiple
            name="students"
            selection
            placeholder="Search Student"
            search
            onChange={this.handleStudentValueChange}
            value={students}
            onSearchChange={this.handleSearchChange}
            searchQuery={this.state.studentSearchQuery}
            options={studentList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            loading={this.props.searchingStudents}
          />
        </Grid.Column>
        {userRole === 'Manager of Central Graders' && (
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
              Grader
            </Header>
            <Dropdown
              fluid
              name="graders"
              selection
              placeholder="Search Grader"
              search
              clearable
              onChange={(_, { value }) =>
                filterQueue({ claimerId: value === '' ? null : value })
              }
              onSearchChange={this.handleSearchGraders}
              value={claimerId}
              options={graderList.map(({ id, firstName, lastName }) => ({
                key: id,
                value: id,
                text: `${firstName} ${lastName}`,
              }))}
            />
          </Grid.Column>
        )}
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Submission Date
          </Header>
          <QueueDateFilter
            label="Start"
            value={startDate}
            isOutsideRange={endDate && (date => date.isAfter(endDate, 'day'))}
            onChange={startDate => {
              filterQueue({ startDate });
            }}
          />
          <br />
          <br />
          <QueueDateFilter
            label="End"
            value={endDate}
            isOutsideRange={
              startDate && (date => date.isBefore(startDate, 'day'))
            }
            onChange={endDate => {
              filterQueue({ endDate });
            }}
          />
        </Grid.Column>
        {userRole === 'Manager of Central Graders' && (
          <Grid.Column computer={16} mobile={16} tablet={16}>
            <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
              Status
            </Header>
            <Dropdown
              placeholder="Status"
              fluid
              selection
              multiple
              value={statusListValues}
              search
              options={statusList.map(status => ({
                key: status,
                value: status,
                text: status,
              }))}
              name="status"
              onChange={(e, { value }) => {
                filterQueue({
                  statusList: value,
                });
              }}
            />
          </Grid.Column>
        )}
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Platform
          </Header>
          <Dropdown
            placeholder="Select Platform"
            fluid
            selection
            multiple
            value={selectedExternalAppIds}
            options={externalAppList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
              id: id,
            }))}
            name="platform"
            onChange={(e, obj) => {
              let { value } = obj;
              changedPlatformFilter({
                selectedExternalAppIds: value,
              });
            }}
          />
        </Grid.Column>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const {
        query,
        filters,
        queue,
        students,
        searchStudents,
        graders,
        user,
      } = state;
      return {
        receivedAt: Math.max(
          query.receivedAt,
          filters.receivedAt,
          graders.receivedAt
        ),
        query,
        user,
        filters: filters.data,
        totalResults: queue.data.totalResults,
        graderList: graders.data.graders.sort((a, b) => {
          if (a.firstName > b.firstName) {
            return 1;
          }
          if (a.firstName < b.firstName) {
            return -1;
          }
          return 0;
        }),
        studentList: [
          ...students.data.filter(
            x => query.students && query.students.includes(x.id)
          ),
          ...searchStudents.students,
        ],
        searchStudentHasMoreResults:
          searchStudents.studentCount > searchStudents.length,
        searchingStudents: searchStudents.isFetching,
        statusCode: user?.error?.statusCode,
      };
    },
    dispatch => ({
      filterQueue: async payload => {
        dispatch(updatedQuery(payload));
      },
      changedProgramsFilter: async payload => {
        dispatch(changedFilter('programs', payload));
        dispatch(updatedQuery(payload));
      },
      changedPlatformFilter: async payload => {
        dispatch(changedFilter('selectedExternalAppIds', payload));
        dispatch(updatedQuery(payload));
      },
      changedCoursesFilter: async payload => {
        dispatch(changedFilter('courses', payload));
        dispatch(updatedQuery(payload));
      },
      loadStudents: async () => {
        dispatch(fetchDataIfNeeded('students'));
      },
      loadGraders: async payload => {
        dispatch(invalidateStore('graders'));
        dispatch(fetchDataIfNeeded('graders', payload));
      },
      clearFilters: async payload => {
        dispatch(invalidateStore('query'));
        dispatch(updatedQuery(payload));
      },
      searchStudent: async payload => {
        dispatch(invalidateStore('searchStudents'));
        dispatch(fetchDataIfNeeded('searchStudents', payload));
      },
      updateStudents: async payload => {
        dispatch({ type: 'UPDATE_STUDENTS', payload: payload });
      },
    })
  )(QueueFilters)
);
