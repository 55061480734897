import moment from 'moment';
import { postData } from 'actions/fetchData';
import { useCallback, useEffect, useState, useRef } from 'react';
import {
  useDispatch as useDispatchRedux,
  useSelector as useSelectorRedux,
} from 'react-redux';

export const useArray = (
  initialState,
  config = {
    type: 'default',
  }
) => {
  const { type } = config;

  const [state, setState] = useState(initialState);

  const find = (id, value) =>
    type === 'object'
      ? state.find(element => element[id] === value)
      : undefined;

  const flatBy = parser =>
    typeof parser === 'string'
      ? state.map(elem => elem[parser])
      : state.map(elem => parser(elem));

  const includes = (value, id = 'id') =>
    type === 'object'
      ? !!state.find(element => element[id] === value)
      : state.includes(value);

  const push = element => setState(state => [...state, element]);

  const remove =
    type === 'object'
      ? (key, value) =>
          setState(state => state.filter(elem => elem[key] !== value))
      : value => setState(state => state.filter(elem => elem !== value));

  const removeByIndex = index =>
    setState(state => state.filter((e, eIndex) => eIndex !== index));

  const replace = (next, previous, id = 'id') => {
    const newState =
      type === 'object'
        ? state.filter(elem => elem[id] !== previous[id])
        : state.filter(elem => elem !== previous);
    newState.push(next);
    setState(newState);
  };

  const replaceValues = (nextValues = {}, key = 'id', value) => {
    if (value === undefined || type !== 'object') return undefined;
    const index = state.findIndex(elem => elem[key] === value);
    if (index < 0) return undefined;
    setState(state => {
      const newState = [...state];
      newState[index] = { ...newState[index], ...nextValues };
      return newState;
    });
  };

  const toggle = element => {
    if (state.includes(element)) {
      remove(element);
    } else {
      push(element);
    }
  };

  const functions = {
    find,
    flatBy,
    includes,
    push,
    remove,
    removeByIndex,
    replace,
    replaceValues,
    toggle,
  };
  return [state, setState, functions];
};

export const useDispatch = useDispatchRedux;

export const useDocumentVisibility = () => {
  const [visibility, setVisibility] = useState(document.visibilityState);
  useEffect(() => {
    const onVisibilityChange = () => setVisibility(document.visibilityState);
    document.addEventListener('visibilitychange', onVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', onVisibilityChange);
    };
  }, []);
  return visibility;
};

export const useObjectState = initialValue => {
  const [state, setState] = useState(initialValue);
  const setValue = (id, value) => {
    setState(state => ({ ...state, [id]: value }));
  };
  const setValues = values => {
    setState(state => ({ ...state, ...values}));
  }
  return [state, setState, setValue, setValues];
};

export const useSelector = reducer => {
  const selector = useSelectorRedux(
    typeof reducer === 'string' ? state => state[reducer] : reducer
  );
  return selector;
};

export const usePostData = (url = '', config = {}) => {
  const { manual = true } = config ?? {};

  const [loading, setLoading] = useState(false);

  const execute = useCallback(
    async (data = {}) => {
      try {
        setLoading(true);
        const response = await postData(url, data);
        if (response?.ok === false) {
          response.message = await response.text();
          throw response;
        }
        return response;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [url]
  );

  useEffect(() => {
    if (manual) return;
    execute();
  }, [execute, manual]);

  return [execute, { loading }];
};

export const useSelectTrack = () => {
  const ref = useRef();
  const { current } = ref;
  const [selection, setSelection] = useState(null);
  const eventName = 'blur';
  const track = event =>
    setSelection({
      end: event?.target?.selectionEnd,
      start: event?.target?.selectionStart,
    });
  useEffect(() => {
    current && current.addEventListener(eventName, track);
    return () => {
      current && current.removeEventListener(eventName, track);
    };
  }, [current]);
  return [ref, selection];
};

export const useTimer = (initialValue, { tickInterval = 1000 } = {}) => {
  const [state, setState] = useState(initialValue);

  useEffect(() => {
    if (typeof state === 'number') {
      const interval = setInterval(() => {
        setState(state => state + tickInterval);
      }, tickInterval);
      return () => clearInterval(interval);
    }
  }, [tickInterval, state]);

  const duration = moment.duration(state, 'milliseconds');

  const hours = Math.floor(duration.asHours());
  const minutes = Math.floor(duration.asMinutes() % 60);
  const seconds = Math.floor(duration.asSeconds() % 60);

  const parse = value => value.toString().padStart(2, '0');
  const timeSting = `${hours ? `${parse(hours)}:` : ''}${parse(
    minutes
  )}:${parse(seconds)}`;

  return [
    timeSting,
    {
      setMilliseconds: setState,
    },
  ];
};

export const useToggle = initialValue => {
  const [state, setState] = useState(initialValue);
  const toggle = () => setState(state => !state);
  return [state, toggle, setState];
};
