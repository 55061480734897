import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class RequestPasswordReset extends Component {
  state = {
    email: '',
    error: null,
    loading: false,
    success: false,
    emailError: false,
  };

  handleInputChange = (event, { value }) => {
    this.setState({ email: value, emailError: false });
  };

  requestPasswordReset = async () => {
    const { email } = this.state;
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      this.setState({ emailError: true });
      return;
    }

    try {
      this.setState({ loading: true, error: null, success: false });
      const payload = { username: email };
      let response = await fetch(
        '/api/centralgrading/v1/requestPasswordReset',
        {
          method: 'post',
          body: JSON.stringify(payload),
        }
      );
      if (response.ok === false) {
        throw response;
      }
      this.setState({ success: true, loading: false });
    } catch (e) {
      console.warn(e);
      this.setState({
        error: 'There was an error in the request password reset',
        loading: false,
      });
    }
  };

  render() {
    const { email, emailError, error, success, loading } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" style={{ color: '#333' }} textAlign="center">
            Password Reset
          </Header>
          <Form size="large">
            <Segment stacked>
              <div style={{ textAlign: 'left' }}>
                <p>
                  Enter the email address you use when logging in to reset your
                  password. You will receive a password reset email with a link
                  to change your password.
                </p>
                <Form.Input
                  fluid
                  placeholder="Email"
                  label="Email"
                  name="email"
                  type="text"
                  value={email}
                  error={emailError}
                  onChange={this.handleInputChange}
                />
                <Button
                  onClick={this.requestPasswordReset}
                  style={{ backgroundColor: '#1B313A', color: 'white' }}
                  fluid
                  disabled={success}
                  loading={loading}
                  size="large">
                  Request Reset
                </Button>
                {error && <Message negative>{error}</Message>}
                {success && (
                  <Message>
                    Instructions on how to set the new password have been sent
                    to your email.
                  </Message>
                )}
              </div>
              <Segment basic>
                <Link to={'/'}>Have an account? Login here</Link>
              </Segment>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default RequestPasswordReset;
