import React, { useEffect, useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Grid, Header, Loader, Message, Segment, Tab } from 'semantic-ui-react';
import moment from 'moment';
import NavigationWrapper from '../NavigationWrapper';
import Metric from './Metric';
import Weekly from './Weekly';
import WeeklyTrend from './WeeklyTrend';
import { postData } from '../../actions/fetchData';

const CurrentMetrics = ({ open, inProgress, readyForReview }) => (
  <Grid celled="internally">
    <Grid.Row columns={4} textAlign="center">
      <Grid.Column textAlign="left" verticalAlign="middle">
        <Header>Current Metrics</Header>
      </Grid.Column>
      <Grid.Column>
        <Metric label="New" metric={open} />
      </Grid.Column>
      <Grid.Column>
        <Metric label="In Progress" metric={inProgress} />
      </Grid.Column>
      <Grid.Column>
        <Metric label="Ready for Review" metric={readyForReview} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const Metrics = () => {
  const today = moment();
  const history = useHistory();
  const [metrics, setMetrics] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [range, setRange] = useState({
    start: today
      .clone()
      .startOf('week')
      .toISOString(),
    end: today
      .clone()
      .endOf('week')
      .toISOString(),
  });

  useEffect(() => {
    setLoading(true);
    postData('/api/centralgrading/v1/metrics', {
      since: range.start,
      until: range.end,
    })
      .then(res => {
        if (res.status >= 400 && res.status < 500)
          return history.push('/login');
        if (res.status >= 500) throw Error('Server Error');
        return res.json();
      })
      .then(setMetrics)
      .catch(() => setError(true))
      .then(() => setLoading(false));
  }, [range, history]);

  const offsetWeek = offset =>
    setRange({
      start: moment(range.start).add(offset, 'week'),
      end: moment(range.end).add(offset, 'week'),
    });

  if (loading)
    return (
      <NavigationWrapper>
        <Loader active size="large" />
      </NavigationWrapper>
    );

  if (error)
    return (
      <NavigationWrapper>
        <Message error>
          <Message.Header>Unable to fetch data</Message.Header>
        </Message>
      </NavigationWrapper>
    );

  return (
    <NavigationWrapper>
      <Header as="h5" floated="right">
        Updated {today.calendar()}
      </Header>
      <Segment style={{ marginBottom: '2em', clear: 'both' }}>
        <CurrentMetrics
          open={metrics.overallMetrics.markedNew}
          inProgress={metrics.overallMetrics.markedInProgress}
          readyForReview={metrics.overallMetrics.markedReadyForReview}
        />
      </Segment>
      <Tab
        panes={[
          {
            menuItem: 'Week',
            render: () => (
              <Tab.Pane>
                <Weekly
                  metrics={metrics}
                  range={range}
                  offsetWeek={offsetWeek}
                />
              </Tab.Pane>
            ),
          },
          {
            menuItem: 'Weekly Trend',
            render: () => (
              <Tab.Pane>
                <WeeklyTrend />
              </Tab.Pane>
            ),
          },
        ]}
      />
    </NavigationWrapper>
  );
};

export default withRouter(Metrics);
