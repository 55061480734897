import '../../assets/css/assignment.css';
import './item.css';
import { BCS } from '../../utils';
import React from 'react';
import moment from 'moment';
import { Button, Grid, Item } from 'semantic-ui-react';
import { Link, useHistory } from 'react-router-dom';
import {
  postData,
  fetchDataIfNeeded,
  invalidateStore,
} from '../../actions/fetchData';
import { useDispatch, useSelector, useTimer } from 'hooks';

const QueueItem = ({
  assignmentDueDate,
  assignmentName,
  claimedDate,
  course = {},
  externalApp = {},
  externalDate: submissionDate,
  id: submissionId,
  plagiarizedRelatedSubmissions,
  potentialPlagiarism,
  program = {},
  status,
  studentName,
}) => {
  const dispatch = useDispatch();
  const { push } = useHistory();

  const user = useSelector('user');
  const userRole = user?.data?.role;

  const [timeSinceClaim] = useTimer(
    claimedDate ? moment().diff(moment(claimedDate), 'milliseconds') : undefined
  );

  const claim = async payload => {
    await postData('/api/centralgrading/v1/claimSubmission', payload);
    dispatch(invalidateStore('queue'));
    dispatch(fetchDataIfNeeded('queue'));
  };

  const removeClaim = async payload => {
    await postData('/api/centralgrading/v1/removeClaim', payload);
    dispatch(invalidateStore('queue'));
    dispatch(fetchDataIfNeeded('queue'));
  };

  const userRoleName = userRole?.name;

  const submissionDetailsLink = () => {
    const appName = externalApp.name.toLowerCase();
    if (appName !== BCS) {
      return `/canvasSubmission/${submissionId}`;
    } else {
      return `/submission/${submissionId}`;
    }
  };

  return (
    <Item
      className={`queue-item ${externalApp.name.toLowerCase()}`}
      key={submissionId}
      style={{
        padding: 10,
        border: '1px solid #dededf',
        borderRadius: '5px',
      }}>
      <Item.Content style={{ position: 'relative' }}>
        <Link to={submissionDetailsLink()}>
          <Item.Header
            style={{
              fontSize: '20px',
              fontWeight: 'bold',
              color: 'black',
              width: '50%',
            }}>
            {assignmentName}
          </Item.Header>
        </Link>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
          }}>
          {status === 'New' &&
            ['Central Grader', 'Manager of Central Graders'].includes(
              userRoleName
            ) && (
              <Button
                positive
                basic
                style={{ marginTop: '-1.5em' }}
                onClick={async () => {
                  await claim({
                    role: user?.data?.role,
                    submissionId,
                  });
                  push(submissionDetailsLink());
                }}>
                Claim
              </Button>
            )}
          {status === 'In Progress' &&
            ['Manager of Central Graders'].includes(userRoleName) && (
              <Button
                positive
                basic
                style={{ marginTop: '-1.5em' }}
                onClick={() => removeClaim({ submissionId })}>
                Release
              </Button>
            )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginRight: '10px',
              marginTop: '-1.5em',
              textAlign: 'right',
            }}>
            {moment().diff(submissionDate, 'hours') >= 72 &&
              ['New', 'In Progress', 'Ready for Review'].includes(status) && (
                <p className="assignmentDetail" style={{ color: '#C3392B' }}>
                  <strong>Over Target Turnaround</strong>
                </p>
              )}
            {['In Progress'].includes(status) && !!claimedDate && (
              <p className="assignmentDetail">
                <strong>Claimed {timeSinceClaim} ago</strong>
              </p>
            )}
            {potentialPlagiarism && (
              <p className="assignmentDetail" style={{ color: '#DC762C' }}>
                <strong>Flagged for Suspected Plagiarism</strong>
              </p>
            )}
            {plagiarizedRelatedSubmissions && (
              <p className="assignmentDetail" style={{ color: '#DC762C' }}>
                <strong>Flagged Previously for Suspected Plagiarism</strong>
              </p>
            )}
          </div>
        </div>

        <Item.Description>
          <Grid>
            <Grid.Column computer={6} mobile={16} tablet={6}>
              <p>
                <strong>Status:</strong> {status}
              </p>
              <p>
                <strong>Date Submitted:</strong>{' '}
                {moment(submissionDate).format('MM/DD/YYYY')}
              </p>
              <p>
                <strong>Date Due:</strong>{' '}
                {moment(assignmentDueDate).format('MM/DD/YYYY')}
              </p>
              <p>
                <strong>Program:</strong> {program.name}
              </p>
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} tablet={6}>
              <p>
                <strong>Student:</strong> {studentName}
              </p>
              <p>
                <strong>Class:</strong> {course.name}
              </p>
            </Grid.Column>
          </Grid>
        </Item.Description>
        <Button
          as={Link}
          to={submissionDetailsLink()}
          color="grey"
          style={{ bottom: '0', right: '0', position: 'absolute' }}>
          <i className="right arrow icon"></i>
        </Button>
      </Item.Content>
    </Item>
  );
};

export default QueueItem;
