import React from 'react';
import { Table, Dropdown, Input } from 'semantic-ui-react';
import '../../assets/css/search.css';
import '../../assets/css/helpers.css';

const roles = [
  { id: 1, name: 'Classroom TA' },
  { id: 2, name: 'Manager of Central Graders' },
  { id: 3, name: 'Central Grader' },
  { id: 4, name: 'Support' },
  { id: 6, name: 'Instructor' },
];

export const EditUsersRow = ({
  mapKey,
  courseList,
  programList,
  handleInputChange,
  handleDropdownChange,
  userInfo: {
    courses,
    firstName,
    instructorCourses,
    lastName,
    programs,
    roleCodeList,
    username,
  },
}) => {
  const parseDropdown = data =>
    data.map(({ id, name }) => ({
      key: id,
      value: id,
      text: name,
    }));

  const parseRolesDropdown = data =>
    data.map(({ id, name }) => ({
      key: id,
      value: name,
      text: name,
    }));

  return (
    <Table.Row>
      <Table.Cell>
        <Input
          fluid
          name="username"
          value={username}
          onChange={e => handleInputChange(mapKey, e)}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          fluid
          name="lastName"
          value={lastName}
          onChange={e => handleInputChange(mapKey, e)}
        />
      </Table.Cell>
      <Table.Cell>
        <Input
          fluid
          name="firstName"
          value={firstName}
          onChange={e => handleInputChange(mapKey, e)}
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          selection
          search
          fluid
          multiple
          value={programs}
          options={parseDropdown(programList)}
          name="program"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'programs')
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          selection
          search
          fluid
          multiple
          value={courses}
          options={parseDropdown(courseList)}
          name="class"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'courses')
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          selection
          search
          fluid
          multiple
          value={instructorCourses}
          options={parseDropdown(courseList)}
          name="class"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'instructorCourses')
          }
        />
      </Table.Cell>
      <Table.Cell>
        <Dropdown
          selection
          fluid
          search
          multiple
          value={roleCodeList}
          options={parseRolesDropdown(roles)}
          name="role"
          onChange={(e, { value }) =>
            handleDropdownChange(mapKey, value, 'roleCodeList')
          }
        />
      </Table.Cell>
    </Table.Row>
  );
};

export default EditUsersRow;

EditUsersRow.defaultProps = {
  userInfo: {
    username: '',
    firstName: '',
    lastName: '',
    active: true,
    roleCodeList: [],
    programs: [],
    courses: [],
  },
  courseList: [],
  programList: [],
};
