import AddUser from './components/AddUser';
import AddUsers from './components/AddUsers';
import AdminNavigator from './components/AdminNavigator';
import Contact from './components/Contact';
import EditUser from './components/EditUser';
import EditUsers from './components/EditUsers';
import Login from './components/Login';
import Metrics from './components/Metrics';
import QueueNavigator from './components/QueueNavigator';
import RequestPasswordReset from './components/RequestPasswordReset';
import RoleSelector from './components/RoleSelector';
import ResetPassword from './components/ResetPassword';
import SubmissionDetail from './components/SubmissionDetail';
import CanvasSubmissionDetail from './components/CanvasSubmissionDetail';
import TaMessageDetails from './components/TaMessageDetails';

const routes = [
  {
    component: Login,
    path: '/',
  },
  {
    component: Login,
    path: '/login',
  },
  {
    component: RequestPasswordReset,
    path: '/requestPasswordReset',
  },
  {
    component: ResetPassword,
    path: '/resetPassword',
  },
  {
    component: RoleSelector,
    path: '/roleSelector',
  },
  {
    component: QueueNavigator,
    isPrivate: true,
    path: '/queue',
  },
  {
    component: QueueNavigator,
    isPrivate: true,
    path: '/claimed',
  },
  {
    component: QueueNavigator,
    isPrivate: true,
    path: '/overTargetTurnaround',
  },
  {
    component: AdminNavigator,
    isPrivate: true,
    path: '/admin',
  },
  {
    component: Metrics,
    isPrivate: true,
    path: '/metrics',
  },
  {
    component: AddUser,
    isPrivate: true,
    path: '/adduser',
  },
  {
    component: AddUsers,
    isPrivate: true,
    path: '/addusers',
  },
  {
    component: Contact,
    isPrivate: true,
    path: '/contact',
  },
  {
    component: AdminNavigator,
    isPrivate: true,
    path: '/taMessages',
  },
  {
    component: AdminNavigator,
    isPrivate: true,
    path: '/ssm',
  },
  {
    component: EditUser,
    isPrivate: true,
    path: '/edituser/:userId',
  },
  {
    component: EditUsers,
    isPrivate: true,
    path: '/editusers',
  },
  {
    component: SubmissionDetail,
    isPrivate: true,
    path: '/submission/:submissionId',
  },
  {
    component: CanvasSubmissionDetail,
    isPrivate: true,
    path: '/canvasSubmission/:submissionId',
  },
  {
    component: TaMessageDetails,
    isPrivate: true,
    path: '/taMessage/:messageId',
  },
];

export default routes;
