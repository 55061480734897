import React, { createRef } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Container, Menu } from 'semantic-ui-react';
import moment from 'moment';

const NavigationWrapper = props => {
  const { receivedAt } = props.query;
  const userRole = props.user?.data?.role?.name;
  const { pathname } = props.location;
  const contextRef = createRef();

  const getStyleForTab = isSelected => {
    if (isSelected) {
      return {
        paddingTop: 18,
        fontWeight: 'bold',
        textDecoration: 'underline',
      };
    }
    return { paddingTop: 18, fontWeight: 'regular' };
  };

  return (
    <div key={receivedAt} ref={contextRef}>
      <Container
        style={{ marginTop: '87px', width: '80%', maxWidth: '1127px' }}>
        <Menu
          style={{
            border: 'none',
            borderBottom: '2px solid #e2e2e3',
            boxShadow: 'none',
            marginBottom: '1rem',
          }}>
          <Link to={'/queue'}>
            <Menu.Item style={getStyleForTab(pathname === '/queue')}>
              All
            </Menu.Item>
          </Link>
          {['Central Grader', 'Manager of Central Graders'].includes(
            userRole
          ) && (
            <Link to={'/claimed'}>
              <Menu.Item style={getStyleForTab(pathname === '/claimed')}>
                Claimed by Me
              </Menu.Item>
            </Link>
          )}
          <Link
            to={`/overTargetTurnaround?endDate=${moment()
              .subtract(72, 'hours')
              .format()}&statusList=New,In Progress,Ready for Review`}>
            <Menu.Item
              style={getStyleForTab(pathname === '/overTargetTurnaround')}>
              Over Target Turnaround
            </Menu.Item>
          </Link>
          {['Manager of Central Graders'].includes(userRole) && (
            <React.Fragment>
              <Link to={'/taMessages'}>
                <Menu.Item style={getStyleForTab(pathname === '/taMessages')}>
                  TA Messages
                </Menu.Item>
              </Link>
            </React.Fragment>
          )}
          {['Manager of Central Graders', 'Support'].includes(userRole) && (
            <React.Fragment>
              <Link to={'/admin'} style={{ float: 'right' }}>
                <Menu.Item style={getStyleForTab(pathname === '/admin')}>
                  User Admin
                </Menu.Item>
              </Link>
              <Link to={'/metrics'} style={{ float: 'right' }}>
                <Menu.Item style={getStyleForTab(pathname === '/metrics')}>
                  Metrics
                </Menu.Item>
              </Link>
              <Link to={'/ssm'} style={{ float: 'right' }}>
                <Menu.Item style={getStyleForTab(pathname === '/ssm')}>
                  SSM list
                </Menu.Item>
              </Link>
            </React.Fragment>
          )}
          {['Classroom TA', 'Instructor'].includes(userRole) && (
            <Link to={'/contact'}>
              <Menu.Item style={getStyleForTab(pathname === '/contact')}>
                Contact
              </Menu.Item>
            </Link>
          )}
        </Menu>
        {props.children}
      </Container>
    </div>
  );
};

export default withRouter(
  connect(state => {
    const { query, user } = state;

    return {
      query,
      user,
    };
  })(NavigationWrapper)
);
