import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Icon,
  Container,
  Table,
  Message,
  Grid,
} from 'semantic-ui-react';
import { postData } from '../../actions/fetchData';
import AddUsersRow from './AddUsersRow';
import { fetchDataIfNeeded } from '../../actions/fetchData';
import { isValidEmail } from '../../utils';
import '../../assets/css/helpers.css';

const blankRow = {
  username: '',
  firstName: '',
  lastName: '',
  active: true,
  roleCodeList: [],
  programs: [],
  courses: [],
};

export const AddUsers = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submissions, setSubmissions] = useState(new Map([[1, blankRow]]));
  const [usersSubmitted, setUsersSubmitted] = useState([]);
  const [error, setError] = useState('');
  const [errVisible, setErrVisible] = useState(true);
  const [succVisible, setSuccVisible] = useState(true);

  let history = useHistory();

  const { courseList, programList } = useSelector(state => {
    const { filters = {} } = state;
    let f = null;
    if (!!filters && !!filters.data) {
      f = filters.data;
    }
    return f;
  });

  useEffect(() => {
    dispatch(fetchDataIfNeeded('filters'));
  }, [dispatch]);

  const addRow = () => {
    setSubmissions(prevState => {
      const m = new Map(prevState);
      const key = prevState.size + 1;
      m.set(key, blankRow);

      return m;
    });
  };

  const deleteRow = key => {
    setSubmissions(prevState => {
      const m = new Map(prevState);
      m.delete(key);

      return m;
    });
  };

  const returnRowsToRender = () => {
    let arrayOfRows = [];

    for (const [key, value] of submissions.entries()) {
      arrayOfRows.push(
        <AddUsersRow
          key={key}
          mapKey={key}
          handleSetUserInfo={setUserInfo}
          handleSetUsername={setUsername}
          handleInputChange={setInput}
          handleDeleteRow={deleteRow}
          handleDropdownChange={setDropdown}
          courseList={courseList}
          programList={programList}
          userInfo={value}
        />
      );
    }

    return arrayOfRows;
  };

  const setUserInfo = (key, userInfo) => {
    setSubmissions(prevState => {
      const m = new Map(prevState);
      m.set(key, {
        ...prevState.get(key),
        ...userInfo,
      });

      return m;
    });
  };

  const setUsername = (key, username) => {
    setSubmissions(prevState => {
      const m = new Map(prevState);
      m.set(key, {
        ...prevState.get(key),
        username,
      });

      return m;
    });
  };

  const setInput = (key, { target: { name, value } }) => {
    setSubmissions(prevState => {
      const m = new Map(prevState);
      m.set(key, {
        ...prevState.get(key),
        [name]: value,
      });

      return m;
    });
  };

  const setDropdown = (key, data, name) => {
    setSubmissions(prevState => {
      const m = new Map(prevState);
      m.set(key, {
        ...prevState.get(key),
        [name]: data,
      });

      return m;
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    let profilesToUpsert = [];
    let completedUpserts = [];
    let err = [];
    let errProfile = [];

    for (const [, value] of submissions.entries()) {
      profilesToUpsert.push(value);
    }

    await Promise.all(
      profilesToUpsert.map(async profile => {
        const {
          courses,
          firstName,
          instructorCourses,
          lastName,
          programs,
          roleCodeList,
          username,
        } = profile;
        const isValid = f => f !== null && typeof f !== 'undefined' && f !== '';

        if (!isValidEmail(username)) {
          err.push(`${username}: Invalid email format`);
          errProfile.push(profile);
          return;
        }

        if (roleCodeList.includes('Classroom TA') && !courses.length) {
          err.push(`${username} must belong to a Classroom TA class`);
          errProfile.push(profile);
          return;
        }

        if (roleCodeList.includes('Instructor') && !instructorCourses.length) {
          err.push(`${username} must belong to an Instructor class`);
          errProfile.push(profile);
          return;
        }

        if (
          isValid(username) &&
          isValid(firstName) &&
          isValid(lastName) &&
          isValid(roleCodeList)
        ) {
          try {
            const rawResponse = await postData(
              '/api/centralgrading/v1/upsertUser',
              {
                active: true,
                courses,
                firstName,
                instructorCourses,
                lastName,
                programs,
                roleCodeList,
                username,
              }
            );
            const responseBody = await rawResponse.json();
            if (responseBody.ok === false || !!responseBody.errorCode) {
              throw responseBody;
            }
            completedUpserts.push(username);
          } catch (e) {
            console.warn(e);
            const errorMap = {
              BCS_ACCOUNT_MISSING: `${username}: A new account with BCS courses needs to be created in Salesforce first.`,
              BCS_COURSE_ENROLLMENT_MISSING: "This account doesn't have the selected courses as enrollments in BCS and needs to be updated in Salesforce."
            };
            const errorMessage =
              errorMap[e.errorCode] ??
              `${username}: There was an error with the request.`;
            err.push(errorMessage);
            errProfile.push(profile);
          }
        } else {
          err.push(`${username}: Please complete all the required fields.`);
          errProfile.push(profile);
        }
      })
    );

    setErrVisible(true);
    setSuccVisible(true);
    setError(err);
    setLoading(false);
    setUsersSubmitted(completedUpserts);

    if (errProfile.length) {
      const errorMap = errProfile.map((profile, i) => {
        return [i + i, profile];
      });
      setSubmissions(new Map(errorMap));
    } else {
      setSubmissions(new Map([[1, blankRow]]));
    }

    if (!err.length) {
      history.push('/admin');
    }
  };

  return (
    <Container style={{ marginTop: '7em', padding: '0em 1em', width: '100%' }}>
      <Button
        as={Link}
        icon="left arrow"
        content="Back"
        labelPosition="left"
        to={{ pathname: '/admin' }}
      />
      <Table celled columns={6}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Program</Table.HeaderCell>
            <Table.HeaderCell>TA Classes</Table.HeaderCell>
            <Table.HeaderCell>Instructor Classes</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {courseList && programList && returnRowsToRender()}
          <Table.Row>
            <Table.Cell colSpan="7">
              <Icon
                name="add"
                color="blue"
                className="float-right"
                link
                onClick={addRow}
              />
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column>
            {!!error && errVisible && (
              <Grid.Row>
                <Message
                  header="Error"
                  error
                  compact
                  list={error}
                  onDismiss={() => setErrVisible(false)}
                />
              </Grid.Row>
            )}
            {!!usersSubmitted.length && succVisible && (
              <Message
                header="Users uploaded successfully"
                success
                compact
                list={usersSubmitted}
                onDismiss={() => setSuccVisible(false)}
              />
            )}
          </Grid.Column>
          <Grid.Column floated="right">
            <Button
              color="blue"
              icon="save"
              content="Save All Users"
              labelPosition="left"
              floated="right"
              disabled={loading}
              onClick={handleSubmit}
              style={{
                marginBottom: '1em',
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default AddUsers;
