const STATE = {
  isFetching: false,
  didInvalidate: false,
  receivedAt: 0,
  data: [],
};

const ssm = (state = STATE, action) => {
  switch (action.type) {
    case 'INVALIDATE_SSM':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        receivedAt: 0,
        data: [],
      };
    case 'REQUEST_SSM':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_SSM':
      return {
        ...state,
        isFetching: false,
        ...action.payload,
      };
    case 'RECEIVE_ERROR':
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default ssm;
