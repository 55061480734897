import React, { Component } from 'react';
import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    const {
      location: { search },
    } = props;
    const params = new URLSearchParams(search);
    this.token = params.get('token');
    this.user = params.get('user');
    let error = null;
    if (this.token === null || this.user === null) {
      error = 'Invalid reset password link';
    }
    this.state = {
      password: '',
      passwordConfirmation: '',
      error: error,
      loading: false,
      validationError: null,
      success: false,
    };
  }

  handleInputChange = (event, { name, value }) => {
    this.setState({ [name]: value, validationError: null });
  };

  resetPassword = async () => {
    const { password, passwordConfirmation } = this.state;
    const { token, user } = this;
    const error = this.validatePassword(password, passwordConfirmation);
    if (error) {
      this.setState({ validationError: error });
      return;
    }

    try {
      this.setState({ loading: true, error: null });
      const payload = { username: user, resetToken: token, password: password };
      let response = await fetch('/api/centralgrading/v1/resetPassword', {
        method: 'post',
        body: JSON.stringify(payload),
      });
      if (response.ok === false) {
        throw response;
      }
      this.setState({ success: true, loading: false });
    } catch (e) {
      console.warn(e);
      this.setState({
        error: 'There was an error in the reset password',
        loading: false,
      });
    }
  };

  validatePassword = (password, passwordConfirmation) => {
    if (password === '') {
      return 'Password cannot be empty';
    }

    if (password.length < 8) {
      return 'Your password must be at least 8 characters in length';
    }

    if (
      !/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[`~!@#$%^&*()_+=?]).{8,}/.test(
        password
      )
    ) {
      return 'Your password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character';
    }

    if (password !== passwordConfirmation) {
      return 'Password and password confirmation fields must match.';
    }
    return null;
  };

  render() {
    const {
      password,
      passwordConfirmation,
      validationError,
      error,
      success,
    } = this.state;
    return (
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle">
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as="h2" style={{ color: '#333' }} textAlign="center">
            Password Reset
          </Header>
          <Form size="large">
            <Segment stacked textAlign="left">
              <p>Enter a new password for your Central Grading account.</p>
              <Form.Input
                fluid
                placeholder="Password"
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={this.handleInputChange}
              />
              <Form.Input
                fluid
                placeholder="Confirm"
                label="Confirm"
                name="passwordConfirmation"
                type="password"
                value={passwordConfirmation}
                onChange={this.handleInputChange}
              />
              <Button
                onClick={this.resetPassword}
                style={{ backgroundColor: '#1B313A', color: 'white' }}
                fluid
                disabled={error !== null || success}
                size="large">
                Request Reset
              </Button>
              {validationError && <Message negative>{validationError}</Message>}
              {error && <Message negative>{error}</Message>}
              {success && (
                <Message>
                  Your password was set successfully. You can now{' '}
                  <Link to="/login">login</Link> with your new credentials.
                </Message>
              )}
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    );
  }
}

export default ResetPassword;
