import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Grid, Header, Dropdown, Icon } from 'semantic-ui-react';
import {
  updatedQuery,
  changedFilter,
  invalidateStore,
  fetchDataIfNeeded,
} from '../../actions/fetchData';

export class AdminFilters extends Component {
  state = {
    receivedAt: 0,
    programList: [],
    courseList: [],
    roleList: [],
    searchQuery: {
      pageSize: 50,
      offset: 0,
      searchString: '',
      programs: [],
      courses: [],
      roles: [],
    },
  };

  componentDidMount() {
    const { adminFilters, query, receivedAt } = this.props;
    const { programs = [] } = query;
    const { programList = [], courseList = [], roleList = [] } = adminFilters;
    this.setState({
      receivedAt,
      programList,
      courseList: courseList.filter(({ programId }) => {
        return programs.length === 0 || programs.includes(programId);
      }),
      roleList,
    });
  }

  componentDidUpdate(prevState) {
    const { query, statusCode } = this.props;
    const { programs = [], courses = [], roles = [] } = query;
    const currentSearchQuery = {
      pageSize: 50,
      offset: 0,
      searchString: '',
      programs: programs,
      courses: courses,
      roles: roles,
    };
    // NOTE: weird issue with batched updates not showing each timestamp thus we are keeping state for receivedAt
    if (this.props.receivedAt > prevState.receivedAt) {
      if (statusCode !== 500) {
        this.props.loadUsers(currentSearchQuery);
      }
      const { adminFilters, query, receivedAt } = this.props;
      const { programs = [] } = query;
      const { programList = [], courseList = [], roleList = [] } = adminFilters;

      this.setState({
        receivedAt,
        programList,
        courseList: courseList.filter(({ programId }) => {
          return programs.length === 0 || programs.includes(programId);
        }),
        roleList,
      });
    }
  }

  render() {
    const {
      changedProgramsFilter,
      changedCoursesFilter,
      changedRolesFilter,
      clearFilters,
    } = this.props;
    const { totalResults, query } = this.props;
    const { programList, courseList, roleList } = this.state;
    const { programs = [], courses = [], roles = [] } = query;
    const isEmpty = !programs.length && !courses.length && !roles.length;

    return (
      <React.Fragment>
        <Header>
          Filter Queue{' '}
          <small>
            <em>
              {totalResults || 0} results{' '}
              {!isEmpty && (
                <Icon
                  name="times circle outline"
                  onClick={() => {
                    clearFilters();
                  }}
                />
              )}
            </em>
          </small>
        </Header>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333' }}>
            Program
          </Header>
          <Dropdown
            placeholder="Program"
            fluid
            selection
            multiple
            value={programs}
            options={programList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            name="program"
            onChange={(e, { value }) => {
              changedProgramsFilter({
                programs: value,
              });
            }}
          />
        </Grid.Column>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Class
          </Header>
          <Dropdown
            placeholder="Class"
            fluid
            selection
            search
            multiple
            value={courses}
            options={courseList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            name="class"
            onChange={(e, { value }) => {
              changedCoursesFilter({
                courses: value,
              });
            }}
          />
        </Grid.Column>
        <Grid.Column computer={16} mobile={16} tablet={16}>
          <Header size="small" style={{ color: '#333', marginTop: '1em' }}>
            Role
          </Header>
          <Dropdown
            placeholder="Role"
            fluid
            selection
            multiple
            value={roles}
            options={roleList.map(({ id, name }) => ({
              key: id,
              value: id,
              text: name,
            }))}
            name="role"
            onChange={(e, { value }) => {
              changedRolesFilter({
                roles: value,
              });
            }}
          />
        </Grid.Column>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { query, adminFilters, user, userlist } = state;

      return {
        receivedAt: Math.max(query.receivedAt, adminFilters.receivedAt),
        query,
        adminFilters: adminFilters.data,
        totalResults: userlist.data.totalResults,
        statusCode: user?.error?.statusCode,
      };
    },
    dispatch => ({
      changedProgramsFilter: async payload => {
        dispatch(invalidateStore('userlist'));
        dispatch(changedFilter('programs', payload));
        dispatch(updatedQuery(payload));
      },
      changedCoursesFilter: async payload => {
        dispatch(invalidateStore('userlist'));
        dispatch(changedFilter('courses', payload));
        dispatch(updatedQuery(payload));
      },
      changedRolesFilter: async payload => {
        dispatch(invalidateStore('userlist'));
        dispatch(changedFilter('roles', payload));
        dispatch(updatedQuery(payload));
      },
      clearFilters: async payload => {
        dispatch(invalidateStore('query'));
        dispatch(updatedQuery(payload));
      },
      loadUsers: async (payload, onSuccess) => {
        dispatch(fetchDataIfNeeded('userlist', payload));
      },
    })
  )(AdminFilters)
);
