import './index.css';
import moment from 'moment';
import {
  Button,
  Checkbox,
  Comment,
  Container,
  Divider,
  Form,
  Grid,
  Header,
  Loader,
  Icon,
  Input,
  Message,
  Modal,
  Segment,
  Tab,
  Table,
  Popup,
  Dropdown,
} from 'semantic-ui-react';
import { Link, Redirect } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchDataIfNeeded,
  invalidateStore,
  updatedQuery,
} from '../../actions/fetchData';
import {
  pressOnlyNumbers,
  someElement,
  insertSubstringAtIndex,
} from 'utils/index';
import { isEmpty, times } from 'lodash';
import { usePostData, useTimer, useSelectTrack } from 'hooks';

export const CanvasSubmissionDetail = props => {
  const dispatch = useDispatch();

  const [claimError, setClaimError] = useState('');
  const [comment, setComment] = useState('');
  const [commentCursorPosition, setCommentCursorPosition] = useState(0);
  const [staffComment, setStaffComment] = useState('');
  const [currentComments, setCurrentComments] = useState([]);
  const [grade, setGrade] = useState('');
  const [rubricGrade, setRubricGrade] = useState({});
  const [plagiarized, setPlagiarized] = useState(false);
  const [incomplete, setIncomplete] = useState(false);
  const [gradeError, setGradeError] = useState(false);
  const [error, setError] = useState(null);
  const [redirect, setRedirect] = useState(null);
  const [open, setOpen] = useState(false);
  const [taUpdateGrade, setTaUpdateGrade] = useState(false);
  const submissionId = props.match.params.submissionId;
  const [selectedTab, setSelectedTab] = useState(0);
  const [commentRef, commentSelection] = useSelectTrack();

  const [approveGrade, { loading: approveGradeLoading }] = usePostData(
    '/api/centralgrading/v1/approveGrade'
  );
  const [claimSubmission, { loading: claimSubmissionLoading }] = usePostData(
    '/api/centralgrading/v1/claimSubmission'
  );
  const [removeClaim, { loading: removeClaimLoading }] = usePostData(
    '/api/centralgrading/v1/removeClaim'
  );
  const [saveStaffComment, { loading: staffCommentLoading }] = usePostData(
    '/api/centralgrading/v1/commentSubmissionStaffChat'
  );
  const [
    upsertSubmissionGrade,
    { loading: upsertSubmissionGradeLoading },
  ] = usePostData('/api/centralgrading/v1/upsertSubmissionGrade');
  const disableActions = someElement([
    approveGradeLoading,
    claimSubmissionLoading,
    removeClaimLoading,
    staffCommentLoading,
    upsertSubmissionGradeLoading,
  ]);

  const { submission, query, isFetching, user } = useSelector(state => {
    const {
      submission = {},
      query = {},
      user = {},
      relatedSubmissions = {},
    } = state;
    let s = {
      submission: {},
      isFetching: true,
      user: {},
      relatedSubmissions: {},
    };
    if (!!submission && !!submission.data) {
      s.submission = submission.data;
      s.query = query;
      s.isFetching = submission.isFetching;
      s.user = user;
      s.relatedSubmissions = relatedSubmissions;
    }
    return s;
  });

  const {
    data = null,
    claimedDate,
    comments = [],
    status = '',
    gradeApproval = {},
    approvedByAccount = {},
    relatedSubmissions = {},
  } = submission;

  const [timeSinceClaim, { setMilliseconds }] = useTimer();

  useEffect(() => {
    dispatch(
      updatedQuery({
        submissionId: parseInt(submissionId, 10),
      })
    );
    dispatch(fetchDataIfNeeded('submission'));
  }, [dispatch, submissionId]);

  const userId = user?.data?.userAccount?.id;
  const userRoleName = user?.data?.role?.name;
  const hasTAPermissions = ['Classroom TA', 'Instructor'].includes(
    userRoleName
  );
  const editMode =
    ['Flagged', 'Ready for Review'].includes(status) && !hasTAPermissions;
  const commentRequired = !hasTAPermissions;
  const marginBottomStyling = { marginBottom: '0.5em' };
  const chatCommentList = submission.chatCommentList;
  const marginBottomSubmissionStyling = { marginBottom: '1.5em' };

  useEffect(() => {
    setClaimError('');
    setCurrentComments(submission?.comments ?? []);
    const incomplete =
      (submission?.grade?.grade === 'I' ||
        submission?.grade?.grade === '0.00') &&
      !submission?.potentialPlagiarism;
    const plagiarized = !!submission?.potentialPlagiarism;
    setIncomplete(incomplete);
    setPlagiarized(plagiarized);
    setGrade(incomplete || plagiarized ? '' : submission?.grade?.grade ?? '');
    submission?.claimedDate &&
      setMilliseconds(moment().diff(submission?.claimedDate, 'milliseconds'));
  }, [setClaimError, setMilliseconds, submission]);

  const onCurrentCommentChange = (i, e) => {
    const newComments = [...currentComments];
    newComments[i] = {
      ...newComments[i],
      content: e?.target?.value ?? '',
    };
    setCurrentComments(newComments);
  };

  const generateSubmissionText = linkData => {
    const links = linkData.filter(({ key }) => key === 'SubmissionUrl').length;
    if (links > 1 || links === 0) {
      return `This submission is made up of ${links} links`;
    }
    return `This submission is made up of 1 link`;
  };

  const previousGraderFirstName = submission.gradedByAccount
    ? submission.gradedByAccount.firstName
    : '';

  const previousGraderLastName = submission.gradedByAccount
    ? submission.gradedByAccount.lastName
    : '';

  // Using this to sort rubric ratings
  const sortFunc = (a, b) => {
    if (a.points > b.points) {
      return -1;
    }
    return 1;
  };

  // see https://stackoverflow.com/questions/9215162/html-force-url-hyperlink-to-be-treated-as-non-relative-absolute
  const formatHref = href => {
    if (href.startsWith('https:') || href.startsWith('http:')) {
      return href;
    }
    return `//${href}`;
  };

  const handleClaim = async payload => {
    try {
      setClaimError('');
      await claimSubmission(payload);
      dispatch(invalidateStore('submission'));
      dispatch(fetchDataIfNeeded('submission'));
      dispatch(invalidateStore('queue'));
    } catch (e) {
      setClaimError(
        e?.message?.toString() ?? 'There was an error claiming the submission'
      );
    }
  };

  const handleRemoveClaim = async payload => {
    await removeClaim(payload);
    dispatch(invalidateStore('submission'));
    dispatch(fetchDataIfNeeded('submission'));
    dispatch(invalidateStore('queue'));
    dispatch(fetchDataIfNeeded('queue'));
  };

  const newStaffComment = async () => {
    const payload = {
      submissionId: Number(submissionId),
      potentialPlagiarism: Boolean(plagiarized),
      comment: staffComment,
      role: user?.data?.role,
    };
    await saveStaffComment(payload);
    dispatch(fetchDataIfNeeded('submission', {}, true));
    setStaffComment('');
  };

  const checkForExistingRubricGrades = (externalId, currentGrade, i) => {
    const previousGrades = submission?.rubricGrades;

    if (previousGrades.length) {
      if (rubricGrade[i]?.grade === undefined) {
        const [grade] = previousGrades.filter(
          grade => grade.criteriaId === externalId
        );
        const newRubricGradeObject = {
          ...rubricGrade,
          [i]: {
            id: grade.Id,
            grade: grade.points,
          },
        };
        setRubricGrade(newRubricGradeObject);
        return String(grade.points);
      }
    }
    return String(currentGrade);
  };

  const calculatePointRange = (a, i) => {
    const isLastIndex = a.length - 1 === i;
    if (isLastIndex) {
      if (a[i].points === 0) {
        return `0 pts.`;
      }
      return `${a[i].points} to >0 pts.`;
    }
    return `${a[i].points} to >${a[++i].points + 1} pts.`;
  };

  const colorAttributes = (outerI, innerI, a) => {
    const grade = parseInt(rubricGrade[outerI]?.grade, 10);
    const isLastIndex = a.length - 1 === innerI;
    const upperBound = a[innerI].points;
    const lowerBound = isLastIndex ? 0 : a[++innerI].points;
    const isWithinRange =
      (grade <= upperBound && grade > lowerBound) ||
      (upperBound === 0 && lowerBound === 0 && grade === 0);
    if (isNaN(grade)) return false;
    return isWithinRange;
  };

  const isRubricInputDisabled = () =>
    submission.excused ||
    (!hasTAPermissions &&
      !editMode &&
      (incomplete || plagiarized || !!submission?.rubricGrades?.length));

  const isGradeInputDisabled = () =>
    incomplete || plagiarized || submission.excused;

  const inputsDisabled = () => submission.excused;

  const dropdownOptions = (text, amount) => {
    if (!amount) return [];
    return times(amount).map(index => {
      const value = index + 1;
      return {
        key: value,
        text: `Add ${value} ${text}${value > 1 ? 's' : ''}`,
        value,
      };
    });
  };

  const buildShortcut = (type, amount) => {
    const shortcut = index => ({
      bullet: '* ',
      lineBreak: '\n',
      number: `${index + 1} - `,
    });
    setComment(currentComment => {
      const start = commentSelection?.start ?? currentComment.length;
      const newString = `\n${times(amount)
        .map(index => shortcut(index)[type])
        .join('\n')}\n`;
      return insertSubstringAtIndex(currentComment, newString, start);
    });
  };

  const panes = [
    {
      menuItem: {
        key: 1,
        content: 'Info & Options',
      },
      pane: (
        <Tab.Pane key={1} attached={false} style={{ minHeight: '100vh' }}>
          <Header as={'h2'}>{submission.assignmentName}</Header>
          <div style={marginBottomStyling}>
            <strong>Status:</strong>{' '}
            {submission.excused ? 'Excused' : submission.status}
          </div>
          <div style={marginBottomStyling}>
            <strong>Date Submitted:</strong>{' '}
            {moment(submission.externalDate).format('MM/DD/YYYY')}
          </div>
          <div style={marginBottomStyling}>
            <strong>Due Date:</strong>{' '}
            {moment(submission.assignmentDueDate).format('MM/DD/YYYY')}
          </div>
          <div style={marginBottomStyling}>
            <strong>Program:</strong> {submission.program?.name}
          </div>
          <div style={marginBottomStyling}>
            <strong>Student:</strong> {submission.studentName}
          </div>
          <div style={marginBottomStyling}>
            <strong>Cohort:</strong> {submission.cohortName}
          </div>
          <div style={marginBottomStyling}>
            <strong>Previous Grader:</strong> {previousGraderFirstName}{' '}
            {previousGraderLastName}
          </div>
          <Header as={'h3'}>Options</Header>
          <div style={marginBottomStyling}>
            <Checkbox
              toggle
              checked={plagiarized}
              disabled={inputsDisabled()}
              label="Assignment plagiarized"
              onChange={(e, { checked }) => onPlagiarizedToggle(checked)}
            />
          </div>
          <div style={marginBottomStyling}>
            <Checkbox
              toggle
              checked={incomplete}
              disabled={inputsDisabled()}
              label="Mark as Incomplete"
              onChange={(e, { checked }) => onIncompleteToggle(checked)}
            />
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 2,
        content: (
          <Popup
            content="These comments are intended to be seen by students."
            position="bottom right"
            trigger={
              <div role="button">
                Comments{' '}
                {commentRequired && (
                  <sup>
                    <Icon name="asterisk" size="small" color="red" />
                  </sup>
                )}
              </div>
            }
          />
        ),
      },
      pane: (
        <Tab.Pane key={2} attached={false} style={{ minHeight: '100vh' }}>
          <Comment.Group>
            <Header as="h3" dividing>
              <div>
                Comments{' '}
                <span
                  style={{
                    fontSize: '0.5em',
                    color: 'red',
                    float: 'right',
                    lineHeight: '3.5em',
                    fontWeight: 'normal',
                  }}>
                  {commentRequired && 'Comments are required for submission'}
                </span>
              </div>
            </Header>
            {data &&
              data
                .filter(({ key }) => key === 'Comment')
                .map((comment, i) => {
                  return (
                    <Comment key={`data-comment-${i}`}>
                      <Comment.Content>
                        <Comment.Author>
                          {comment.children[1].content}
                        </Comment.Author>
                        <Comment.Metadata>
                          <div>
                            {moment(comment.children[0].content).format(
                              'MM/DD/YYYY [at] HH:mm:ss A'
                            )}
                          </div>
                        </Comment.Metadata>
                        <Comment.Text>{comment.content}</Comment.Text>
                      </Comment.Content>
                    </Comment>
                  );
                })}
            {currentComments.map((comment, i) => {
              const canBeEdited =
                userId === comment?.user?.id &&
                editMode &&
                currentComments.length === i + 1 &&
                !gradeApproval?.id;
              return (
                <Comment key={`current-comment-${i}`}>
                  <Comment.Content>
                    <Comment.Author>
                      {`${comment.user.firstName} ${comment.user.lastName}`}
                    </Comment.Author>
                    <Comment.Metadata>
                      <div>
                        {moment(comment.date).format(
                          'MM/DD/YYYY [at] HH:mm:ss A'
                        )}
                      </div>
                    </Comment.Metadata>
                    {!canBeEdited ? (
                      <Comment.Text>{comment.content}</Comment.Text>
                    ) : (
                      <>
                        <Form>
                          <Form.TextArea
                            rows={3}
                            value={comment.content}
                            onChange={e => onCurrentCommentChange(i, e)}
                          />
                        </Form>
                      </>
                    )}
                  </Comment.Content>
                </Comment>
              );
            })}
            {gradeApproval.id && approvedByAccount.id && (
              <Comment key={gradeApproval.id}>
                <Comment.Content>
                  <Comment.Author>
                    {`${approvedByAccount.firstName} ${approvedByAccount.lastName}`}
                  </Comment.Author>
                  <Comment.Metadata>
                    <div>
                      {moment(gradeApproval.date).format(
                        'MM/DD/YYYY [at] HH:mm:ss A'
                      )}
                    </div>
                  </Comment.Metadata>
                  <Comment.Text>{gradeApproval.comment}</Comment.Text>
                </Comment.Content>
              </Comment>
            )}
            {!editMode && (
              <Form reply>
                <div style={{ display: 'flex', height: '2.8em' }}>
                  <Dropdown
                    text="Bullets"
                    fluid
                    button
                    onChange={(event, { value }) =>
                      buildShortcut('bullet', value)
                    }
                    disabled={inputsDisabled()}
                    options={dropdownOptions('bullet', 5)}
                    name="Bullets"
                    labelPosition="left"
                  />

                  <Dropdown
                    text="Numbers"
                    fluid
                    button
                    onChange={(event, { value }) =>
                      buildShortcut('number', value)
                    }
                    disabled={inputsDisabled()}
                    options={dropdownOptions('number', 5)}
                    name="Numbers"
                    labelPosition="left"
                  />

                  <Dropdown
                    style={{ marginRight: 0 }}
                    text="Breaks"
                    fluid
                    button
                    onChange={(event, { value }) =>
                      buildShortcut('lineBreak', value)
                    }
                    disabled={inputsDisabled()}
                    options={dropdownOptions('line break', 5)}
                    name="Break"
                    labelPosition="left"
                  />
                </div>
                <Form.TextArea
                  style={{ marginTop: '5px' }}
                  onChange={e => onCommentChange(e)}
                  id="commentTextArea"
                  value={comment}
                  disabled={inputsDisabled()}
                  ref={commentRef}
                />
              </Form>
            )}
          </Comment.Group>
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 3,
        content: 'Rubric',
      },
      pane: (
        <Tab.Pane key={3} attached={false} style={{ minHeight: '100vh' }}>
          {submission?.rubric?.rubricCriteria?.map((c, outerI) => (
            <div
              key={`rubric-outer-${outerI}`}
              style={{ marginBottom: '1.5em' }}>
              <Header>
                {c.description}
                <Header.Subheader>{c.longDescription}</Header.Subheader>
              </Header>
              <Header style={{ marginTop: '0' }}>Rating</Header>
              {c.rubricRatings.sort(sortFunc).map((r, i, a) => {
                const applyColorAttributes = colorAttributes(outerI, i, a);
                return (
                  <Table
                    key={`rubric-table-${i}`}
                    celled
                    attached={i === 0 ? 'top' : true}>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell
                          width={6}
                          style={
                            applyColorAttributes
                              ? {
                                  backgroundColor: '#BCD3B0',
                                }
                              : {}
                          }>
                          {calculatePointRange(a, i)}
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          width={10}
                          style={
                            applyColorAttributes
                              ? {
                                  backgroundColor: '#BCD3B0',
                                }
                              : {}
                          }>
                          {r.description}
                          {applyColorAttributes && (
                            <Icon
                              name="check circle"
                              style={{ float: 'right' }}
                            />
                          )}
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell
                          width={16}
                          colSpan="2"
                          style={
                            applyColorAttributes
                              ? {
                                  backgroundColor: '#FCFFF5',
                                }
                              : {}
                          }>
                          {r.longDescription}
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                );
              })}
              <Segment attached="bottom">
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}>
                  <div
                    style={{
                      paddingRight: '1em',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    {rubricGrade[`${outerI}`]?.error && (
                      <Icon
                        name="exclamation triangle"
                        color="red"
                        size="small"
                        circular
                        bordered
                        inverted
                      />
                    )}
                    <Input
                      placeholder="Grade"
                      size="small"
                      style={{ width: '6em' }}
                      type="number"
                      max={c.points}
                      error={rubricGrade[`${outerI}`]?.error}
                      disabled={isRubricInputDisabled()}
                      value={checkForExistingRubricGrades(
                        c.externalId,
                        rubricGrade[`${outerI}`]?.grade,
                        outerI
                      )}
                      onChange={e =>
                        onRubricGradeChange(
                          e,
                          outerI,
                          c.points,
                          c.rubricRatings
                        )
                      }
                      className="gradeInputBoxForCanvas"
                      onWheel={e => e.target.blur()}
                    />
                    <Header style={{ margin: '0 0 0 0.5em' }} size="large">
                      / {c.points}
                    </Header>
                  </div>
                </div>
              </Segment>
            </div>
          ))}
        </Tab.Pane>
      ),
    },
    {
      menuItem: {
        key: 4,
        content: (
          <Popup
            content="These comments are only seen by other staff members. Students do not see these comments."
            position="bottom right"
            trigger={
              <div>
                Internal{' '}
                <sup>
                  <Icon name="asterisk" size="small" color="red" />
                </sup>
              </div>
            }
          />
        ),
      },
      pane: (
        <Tab.Pane key={4} attached={false} style={{ minHeight: '100vh' }}>
          <Comment.Group>
            <span>
              <div
                style={{
                  fontSize: '0.7em',
                  color: 'red',
                  lineHeight: '1.5em',
                  fontWeight: 'normal',
                }}>
                These comments are only seen by other staff members.
                <br />
                Students do not see these comments.
              </div>
            </span>
            {chatCommentList &&
              chatCommentList.map((comment, i) => {
                return (
                  <Comment key={`comment-${i}`}>
                    <Comment.Content>
                      <Comment.Author>
                        {comment.firstName} {comment.lastName}{' '}
                        <span style={{ color: '#d3d3d3', fontSize: 12 }}>
                          {moment(comment.date).format(
                            'MM/DD/YYYY [at] HH:mm:ss A'
                          )}
                        </span>
                      </Comment.Author>
                      <Comment.Text>{comment.content}</Comment.Text>
                      <Comment.Metadata>{comment.roleName}</Comment.Metadata>
                    </Comment.Content>
                  </Comment>
                );
              })}

            <Form reply>
              <Form.TextArea
                onChange={e => onStaffCommentChange(e)}
                value={staffComment}
                disabled={inputsDisabled()}
              />

              <Button
                className="ui primary button"
                disabled={!staffComment || disableActions}
                loading={staffCommentLoading}
                style={{
                  float: 'right',
                }}
                onClick={() => newStaffComment()}>
                Save
              </Button>
            </Form>
          </Comment.Group>
        </Tab.Pane>
      ),
    },
  ];

  const shouldOpenClaimModal = () =>
    status === 'New' &&
    ['Central Grader', 'Manager of Central Graders'].includes(userRoleName);

  const sanitizeNumberList = (lines, currentDigit) => {
    let nextNumber = parseInt(currentDigit) + 1;
    let finishNumberedList = false;

    return lines.map((line, index) => {
      if (!finishNumberedList && /^\d+\s-\s/.test(line)) {
        return `${nextNumber++} -${line
          .split('-')
          .slice(1)
          .join('-')}`;
      } else if (line.length > 0 && index > 0) {
        finishNumberedList = true;
      }
      return line;
    });
  };

  const findFirstDifference = (arrOriginal, arrModified) => {
    const length = Math.min(arrOriginal.length, arrModified.length);

    for (let i = 0; i < length; i++) {
      if (arrOriginal[i] !== arrModified[i]) {
        return i;
      }
    }
    return length;
  };

  const getPreviousLineFormat = line => {
    if (line.trim().startsWith('* ')) {
      return '* ';
    } else if (/^\d+\s-\s/.test(line.trim())) {
      const nextNumber = parseInt(line.match(/^\d+/)[0]) + 1;
      return `${nextNumber} - `;
    } else {
      return '';
    }
  };
  const onCommentChange = e => {
    let cursorPosition = e.target.selectionStart;
    let textBeforeCursor = comment.substring(0, cursorPosition - 1);
    let textAfterCursor = comment.substring(cursorPosition - 1);
    let linesBeforeCursor = textBeforeCursor.split('\n');
    let linesAfterCursor = textAfterCursor.split('\n');

    let newCommentText = e.target.value;
    const textarea = document.getElementById('commentTextArea');
    const lastLine = linesBeforeCursor[linesBeforeCursor.length - 1];

    let formattedText = '';
    const linesA = comment.split('\n');
    const linesB = e.target.value.split('\n');

    if (linesA.length < linesB.length && lastLine) {
      if (
        /^\*\s*$/.test(lastLine.trim()) ||
        /^\d+\s-\s?$/.test(lastLine.trim())
      ) {
        linesBeforeCursor.pop();
        textBeforeCursor = `${linesBeforeCursor.join('\n')}\n\n`;
        cursorPosition -= lastLine.length;
        linesAfterCursor = sanitizeNumberList(linesAfterCursor, 0);
        textAfterCursor = linesAfterCursor.join('\n');
      } else {
        formattedText = getPreviousLineFormat(lastLine);
        if (formattedText.match(/(\d+) - /)) {
          const currentDigit = formattedText.match(/(\d+) - /)[1];
          linesAfterCursor = sanitizeNumberList(linesAfterCursor, currentDigit);
          textAfterCursor = linesAfterCursor.join('\n');
        }
      }

      if (textBeforeCursor.endsWith('\n')) {
        textBeforeCursor = textBeforeCursor.slice(0, -1);
      }

      newCommentText = `${textBeforeCursor}\n${formattedText}${textAfterCursor}`;
    } else if (linesA.length > linesB.length && lastLine) {
      const indexOfFirstDifference = findFirstDifference(linesA, linesB);
      linesBeforeCursor = linesB.slice(0, indexOfFirstDifference);
      linesAfterCursor = linesB.slice(indexOfFirstDifference);

      const lastLine = linesBeforeCursor[linesBeforeCursor.length - 1];
      if (lastLine.match(/(\d+) - /)) {
        const currentDigit = lastLine.match(/(\d+) - /)[1];
        linesAfterCursor = sanitizeNumberList(linesAfterCursor, currentDigit);
        textAfterCursor = linesAfterCursor.join('\n');
        textBeforeCursor = linesBeforeCursor.join('\n');
        newCommentText = `${textBeforeCursor}\n${textAfterCursor}`;
      }
    }
    setComment(newCommentText);

    const newCursorPosition = cursorPosition + formattedText.length + 1;
    textarea.setSelectionRange(newCursorPosition, newCursorPosition);
    setCommentCursorPosition(cursorPosition + formattedText.length);
  };

  useEffect(() => {
    const textarea = document.getElementById('commentTextArea');
    if (textarea) {
      textarea.selectionStart = textarea.selectionEnd = commentCursorPosition;
    }
  }, [comment, commentCursorPosition]);

  const onStaffCommentChange = ({ target: { value } }) => {
    setStaffComment(value);
  };

  const onGradeChange = e => {
    if (!submission?.rubric?.rubricCriteria) {
      if (hasTAPermissions && !taUpdateGrade) {
        setTaUpdateGrade(true);
      }
      // we want this to work if no rubric
      if (e.target.value > 120 || e.target.value < 0) {
        setError('Grade is out of range.');
        setGradeError(true);
      } else {
        setGradeError(false);
      }
      if (!submission?.grade?.grade || editMode || hasTAPermissions) {
        setGrade(e.target.value);
      }
    }
  };

  const onRubricGradeChange = (e, i, points, ratings) => {
    const rg = e.target.value;

    // calculate the right externalId to pass back to nexus
    const [rating] = ratings.filter((r, i, a) => {
      // this is a duplication of logic, can be refined further
      const grade = parseInt(rg, 10);
      const isLastIndex = a.length - 1 === i;
      const upperBound = a[i].points;
      const lowerBound = isLastIndex ? 0 : a[++i].points;
      return (
        (grade <= upperBound && grade > lowerBound) ||
        (upperBound === 0 && lowerBound === 0 && grade === 0)
      );
    });

    const error = parseInt(rg, 10) > points;
    let newRubricGradeObject;
    if (hasTAPermissions) {
      setTaUpdateGrade(true);
      newRubricGradeObject = {
        ...rubricGrade,
        [i]: {
          ...rubricGrade[i],
          submissionId: parseInt(submissionId, 10),
          grade: rg,
          ratingId: rating?.id,
          error,
        },
      };
    } else {
      newRubricGradeObject = {
        ...rubricGrade,
        [i]: {
          ...(!editMode ? {} : rubricGrade[i]),
          grade: rg,
          ratingId: rating?.id,
          error,
        },
      };
    }

    setRubricGrade(newRubricGradeObject);
    const newOverallGrade = Object.values(newRubricGradeObject).reduce(
      (total, g) => {
        return total + Number(g.grade);
      },
      0
    );
    const gradeErrors = Object.values(newRubricGradeObject).filter(
      g => g.error === true
    );
    setGrade(newOverallGrade);
    setGradeError(gradeErrors.length > 0);
  };

  const onPlagiarizedToggle = checked => {
    setPlagiarized(checked);
    if (checked) setGradeError(false);
  };

  const onIncompleteToggle = checked => {
    setIncomplete(checked);
    if (checked) setGradeError(false);
  };

  const checkIfClaimer = claimedByAccount =>
    claimedByAccount && userId === claimedByAccount?.id;

  const gradeString = grade => {
    if (incomplete || plagiarized) return 'I';
    return String(grade);
  };

  const submitGrade = async () => {
    setError(null);
    setGradeError(false);

    if (hasTAPermissions) {
      try {
        const graderComment = comments[0]?.content;
        await approveGrade({
          comment: comment === '' ? graderComment : comment,
          finalGrade: gradeString(grade),
          potentialPlagiarism: false,
          submissionId: Number.parseInt(submissionId, 10),
          submissionRubricGrades: Object.values(rubricGrade),
        });
        setRedirect('/queue');
      } catch (e) {}
    } else {
      try {
        const lastIndex = currentComments.length - 1;
        const parsedSubmissionId = Number.parseInt(submissionId, 10);
        const payload = {
          comment: !editMode ? comment : currentComments[lastIndex]?.content,
          grade: gradeString(grade),
          potentialPlagiarism: plagiarized,
          role: user?.data?.role,
          submissionId: parsedSubmissionId,
          ...(!editMode
            ? {
                rubricGrades:
                  plagiarized || incomplete ? [] : Object.values(rubricGrade),
              }
            : {
                submissionRubricGrades: Object.values(rubricGrade).map(g => ({
                  ...g,
                  submissionId: parsedSubmissionId,
                })),
              }),
        };
        await upsertSubmissionGrade(payload);
        setRedirect('/claimed');
      } catch (e) {
        setGradeError(e?.message);
      }
    }
  };

  const checkCanSubmit = async () => {
    const isClaimer =
      checkIfClaimer(submission.claimedByAccount) || hasTAPermissions;

    // rubric error logic
    const rubricGrades = Object.values(rubricGrade).filter(r => r.grade !== '')
      .length;
    const rubricCriteria = submission.rubric?.rubricCriteria?.length;
    const currentComment = !editMode
      ? comment
      : currentComments[currentComments.length - 1]?.content;

    let rubricError =
      typeof rubricCriteria !== 'undefined' && rubricGrades !== rubricCriteria;
    const setErrorAndOpen = error => {
      setError(error);
      setOpen(true);
    };
    if (!isClaimer)
      return setErrorAndOpen(
        'You must claim this submission before submitting.'
      );
    if (rubricError && !incomplete)
      return setErrorAndOpen(
        'You must submit a grade for each rubric criteria.'
      );
    if (!!submission?.grade?.grade && !hasTAPermissions && !editMode)
      return setErrorAndOpen('A grade already exists for this assignment.');
    if (plagiarized && !currentComment && commentRequired)
      return setErrorAndOpen(
        'A marked plagiarized assignment must be submitted with a comment.'
      );
    if (!grade && !currentComment && commentRequired) {
      setGradeError(true);
      return setErrorAndOpen(
        'A grade and comment are required to submit the grade.'
      );
    }
    if (gradeError)
      return setErrorAndOpen('A valid grade is required for submission.');
    if (!grade && !incomplete && !plagiarized) {
      setGradeError(true);
      return setErrorAndOpen('A grade is required to submit the grade.');
    }
    if (!currentComment && commentRequired)
      return setErrorAndOpen('A comment is required to submit the grade.');

    return submitGrade();
  };

  const parseBool = value => (!!value ? 'Yes' : 'No');

  const renderGrade = () => (
    <div
      style={{
        alignItems: 'center',
        color: 'white',
        display: 'flex',
      }}>
      {gradeError && (
        <Icon
          name="exclamation triangle"
          color="red"
          size="small"
          circular
          bordered
          inverted
        />
      )}
      {['In Progress'].includes(status) && claimedDate && (
        <p
          style={{ fontSize: 17, fontWeight: 600, margin: '0px 30px 0px 0px' }}>
          Claimed {timeSinceClaim} ago
        </p>
      )}
      <div style={{ paddingRight: '1em', marginLeft: '0.2em' }}>Grade</div>
      <div
        style={{
          paddingRight: '1em',
          display: 'flex',
          alignItems: 'center',
        }}>
        <Input
          placeholder="Grade"
          size="small"
          style={{ width: '6em' }}
          type="number"
          max="120"
          error={!!gradeError}
          disabled={isGradeInputDisabled()}
          value={incomplete || plagiarized ? '' : grade}
          onChange={e => onGradeChange(e)}
          onKeyPress={e => pressOnlyNumbers(e)}
          className="gradeInputBoxForCanvas"
        />
        <Header style={{ color: 'white', margin: '0 0 0 0.5em' }} size="medium">
          / 100
        </Header>
      </div>
      <Button
        positive
        color={editMode ? 'yellow' : 'green'}
        disabled={disableActions}
        icon="check"
        labelPosition="right"
        loading={approveGradeLoading || upsertSubmissionGradeLoading}
        content={hasTAPermissions ? 'Approve' : editMode ? 'Edit' : 'Submit'}
        onClick={() => checkCanSubmit()}
      />
    </div>
  );

  if (redirect) return <Redirect to={redirect} />;

  if (isFetching) return <Loader active />;

  return (
    <div>
      <Grid style={{ marginTop: '4em' }}>
        <Grid.Row style={{ backgroundColor: '#6A7174' }}>
          <Grid.Column>
            <Container>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Button
                    style={{ marginRight: '0.75em' }}
                    icon="close"
                    labelPosition="left"
                    content="Exit"
                    as={Link}
                    negative
                    to={{
                      pathname: query.pathname || '/queue',
                      search: query.search,
                    }}
                  />
                  {status === 'In Progress' &&
                    ['Manager of Central Graders'].includes(userRoleName) && (
                      <Button
                        content="Release"
                        disabled={disableActions}
                        loading={removeClaimLoading}
                        onClick={() => {
                          handleRemoveClaim({
                            submissionId: Number(submissionId),
                          });
                        }}
                      />
                    )}
                  <Header
                    style={{ color: 'white', margin: '0 0 0 0.5em' }}
                    size="medium">
                    <Header.Content>
                      {submission.assignmentName}
                      <Header.Subheader style={{ color: 'white' }}>
                        {submission.program ? submission.program.name : ''}
                      </Header.Subheader>
                    </Header.Content>
                  </Header>
                </div>
                <Popup
                  content="Please include a comment before submitting"
                  disabled={!commentRequired || editMode}
                  position="bottom right"
                  trigger={renderGrade()}
                />
              </div>
            </Container>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid container stackable>
        <Grid.Row divided columns="equal">
          <Modal onClose={() => setOpen(false)} open={open}>
            <Modal.Header>Action Required</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>{error}</p>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button content="Close" onClick={() => setOpen(false)} />
            </Modal.Actions>
          </Modal>
          <Modal open={shouldOpenClaimModal()}>
            <Modal.Header>Action Required</Modal.Header>
            <Modal.Content>
              <Modal.Description>
                <p>A submission must be claimed before it can be graded.</p>
              </Modal.Description>
              <Header>Info</Header>

              <div style={marginBottomStyling}>
                <strong>Status:</strong> {submission.status}
              </div>
              <div style={marginBottomStyling}>
                <strong>Date Submitted:</strong>{' '}
                {moment(submission.externalDate).format('MM/DD/YYYY')}
              </div>
              <div style={marginBottomStyling}>
                <strong>Due Date:</strong>{' '}
                {moment(submission.assignmentDueDate).format('MM/DD/YYYY')}
              </div>
              <div style={marginBottomStyling}>
                <strong>Program:</strong> {submission.program?.name}
              </div>
              <div style={marginBottomStyling}>
                <strong>Student:</strong> {submission.studentName}
              </div>
              <div style={marginBottomStyling}>
                <strong>Cohort:</strong> {submission.cohortName}
              </div>
              <div style={marginBottomStyling}>
                <strong>Previous Grader:</strong> {previousGraderFirstName}{' '}
                {previousGraderLastName}
              </div>
              {!!claimError && <div style={{ color: 'red' }}>{claimError}</div>}
            </Modal.Content>
            <Modal.Actions>
              <Button
                positive
                content="Claim"
                disabled={disableActions}
                loading={claimSubmissionLoading}
                onClick={() => {
                  handleClaim({
                    role: user?.data?.role,
                    submissionId: Number(submissionId),
                  });
                }}
              />
              <Button
                negative
                as={Link}
                content="Exit to Queue"
                disabled={disableActions}
                icon="close"
                labelPosition="left"
                style={{ marginRight: '0.75em' }}
                to={{
                  pathname: query.pathname || '/queue',
                  search: query.search,
                }}
              />
            </Modal.Actions>
          </Modal>
          <Grid.Column width={10}>
            {data &&
              data.reduce((filtered, body) => {
                let i = 1;
                if (body.key === 'UserSubmittedBody') {
                  filtered.push(
                    <Message key={`user-body-${i}`}>
                      <Message.Content>
                        <Message.Header>User Submitted Content</Message.Header>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: body.content,
                          }}></div>
                      </Message.Content>
                    </Message>
                  );
                  i += 1;
                }
                return filtered;
              }, [])}
            <Divider />
            <Message>
              <Message.Content>
                <Message.Header>
                  {data && generateSubmissionText(data)}
                </Message.Header>
              </Message.Content>
            </Message>
            {data &&
              data.reduce((filtered, link) => {
                if (link.key === 'SubmissionUrl') {
                  const index = filtered.length + 1;
                  filtered.push(
                    <a
                      key={`url-${index}`}
                      href={formatHref(link.content)}
                      target="_blank"
                      rel="noopener noreferrer">
                      <Message icon key={index}>
                        <Message.Content>
                          <Message.Header>Link {index}</Message.Header>
                          {link.content}
                        </Message.Content>
                        <Icon name="arrow right" />
                      </Message>
                    </a>
                  );
                }
                return filtered;
              }, [])}
            {!isEmpty(relatedSubmissions) && (
              <Grid.Column width={10}>
                <Divider />
                <Message>
                  <Message.Content>
                    <Message.Header>Related Submissions</Message.Header>
                  </Message.Content>
                </Message>
                {relatedSubmissions.map((content, i) => (
                  <Message key={i}>
                    <Message.Content>
                      <Message.Header style={{ paddingBottom: 20 }}>
                        <div>Submission {content.id}</div>
                      </Message.Header>
                      <Message.Content style={marginBottomSubmissionStyling}>
                        <div>
                          <strong>Date</strong>{' '}
                          {moment(content.date).format(
                            'MM/DD/YYYY [at] HH:mm:ss A'
                          )}
                        </div>
                        {!isEmpty(content.grade) && (
                          <Grid.Column>
                            <div>
                              <strong>Grade</strong> {content.grade.grade}
                            </div>
                            <div>
                              <strong>Grading Date</strong>{' '}
                              {moment(content.grade.date).format(
                                'MM/DD/YYYY [at] HH:mm:ss A'
                              )}
                            </div>
                          </Grid.Column>
                        )}
                        <div>
                          <strong>Potential Plagiarism</strong>{' '}
                          {parseBool(content.potentialPlagiarism)}
                        </div>
                      </Message.Content>

                      {!isEmpty(content.gradedByAccount) && (
                        <Message.Content style={marginBottomSubmissionStyling}>
                          <Message.Header>Graded By</Message.Header>
                          <div>
                            <strong>Username</strong>{' '}
                            {content.gradedByAccount.userName}
                          </div>
                          <div>
                            <strong>Full Name</strong>{' '}
                            {content.gradedByAccount.firstName}{' '}
                            {content.gradedByAccount.lastName}
                          </div>
                        </Message.Content>
                      )}

                      {!isEmpty(content.gradeApproval) && (
                        <Message.Content style={marginBottomSubmissionStyling}>
                          <Message.Header>Grade Approval</Message.Header>
                          <div>
                            <strong>Comment</strong>{' '}
                            {content.gradeApproval?.comment}
                          </div>
                          <div>
                            <strong>Date</strong>{' '}
                            {moment(content.gradeApproval?.date).format(
                              'MM/DD/YYYY [at] HH:mm:ss A'
                            )}
                          </div>
                          <div>
                            <strong>Final Grade</strong>{' '}
                            {content.gradeApproval?.finalGrade}
                          </div>
                        </Message.Content>
                      )}

                      {!isEmpty(content.comments) && (
                        <Message.Content style={marginBottomSubmissionStyling}>
                          <Message.Header>Comments</Message.Header>
                          {content.comments &&
                            content.comments.map((commentContent, i) => {
                              return (
                                <Message key={i}>
                                  <Message.Content>
                                    <div>
                                      <strong>
                                        {commentContent.user.firstName}{' '}
                                        {commentContent.user.lastName}
                                      </strong>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: 10,
                                        marginLeft: 5,
                                      }}>
                                      {moment(commentContent.date).format(
                                        'MM/DD/YYYY [at] HH:mm:ss A'
                                      )}
                                    </span>
                                    <div>{commentContent.content}</div>
                                  </Message.Content>
                                </Message>
                              );
                            })}
                        </Message.Content>
                      )}

                      {!isEmpty(content.chatCommentList) && (
                        <Message.Content style={marginBottomSubmissionStyling}>
                          <Message.Header>Internal Comments</Message.Header>
                          {content.chatCommentList.map(
                            (chatCommentContent, i) => {
                              return (
                                <Message key={i}>
                                  <Message.Content>
                                    <div>
                                      <strong>
                                        {chatCommentContent.firstName}{' '}
                                        {chatCommentContent.lastName}
                                      </strong>
                                    </div>
                                    <span
                                      style={{
                                        fontSize: 10,
                                        marginLeft: 5,
                                      }}>
                                      {moment(chatCommentContent.date).format(
                                        'MM/DD/YYYY [at] HH:mm:ss A'
                                      )}
                                    </span>
                                    <div>{chatCommentContent.content}</div>
                                  </Message.Content>
                                </Message>
                              );
                            }
                          )}
                        </Message.Content>
                      )}
                    </Message.Content>
                  </Message>
                ))}
              </Grid.Column>
            )}
          </Grid.Column>
          <Grid.Column>
            <Tab
              menu={{ pointing: true }}
              panes={panes}
              renderActiveOnly={false}
              activeIndex={selectedTab}
              onTabChange={(e, { activeIndex }) => setSelectedTab(activeIndex)}
            />
          </Grid.Column>
        </Grid.Row>
        <Modal open={!!gradeError}>
          <Modal.Header>Action Required</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <p>{gradeError}</p>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setGradeError(null)}>Cancel</Button>
          </Modal.Actions>
        </Modal>
      </Grid>
    </div>
  );
};

export default CanvasSubmissionDetail;
