import { Link, Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import {
  Button,
  Checkbox,
  Container,
  Dropdown,
  Form,
  Header,
  Grid,
  Icon,
  Message,
  Modal,
  Segment,
  TextArea,
} from 'semantic-ui-react';
import moment from 'moment';
import '../../assets/css/assignment.css';
import {
  postData,
  fetchDataIfNeeded,
  invalidateStore,
  updatedQuery,
  patchData,
} from '../../actions/fetchData';
import { IsRoleStaff } from '../helpers/IsRoleStaff';

export class SubmissionDetail extends Component {
  static BCS_CODE = 'BCS';
  static CANVAS_CODE = 'CANVAS';

  constructor(props) {
    super(props);
    const { submission = { data: {} } } = this.props || {};
    const { potentialPlagiarism, grade = {} } = submission.data;
    this.state = {
      potentialPlagiarism,
      grade: grade.grade,
      invalidGradeField: false,
      invalidCommentField: false,
      gradeSubmitted: false,
      gradeApproved: false,
      error: null,
      openModal: false,
      redirectTo: null,
    };
  }

  componentDidMount() {
    this.props.loadSubmissionDetail(this.props.match.params.submissionId);
  }

  checkResubmissions() {
    const {
      assignmentDueDate,
      externalDate: submissionDate,
      relatedSubmissions = [],
    } = this.props.submission.data;
    const submissionHistory = [];
    let lastGrade = null;

    for (let i = 0; i < relatedSubmissions.length; i++) {
      const gradeApproval = relatedSubmissions[i].gradeApproval;
      if (gradeApproval !== null) {
        if (lastGrade == null) {
          lastGrade = gradeApproval.finalGrade;
        }

        submissionHistory.push({
          text:
            gradeApproval.finalGrade === 'I'
              ? 'Marked incomplete'
              : `Marked as ${gradeApproval.finalGrade}`,
          date: moment(gradeApproval.date),
          formattedDate: moment(gradeApproval.date).format('MM/DD/YYYY'),
        });
      }

      submissionHistory.push({
        text:
          i === relatedSubmissions.length - 1
            ? 'Initial submit'
            : 'Resubmission',
        date: moment(relatedSubmissions[i].date),
        formattedDate: moment(relatedSubmissions[i].date).format('MM/DD/YYYY'),
      });
    }
    submissionHistory.push({
      text: 'Due date',
      date: moment(assignmentDueDate),
      formattedDate: moment(assignmentDueDate).format('MM/DD/YYYY'),
    });
    submissionHistory.push({
      text: 'Resubmission',
      date: moment(submissionDate),
      formattedDate: moment(submissionDate).format('MM/DD/YYYY'),
    });
    submissionHistory.sort((x, y) => y.date.unix() - x.date.unix());

    return {
      isLateSubmission:
        relatedSubmissions.length === 0 && submissionDate > assignmentDueDate,
      isResubmission: relatedSubmissions.length > 0,
      submissionDateMessage:
        relatedSubmissions.length > 0
          ? lastGrade == null
            ? 'Resubmitted'
            : `Resubmitted, previously marked as ${
                lastGrade === 'I' ? 'Incomplete' : lastGrade
              }`
          : submissionDate > assignmentDueDate
          ? 'Late submission'
          : false,
      submissionHistory,
    };
  }

  renderCanvasUrlButton = url => {
    let trimmedUrl = url.trim();
    if (trimmedUrl.indexOf('http') === -1) {
      trimmedUrl = 'https://' + trimmedUrl;
    }
    return (
      <Button
        as="a"
        href={trimmedUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          const submissionId = this.props.match.params.submissionId;
          const payload = {
            submissionId: Number.parseInt(submissionId, 10),
          };
          postData(
            '/api/centralgrading/v1/archiveClaimForSubmission',
            payload
          ).then();
        }}
        style={{ width: '100%' }}>
        <i className="right arrow icon" style={{ marginRight: '1em' }} />
        Grade on Canvas
      </Button>
    );
  };

  renderSubmissionUrlButton = ({ content: url, ordinal, children }, i, a) => {
    const { content: title } = children[0];
    const trimmedUrl = url.trim();
    let label;
    let color = '#e0e1e2';

    let radius = i === a.length - 1 ? '0 0 5px 5px' : '0 0 0 0';
    let border = i > 0 ? 'solid 3px #adadae' : '';

    try {
      label = `${new URL(trimmedUrl).hostname.toUpperCase()} ${ordinal + 1}`;
      label = title || label;
    } catch (e) {
      console.error(e);
      label = 'Invalid URL';
      color = 'red';
    }

    return (
      <Button
        as="a"
        href={trimmedUrl}
        target="_blank"
        rel="noopener noreferrer"
        key={ordinal}
        style={{
          backgroundColor: color,
          width: '100%',
          borderRadius: radius,
          borderTop: border,
        }}>
        <i className="right arrow icon" style={{ marginRight: '1em' }} />
        {label}
      </Button>
    );
  };

  setErrorAfterEx = async e => {
    console.warn(e);
    let errorMsg;
    try {
      errorMsg = await e.text();
    } catch (e2) {
      errorMsg = e;
    }

    this.setState({
      error: `Error in submit. ${errorMsg}`,
      loading: false,
    });
  };

  openSubmissionConfirmation = () =>
    this.setState({
      invalidGradeField: !this.state.grade,
      invalidCommentField: !this.state.comment,
      openModal: this.state.grade && this.state.comment,
    });

  submitGrade = async (
    submissionId,
    gradeApproval,
    grade,
    comment,
    potentialPlagiarism
  ) => {
    if (!this.state.grade) {
      this.setState({ invalidGradeField: true });
    } else if (!this.state.comment) {
      this.setState({ invalidCommentField: true });
    } else {
      try {
        this.setState({ loading: true, error: null });
        const { potentialPlagiarism: sPotentialPlagiarism } = this.state;
        const { user } = this.props;
        const payload = {
          comment: this.state.comment || comment,
          grade: this.state.grade || gradeApproval.finalGrade || grade.grade,
          potentialPlagiarism:
            sPotentialPlagiarism !== undefined
              ? sPotentialPlagiarism
              : potentialPlagiarism,
          role: user?.data?.role,
          submissionId: Number.parseInt(submissionId, 10),
        };
        let response = await postData(
          '/api/centralgrading/v1/upsertSubmissionGrade',
          payload
        );
        if (response.ok === false) {
          throw response;
        }
        this.setState({
          success: true,
          loading: false,
          gradeSubmitted: true,
          redirectTo: '/claimed',
        });
      } catch (e) {
        this.setErrorAfterEx(e);
      }
    }
  };

  approveGrade = async payload => {
    try {
      const response = await postData(
        '/api/centralgrading/v1/approveGrade',
        payload
      );
      if (response.ok === false) {
        throw response;
      }
      const s = this.props.submission.data;
      s.gradeApproval = await response.json();
      s.status = 'Done';
      this.props.patchSubmissionData(s);
      this.props.invalidateQueue();
      this.setState({ gradeApproved: true, error: null });
    } catch (e) {
      this.setErrorAfterEx(e);
    }
  };

  getPlatformName(platformCode) {
    if (platformCode === SubmissionDetail.BCS_CODE) {
      return 'Bootcamp Spot';
    }
    if (platformCode === SubmissionDetail.CANVAS_CODE) {
      return 'Canvas';
    }
    return '';
  }

  isClaimer(claimedByAccount) {
    if (claimedByAccount) {
      const { data = {} } = this.props.user;
      const { userAccount = {} } = data;
      return userAccount.id === claimedByAccount.id;
    }
    return false;
  }

  commentHistory() {
    const { relatedSubmissions = [] } = this.props.submission.data;

    let relatedSubmissionsCommentObjList = [];
    let submissionAttempt = 1;
    let previousSubmissionAttempt = 0;
    for (let i = relatedSubmissions.length - 1; i >= 0; i--) {
      const curRelatedSubmission = relatedSubmissions[i].data;
      curRelatedSubmission.sort((a, b) => b.ordinal - a.ordinal);
      const curCommentObjList = curRelatedSubmission.filter(
        child => child.key === 'Comment'
      );
      const studentComment = curRelatedSubmission.find(
        child => child.key === 'StudentComment'
      )?.content;

      let commentsCount = 0;
      let previousCommentDate = '';
      for (let j = curCommentObjList.length - 1; j >= 0; j--) {
        let curCommentObj = curCommentObjList[j];
        let curCommentDate = curCommentObj.children.find(
          child => (child.key = 'Posted')
        ).content;
        const exist = relatedSubmissionsCommentObjList.find(
          comment =>
            comment.content === curCommentObj.content &&
            comment.children.find(child => (child.key = 'Posted')).content ===
              curCommentDate
        );
        if (!exist) {
          if (previousSubmissionAttempt !== submissionAttempt) {
            curCommentObj.studentComment = studentComment;
            curCommentObj.studentName = relatedSubmissions[i].studentName;
            curCommentObj.studentCommentDate = relatedSubmissions[i].date;
            curCommentObj.submissionAttempt = submissionAttempt;
            curCommentObj.duplicatedComment = false;
            previousSubmissionAttempt = submissionAttempt;
          }
          curCommentObj.postedOnSubmissionId = relatedSubmissions[i].id;
          relatedSubmissionsCommentObjList.push(curCommentObj);
          commentsCount++;
        }
        previousCommentDate = curCommentDate;
      }
      if (commentsCount === 0) {
        let curCommentDate;
        let curCommentObj;
        if (curCommentObjList.length > 0) {
          curCommentObj = curCommentObjList[0];
        } else {
          curCommentObj =
            curRelatedSubmission.find(
              child => child.key === 'StudentComment'
            ) ?? {};
          curCommentDate = relatedSubmissions[i].date;
        }
        if (curCommentDate === previousCommentDate) {
          curCommentObj.duplicatedComment = true;
        } else {
          curCommentObj.studentComment = studentComment;
          curCommentObj.studentName = relatedSubmissions[i].studentName;
          curCommentObj.studentCommentDate = relatedSubmissions[i].date;
          curCommentObj.submissionAttempt = submissionAttempt;
          previousSubmissionAttempt = submissionAttempt;
          previousCommentDate = curCommentDate;
          relatedSubmissionsCommentObjList.push(curCommentObj);
        }
      }
      submissionAttempt++;
    }
    return relatedSubmissionsCommentObjList;
  }

  render() {
    if (this.state.redirectTo) return <Redirect to={this.state.redirectTo} />;

    const styles = {
      requiredStyle: {
        color: '#C3392B',
        fontFamily: 'Lato',
        fontSize: '11px',
        fontStyle: 'Italic',
      },
    };
    const { claim, user } = this.props;
    const { pathname, search } = this.props.query;
    const userRoleName = this.props.user?.data?.role?.name;
    const {
      id: submissionId,
      availableGrades,
      status = '',
      studentName,
      plagiarizedRelatedSubmissions,
      potentialPlagiarism,
      assignmentName,
      assignmentDueDate,
      externalDate: submissionDate,
      program = {},
      course = {},
      data = [],
      relatedSubmissions = [],
      grade = {},
      gradeApproval = {},
      comments = [],
      claimedByAccount,
      gradedByAccount,
      approvedByAccount,
      externalApp = {},
      url: submissionUrl,
    } = this.props.submission.data;

    const isClaimer = this.isClaimer(claimedByAccount);

    const gradeOptions = !!availableGrades
      ? availableGrades.map(ag => {
          const text = ag === 'I' ? 'Incomplete' : ag;
          return { key: ag, value: ag, text: text };
        })
      : [];
    const studentComments = data
      .filter(({ key }) => key === 'StudentComment')
      .map(({ content }) => content);

    const commentHistory = this.commentHistory();

    let latestSubmissionCommentList = [];
    const latestSubmissionAttempt = relatedSubmissions.length + 1;
    let latestComment = { content: '', date: '', author: '' };
    data.sort((a, b) => b.ordinal - a.ordinal);
    const commentList = data.filter(child => child.key === 'Comment');
    if (commentList.length > 0) {
      for (let i = 0; i < commentList.length; i++) {
        let latestGraderComment = { content: '', date: '', author: '' };
        const dataItem = commentList[i];
        let curCommentDate = dataItem.children.find(
          child => (child.key = 'Posted')
        ).content;
        const existInRelatedSubmissions = commentHistory.find(
          comment =>
            comment.content === dataItem.content &&
            comment.studentCommentDate === curCommentDate
        );
        const existInLatestSubmission = latestSubmissionCommentList.find(
          comment =>
            comment.content === dataItem.content &&
            comment.studentCommentDate === curCommentDate
        );
        if (!existInRelatedSubmissions && !existInLatestSubmission) {
          latestGraderComment.author = dataItem.children.find(
            c => c.key === 'Author'
          ).content;
          latestGraderComment.content = dataItem.content;
          latestGraderComment.date = moment(curCommentDate).format(
            'MM/DD/YYYY [at] HH:mm:ss A'
          );
          latestGraderComment.submissionAttempt = latestSubmissionAttempt;
          latestSubmissionCommentList.push(latestGraderComment);
        }
      }
    } else {
      const studentCommentObj = data.filter(
        child => child.key === 'StudentComment'
      );

      latestComment.author = studentName;
      latestComment.content = studentCommentObj.content;
      latestComment.date = moment(submissionDate).format(
        'MM/DD/YYYY [at] HH:mm:ss A'
      );
      latestComment.submissionAttempt = latestSubmissionAttempt;
      latestComment.graderComment = gradeApproval?.comment;
      latestComment.graderCommentDate = gradeApproval?.date;
      latestComment.gradeAuthor = gradedByAccount?.userName;
      latestSubmissionCommentList.push(latestComment);
    }

    const relatedSubmissionsCommentHistory = commentHistory.map(
      curCommentObj => {
        const commentDate = curCommentObj.children
          ? curCommentObj.children.find(child => child.key === 'Posted').content
          : null;
        const commentAuthor = curCommentObj.children
          ? curCommentObj.children.find(child => child.key === 'Author').content
          : null;
        return (
          <React.Fragment>
            {curCommentObj.submissionAttempt != null && (
              <span>Submission Attempt {curCommentObj.submissionAttempt}</span>
            )}
            {curCommentObj.studentComment != null && (
              <Segment>
                <p>
                  <strong>{curCommentObj.studentName}&nbsp;&nbsp;</strong>
                  <span style={{ color: '#777' }}>
                    {moment(curCommentObj.studentCommentDate).format(
                      'MM/DD/YYYY [at] HH:mm:ss A'
                    )}
                  </span>
                </p>
                {curCommentObj.studentComment}
              </Segment>
            )}
            {!curCommentObj.duplicatedComment &&
              !!commentDate &&
              !!commentAuthor && (
                <Segment>
                  <p>
                    <strong>{commentAuthor}&nbsp;&nbsp;</strong>
                    <span style={{ color: '#777' }}>
                      {moment(commentDate).format('MM/DD/YYYY [at] HH:mm:ss A')}
                    </span>
                  </p>
                  {curCommentObj.content}
                </Segment>
              )}
          </React.Fragment>
        );
      }
    );

    const lastSubmissionComments = latestSubmissionCommentList.map(
      curLatestSubmissionCommentObj => {
        if (!curLatestSubmissionCommentObj.content) return null;
        return (
          <Segment>
            <p>
              <strong>
                {curLatestSubmissionCommentObj.author}&nbsp;&nbsp;
              </strong>
              <span style={{ color: '#777' }}>
                {curLatestSubmissionCommentObj.date}
              </span>
            </p>
            {curLatestSubmissionCommentObj.content}
          </Segment>
        );
      }
    );

    const isStaffMember = [
      'Central Grader',
      'Classroom TA',
      'Instructor',
      'Manager of Central Graders',
    ].includes(userRoleName);
    const { content: comment } = comments[0] || {};
    const canBeGraded = ['in progress', 'ready for review', 'flagged'].includes(
      status.toLowerCase()
    );
    const isReadyForReview = ['ready for review'].includes(
      status.toLowerCase()
    );
    const hasBeenGraded =
      ['done'].includes(status.toLowerCase()) ||
      Object.values(gradeApproval).length > 0;

    const {
      isLateSubmission,
      isResubmission,
      submissionDateMessage,
      submissionHistory,
    } = this.checkResubmissions();

    const platformName = this.getPlatformName(externalApp.name);
    const isBcs = externalApp.name === SubmissionDetail.BCS_CODE;
    const isCanvas = externalApp.name === SubmissionDetail.CANVAS_CODE;
    return (
      <div key={this.props.submission.receivedAt}>
        <Container style={{ marginTop: '7em', width: '70%' }}>
          <Button
            as={Link}
            className={'buttonIcon left'}
            to={{ pathname: pathname || '/queue', search }}>
            <span className={'buttonIconImage'}>
              <i className="left arrow icon" />
            </span>
            <span className={'buttonIconContent'}>Back to Queue</span>
          </Button>
          <Segment style={{ border: '1px solid #ddd' }}>
            <Grid style={{ padding: '0em' }}>
              <div style={{ padding: '0em' }}>
                <Grid.Row
                  computer={16}
                  mobile={16}
                  tablet={16}
                  style={{ padding: '2em', clear: 'both' }}>
                  <Grid.Column
                    computer={4}
                    mobile={16}
                    tablet={16}
                    style={{ width: '70%', float: 'left' }}>
                    <Header>{assignmentName}</Header>
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    mobile={16}
                    tablet={16}
                    style={{ float: 'right', marginTop: '-0.5em' }}>
                    {status === 'New' &&
                      ['Central Grader', 'Manager of Central Graders'].includes(
                        userRoleName
                      ) && (
                        <Button
                          positive
                          basic
                          onClick={() => {
                            claim({
                              role: user?.data?.role,
                              submissionId: Number(
                                this.props.match.params.submissionId
                              ),
                            });
                          }}>
                          Claim
                        </Button>
                      )}
                    {status === 'In Progress' &&
                      ['Manager of Central Graders'].includes(userRoleName) && (
                        <Button
                          positive
                          basic
                          onClick={() => {
                            this.props.removeClaim({
                              submissionId: Number(
                                this.props.match.params.submissionId
                              ),
                            });
                          }}>
                          Release
                        </Button>
                      )}
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    mobile={16}
                    tablet={16}
                    style={{ float: 'right', marginRight: '15px' }}>
                    {moment().diff(submissionDate, 'hours') >= 72 &&
                      ['New', 'In Progress', 'Ready for Review'].includes(
                        status
                      ) && (
                        <p
                          className="assignmentDetail"
                          style={{ color: '#C3392B' }}>
                          <strong>Over Target Turnaround</strong>
                        </p>
                      )}
                  </Grid.Column>
                </Grid.Row>
                {status !== 'New' &&
                  (!!claimedByAccount ||
                    !!gradedByAccount ||
                    !!approvedByAccount) && (
                    <Grid.Row
                      computer={16}
                      mobile={16}
                      tablet={16}
                      style={{ padding: '2em', clear: 'both' }}>
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{ width: '33.33%', float: 'left' }}>
                        {!!claimedByAccount && !!claimedByAccount && (
                          <span>
                            <strong>Claimed by:</strong>{' '}
                            {`${claimedByAccount.firstName} ${claimedByAccount.lastName}`}
                          </span>
                        )}
                      </Grid.Column>
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{ width: '33.33%', float: 'left' }}>
                        {!!gradedByAccount && (
                          <span>
                            <strong>Graded by:</strong>{' '}
                            {`${gradedByAccount.firstName} ${gradedByAccount.lastName}`}
                          </span>
                        )}
                      </Grid.Column>
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{ width: '33.33%', float: 'left' }}>
                        {!!approvedByAccount && (
                          <span>
                            <strong>Approved by:</strong>{' '}
                            {`${approvedByAccount.firstName} ${approvedByAccount.lastName}`}
                          </span>
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  )}
                <Grid.Row
                  computer={16}
                  mobile={16}
                  tablet={16}
                  style={{
                    padding: '2em',
                    clear: 'both',
                    marginBottom: '9em',
                  }}>
                  <div>
                    <Grid.Column
                      computer={4}
                      mobile={16}
                      tablet={16}
                      style={{ width: '33.33%', float: 'left' }}>
                      <p className="assignmentDetail">
                        <strong>Status:</strong> {status}
                      </p>

                      {!isLateSubmission && !isResubmission && (
                        <p className="assignmentDetail">
                          <strong>Date Submitted:</strong>{' '}
                          {moment(submissionDate).format('MM/DD/YYYY')}
                        </p>
                      )}

                      {isLateSubmission && (
                        <p style={{ color: '#C3392B', marginBottom: '0px' }}>
                          <Icon name="exclamation circle" />
                          <strong>Date Submitted:</strong>{' '}
                          {moment(submissionDate).format('MM/DD/YYYY')}
                        </p>
                      )}

                      {isResubmission && (
                        <p style={{ color: '#C3392B', marginBottom: '0px' }}>
                          <Icon name="exclamation circle" />
                          <strong>Date Submitted:</strong>{' '}
                          {moment(submissionDate).format('MM/DD/YYYY')}
                          <Dropdown
                            icon={'angle down'}
                            style={{ color: 'black' }}>
                            <Dropdown.Menu>
                              {submissionHistory.map(x => [
                                <Dropdown.Item
                                  style={{
                                    minWidth: '300px',
                                    minHeight: '54px',
                                  }}>
                                  <span
                                    style={{ float: 'right', lineHeight: '2' }}>
                                    <b
                                      style={{
                                        background: '#999999',
                                        color: 'white',
                                        paddingTop: '3px',
                                        paddingBottom: '3px',
                                        paddingLeft: '10px',
                                        paddingRight: '10px',
                                        borderRadius: '5px',
                                      }}>
                                      {x.formattedDate}
                                    </b>
                                  </span>
                                  <b style={{ float: 'left', lineHeight: '2' }}>
                                    {x.text}
                                  </b>
                                </Dropdown.Item>,
                                <Dropdown.Divider style={{ margin: '0px' }} />,
                              ])}
                            </Dropdown.Menu>
                          </Dropdown>
                        </p>
                      )}

                      {submissionDateMessage && (
                        <p
                          style={{
                            color: '#C3392B',
                            fontStyle: 'italic',
                            fontSize: '13px',
                          }}>
                          {submissionDateMessage}
                        </p>
                      )}

                      <p className="assignmentDetail">
                        <strong>Due Date:</strong>{' '}
                        {moment(assignmentDueDate).format('MM/DD/YYYY')}
                      </p>
                      <p className="assignmentDetail">
                        <strong>Platform:</strong> {platformName}
                      </p>
                    </Grid.Column>
                    <Grid.Column
                      computer={4}
                      mobile={16}
                      tablet={16}
                      style={{ width: '33.33%', float: 'left' }}>
                      <p className="assignmentDetail">
                        <strong>Student:</strong> {studentName}
                      </p>
                      <p className="assignmentDetail">
                        <strong>Class:</strong> {course.name}
                      </p>
                      <p className="assignmentDetail">
                        <strong>Program:</strong> {program.name}
                      </p>
                    </Grid.Column>
                    {isBcs && (
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{ width: '33.33%', float: 'left' }}>
                        <p
                          style={{
                            fontSize: '20px',
                            width: '100%',
                            border: 'solid',
                            borderWidth: '1px',
                            borderColor: '#e0e1e2',
                            borderRadius: '5px 5px 0px 0px',
                            padding: '5px',
                            marginBottom: '0px',
                            textAlign: 'center',
                            ...(plagiarizedRelatedSubmissions
                              ? { color: '#DC762C' }
                              : {}),
                          }}
                          className="assignmentDetail">
                          <strong>
                            {plagiarizedRelatedSubmissions
                              ? 'Flagged Previously for Suspected Plagiarism'
                              : 'Submitted Links'}
                          </strong>
                        </p>
                        {data
                          .filter(({ key }) => key === 'SubmissionUrl')
                          .map((d, i, a) =>
                            this.renderSubmissionUrlButton(d, i, a)
                          )}
                      </Grid.Column>
                    )}
                    {isCanvas && submissionUrl && isClaimer && (
                      <Grid.Column
                        computer={4}
                        mobile={16}
                        tablet={16}
                        style={{ width: '33.33%', float: 'left' }}>
                        {this.renderCanvasUrlButton(submissionUrl)}
                      </Grid.Column>
                    )}
                  </div>
                </Grid.Row>

                {isBcs && (
                  <Grid.Row
                    computer={16}
                    mobile={16}
                    tablet={16}
                    style={{
                      padding: '2em',
                      marginTop: '9em',
                      borderTop: '1px solid #ddd',
                    }}>
                    {studentComments.length > 0 && (
                      <React.Fragment>
                        <p
                          className="assignmentDetail"
                          style={{ color: '#777' }}>
                          <strong>Notes from the Student:</strong>
                        </p>
                        {studentComments.map((content, index, array) => (
                          <p
                            key={index}
                            style={
                              index === array.length - 1
                                ? { color: '#777', marginBottom: '30px' }
                                : { color: '#777' }
                            }>
                            {content || 'No comment'}
                          </p>
                        ))}
                      </React.Fragment>
                    )}
                    {relatedSubmissionsCommentHistory.length > 0 && (
                      <React.Fragment>
                        <p>
                          <p style={{ color: '#333' }}>
                            <strong>Comments</strong>
                          </p>
                          {relatedSubmissionsCommentHistory}
                        </p>
                      </React.Fragment>
                    )}
                    {lastSubmissionComments.length > 0 && (
                      <React.Fragment>
                        <p>
                          <span>
                            Submission Attempt {latestSubmissionAttempt}
                          </span>
                        </p>
                        <p>{lastSubmissionComments}</p>
                        {latestComment.graderComment != null && (
                          <p>
                            <Segment>
                              <p>
                                <strong>
                                  {latestComment.gradeAuthor}&nbsp;&nbsp;
                                </strong>
                                <span style={{ color: '#777' }}>
                                  {latestComment.graderCommentDate}
                                </span>
                              </p>
                              {latestComment.graderComment}
                            </Segment>
                          </p>
                        )}
                      </React.Fragment>
                    )}
                    {(canBeGraded || hasBeenGraded) && (
                      <React.Fragment>
                        {!hasBeenGraded && (
                          <Form>
                            <Header size="small">
                              {this.state.invalidCommentField ? (
                                <Icon
                                  name="exclamation circle"
                                  size="tiny"
                                  color="red"
                                  style={{
                                    float: 'left',
                                    fontSize: '11px',
                                    marginTop: '0.5em',
                                  }}
                                />
                              ) : null}
                              Comments for Student{' '}
                              <span style={styles.requiredStyle}>Required</span>
                            </Header>
                            <Form.Field
                              disabled={
                                !canBeGraded || !IsRoleStaff(userRoleName)
                              }
                              control={TextArea}
                              placeholder="Comments"
                              value={this.state.comment || comment}
                              onChange={(e, { value: comment }) => {
                                this.setState({
                                  comment,
                                  invalidCommentField: false,
                                  gradeSubmitted: false,
                                  success: null,
                                  error: null,
                                });
                              }}
                            />
                            {this.state.invalidCommentField ? (
                              <span style={styles.requiredStyle}>
                                Please enter a comment for the Student
                              </span>
                            ) : null}
                          </Form>
                        )}
                        <Grid style={{ marginTop: '15px' }}>
                          <Grid.Column computer={4} mobile={16} tablet={16}>
                            <Header size="small">
                              {this.state.invalidGradeField ? (
                                <Icon
                                  name="exclamation circle"
                                  size="tiny"
                                  color="red"
                                  style={{
                                    float: 'left',
                                    fontSize: '11px',
                                    marginTop: '0.5em',
                                  }}
                                />
                              ) : null}
                              Grade{' '}
                              <span style={styles.requiredStyle}>Required</span>
                            </Header>
                            <Dropdown
                              disabled={
                                !canBeGraded || !IsRoleStaff(userRoleName)
                              }
                              style={{ width: 200, display: 'block' }}
                              placeholder="Grades"
                              fluid
                              selection
                              value={
                                this.state.grade ||
                                gradeApproval.finalGrade ||
                                grade.grade
                              }
                              options={gradeOptions}
                              onChange={(e, { value }) => {
                                this.setState({
                                  grade: value,
                                  invalidGradeField: false,
                                  gradeSubmitted: false,
                                  success: null,
                                  error: null,
                                });
                              }}
                            />
                            {this.state.invalidGradeField ? (
                              <span style={styles.requiredStyle}>
                                Please select a grade from the dropdown
                              </span>
                            ) : null}
                          </Grid.Column>
                          <Grid.Column computer={8} mobile={16} tablet={16}>
                            <Checkbox
                              disabled={
                                !canBeGraded || !IsRoleStaff(userRoleName)
                              }
                              toggle
                              style={{ marginLeft: '1em', marginTop: '3em' }}
                              label="Plagiarism Suspected"
                              checked={
                                this.state.potentialPlagiarism !== undefined
                                  ? this.state.potentialPlagiarism
                                  : potentialPlagiarism
                              }
                              onChange={() => {
                                this.setState(prevState => ({
                                  potentialPlagiarism: !prevState.potentialPlagiarism,
                                }));
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column
                            computer={4}
                            mobile={16}
                            tablet={16}
                            verticalAlign="bottom">
                            {[
                              'Central Grader',
                              'Manager of Central Graders',
                            ].includes(userRoleName) &&
                              !hasBeenGraded && (
                                <Modal
                                  open={this.state.openModal}
                                  trigger={
                                    <Button
                                      color="teal"
                                      disabled={
                                        this.state.error || this.state.success
                                      }
                                      className={'buttonIcon right'}
                                      floated="right"
                                      onClick={this.openSubmissionConfirmation}>
                                      <span className={'buttonIconContent'}>
                                        {isReadyForReview
                                          ? 'Update "Ready for review"'
                                          : 'Send to TA'}
                                      </span>
                                      <span className={'buttonIconImage'}>
                                        <i className="right arrow icon" />
                                      </span>
                                    </Button>
                                  }>
                                  <Modal.Content style={{ overflow: 'auto' }}>
                                    <Header>
                                      {`Are you sure you want to send this
                                    submission for ${studentName} to the class TA?`}
                                    </Header>
                                    <p>
                                      Once you click “Send to TA” you will not
                                      be able to make any modifications to the
                                      grade or notes for this submission. The
                                      submission will be sent to the TA for
                                      review.
                                    </p>
                                    <div style={{ float: 'right' }}>
                                      <Button
                                        onClick={() =>
                                          this.setState({ openModal: false })
                                        }>
                                        Cancel
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          this.submitGrade(
                                            submissionId,
                                            gradeApproval,
                                            grade,
                                            comment,
                                            potentialPlagiarism
                                          )
                                        }
                                        color="teal">
                                        Send to TA
                                      </Button>
                                    </div>
                                  </Modal.Content>
                                </Modal>
                              )}
                            {['Classroom TA', 'Instructor'].includes(
                              userRoleName
                            ) &&
                              ['Ready for Review', 'Flagged'].includes(
                                status
                              ) && (
                                <Button
                                  fluid
                                  icon="checkmark"
                                  labelPosition="right"
                                  content="Approve Grade"
                                  color="teal"
                                  onClick={() => {
                                    this.approveGrade({
                                      submissionId,
                                      finalGrade:
                                        this.state.grade || grade.grade,
                                      comment: this.state.comment || comment,
                                      potentialPlagiarism:
                                        this.state.potentialPlagiarism !==
                                        undefined
                                          ? this.state.potentialPlagiarism
                                          : potentialPlagiarism,
                                    });
                                    this.setState({
                                      comment: undefined,
                                      grade: undefined,
                                      potentialPlagiarism: undefined,
                                    });
                                  }}
                                />
                              )}
                          </Grid.Column>
                        </Grid>
                        {!!this.state.error && (
                          <Grid>
                            <Grid.Column computer={16} mobile={16} tablet={16}>
                              <Message error>{this.state.error}</Message>
                            </Grid.Column>
                          </Grid>
                        )}
                        {!!this.state.gradeSubmitted && (
                          <Grid>
                            <Grid.Column computer={16} mobile={16} tablet={16}>
                              <Message success>
                                The grade was submitted successfully. You can
                                edit it now if you want.
                              </Message>
                            </Grid.Column>
                          </Grid>
                        )}
                        {!!this.state.gradeApproved && (
                          <Grid>
                            <Grid.Column computer={16} mobile={16} tablet={16}>
                              <Message success>
                                The grade was approved successfully.
                              </Message>
                            </Grid.Column>
                          </Grid>
                        )}
                      </React.Fragment>
                    )}
                  </Grid.Row>
                )}
              </div>
            </Grid>
          </Segment>
          {hasBeenGraded &&
            isStaffMember &&
            (!!comments &&
            !!comments[0] &&
            gradeApproval.comment !== comments[0].content
              ? comments.map(({ user = {}, content = {} }) => (
                  <p
                    style={{
                      padding: '4em',
                    }}>
                    <div>
                      <Header as="h1">
                        <strong>Staff Comments</strong>
                        <Header.Subheader
                          style={{ color: '#D3CDCD', fontSize: '12px' }}>
                          These comments are only seen by other staff members.
                          Students do not see these comments.
                        </Header.Subheader>
                      </Header>
                    </div>
                    <Grid
                      style={{
                        padding: '1em',
                        paddingTop: '3em',
                      }}>
                      <div>
                        <strong>{user.firstName}</strong>&nbsp;&nbsp;{' '}
                        <span style={{ color: '#777' }}>
                          {moment(comments[0].date).format(
                            'MM/DD/YYYY [at] HH:mm:ss A'
                          )}
                        </span>
                        <p style={{ color: '#333' }}>{content}</p>
                        <strong>
                          {!!approvedByAccount
                            ? approvedByAccount.userName
                            : null}
                        </strong>
                        &nbsp;&nbsp;{' '}
                        <span style={{ color: '#777' }}>
                          {!!gradeApproval
                            ? moment(gradeApproval.date).format(
                                'MM/DD/YYYY [at] HH:mm:ss A'
                              )
                            : null}
                        </span>
                        <p style={{ color: '#333' }}>
                          {!!gradeApproval ? gradeApproval.comment : null}
                        </p>
                      </div>
                    </Grid>
                  </p>
                ))
              : null)}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { filters, query, submission = {}, user } = state;

      return {
        filters,
        query,
        submission,
        user,
      };
    },
    (dispatch, { data, ...props }) => ({
      loadSubmissionDetail: async submissionId => {
        dispatch(
          updatedQuery({
            submissionId: Number.parseInt(submissionId, 10),
          })
        );
        dispatch(fetchDataIfNeeded('submission'));
      },
      claim: async payload => {
        await postData('/api/centralgrading/v1/claimSubmission', payload);
        dispatch(invalidateStore('submission'));
        dispatch(fetchDataIfNeeded('submission'));
        dispatch(invalidateStore('queue'));
      },
      removeClaim: async payload => {
        await postData('/api/centralgrading/v1/removeClaim', payload);
        dispatch(invalidateStore('submission'));
        dispatch(fetchDataIfNeeded('submission'));
        dispatch(invalidateStore('queue'));
      },
      patchSubmissionData: payload => {
        dispatch(patchData('submission', payload));
      },
      comment: async payload => {
        await postData('/api/centralgrading/v1/commentSubmission', payload);
        dispatch(invalidateStore('submission'));
        dispatch(fetchDataIfNeeded('submission'));
      },
      invalidateQueue: () => {
        dispatch(invalidateStore('queue'));
      },
    })
  )(SubmissionDetail)
);
