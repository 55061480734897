import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react';
import React, { useEffect } from 'react';
import { func, object } from 'prop-types';

const RoleSelector = ({
  history: { push },
  setUserRole,
  user: {
    data: { role, roles = [] },
  },
}) => {
  useEffect(() => {
    if (!isEmpty(role)) {
      push('/queue');
    }
  }, [push, role]);

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 800 }}>
        <Header as="h2" style={{ color: '#333' }} textAlign="center">
          Centralized Grading
        </Header>
        <Form size="large">
          <Segment stacked>
            <Header
              as="h3"
              style={{ color: '#00000099', fontSize: '1rem' }}
              textAlign="center">
              Please select your role
            </Header>
            <Grid stackable columns={roles.length}>
              {roles.map(({ id, name }) => (
                <Grid.Column stretched key={id}>
                  <Button onClick={() => setUserRole(id)}>{name}</Button>
                </Grid.Column>
              ))}
            </Grid>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

RoleSelector.propTypes = {
  history: object,
  setUserRole: func,
  user: object,
};

export default withRouter(
  connect(
    ({ user }) => ({
      user,
    }),
    dispatch => ({
      setUserRole: payload => {
        dispatch({ payload, type: 'USER_SET_ROLE' });
      },
    })
  )(RoleSelector)
);
