import React from 'react';
import PropTypes from 'prop-types';
import '../../assets/css/alert.css';

const Alert = props => (
  <div
    className={`context-${props.type} margin-b-3`}
    role="alert"
    data-component="alert">
    {props.children}
  </div>
);

Alert.propTypes = {
  type: PropTypes.oneOf(['error', 'success', 'warn']),
};

export default Alert;
