const STATE = {
  isFetching: false,
  didInvalidate: true,
  receivedAt: 0,
  data: [],
};

const userList = (state = STATE, action) => {
  switch (action.type) {
    case 'UPDATED_QUERY':
      if (Array.isArray(action.payload)) {
        return {
          ...STATE,
          didInvalidate: true,
        };
      }
      return {
        ...state,
      };
    case 'INVALIDATE_USERLIST':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        receivedAt: 0,
        data: [],
      };
    case 'REQUEST_USERLIST':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_USERLIST':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        ...action.payload,
      };
    case 'RECEIVE_ERROR':
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default userList;
