const STATE = {
  isFetching: false,
  didInvalidate: false,
  receivedAt: 0,
  students: [],
  studentCount: 0,
  searchString: null,
};

const searchStudents = (state = STATE, action) => {
  switch (action.type) {
    case 'RECEIVE_ERROR':
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
    case 'INVALIDATE_SEARCHSTUDENTS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        receivedAt: 0,
        data: [],
      };
    case 'REQUEST_SEARCHSTUDENTS':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_SEARCHSTUDENTS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        ...action.payload.data,
      };
  }
};

export default searchStudents;
