const STATE = {
  errorBoundaryActive: false,
  message: '',
};

const errors = (state = STATE, action) => {
  switch (action.type) {
    case 'SET_ERRORBOUNDARY':
      return {
        ...state,
        errorBoundaryActive: true,
        message: action.payload.message,
      };
    case 'CLEAR_ERRORBOUNDARY':
      return {
        ...state,
        errorBoundaryActive: false,
        message: '',
      };
    default:
      return state;
  }
};

export default errors;
