import React, { Component } from 'react';
import { DayPickerSingleDateController } from 'react-dates';
import { Button, Popup } from 'semantic-ui-react';
import moment from 'moment';

export class QueueDateFilter extends Component {
  state = {
    isOpen: false,
  };

  render() {
    const { onChange, value, label, isOutsideRange } = this.props;

    return (
      <Button.Group basic fluid>
        {moment.isMoment(value) && (
          <Button
            basic
            icon="remove"
            onClick={() => {
              onChange(undefined);
            }}
          />
        )}
        <Popup
          trigger={
            <Button
              basic
              {...(moment.isMoment(value)
                ? {
                    labelPosition: 'right',
                    content: value.format('MM/DD/YYYY'),
                  }
                : {
                    labelPosition: 'right',
                    content: label,
                  })}
              icon="calendar plus outline"
              onClick={() => {
                this.setState({ isOpen: !this.state.isOpen });
              }}
            />
          }
          basic
          wide
          flowing
          hoverable
          on="click"
          open={this.state.isOpen}
          onClose={() => {
            this.setState({ isOpen: false });
          }}
          onOpen={() => {
            this.setState({ isOpen: true });
          }}
          position="bottom center">
          <DayPickerSingleDateController
            date={value}
            initialVisibleMonth={() => value || moment()}
            firstDayOfWeek={1}
            numberOfMonths={1}
            isOutsideRange={isOutsideRange}
            onDateChange={onChange}
            onClose={() => {
              this.setState({ isOpen: false });
            }}
          />
        </Popup>
      </Button.Group>
    );
  }
}

export default QueueDateFilter;
