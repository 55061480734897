const STATE = {
  isFetching: false,
  didInvalidate: false,
  receivedAt: 0,
  data: [],
};

const students = (state = STATE, action) => {
  switch (action.type) {
    case 'UPDATED_QUERY':
      // when action is sent from filter, it is formatted with array
      // when action is sent from navigation, it is formatted with string
      // TODO: find a better discriminator
      if (Array.isArray(action.payload.courses)) {
        return {
          ...STATE,
          didInvalidate: true,
        };
      }
      return {
        ...state,
      };
    case 'INVALIDATE_STUDENTS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: true,
        receivedAt: 0,
        data: [],
      };
    case 'REQUEST_STUDENTS':
      return {
        ...state,
        isFetching: true,
      };
    case 'RECEIVE_STUDENTS':
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        ...action.payload,
      };
    case 'UPDATE_STUDENTS':
      return {
        ...state,
        data: action.payload,
      };
    case 'RECEIVE_ERROR':
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};

export default students;
