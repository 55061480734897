import { Link } from 'react-router-dom';
import ScrollToTop from '../helpers/ScrollToTop';
import QueueFilters from '../QueueFilters';
import QueueItem from '../QueueItem';
import QueuePagination from '../QueuePagination';
import { useLocation } from 'react-router-dom';
import {
  Grid,
  Segment,
  Item,
  Container,
  Tab,
  Loader,
  Dimmer,
  Message,
  Icon,
  Modal,
  Button,
} from 'semantic-ui-react';
import React, { useEffect, useRef, useState } from 'react';
import {
  fetchDataIfNeeded,
  invalidateStore,
  postData,
} from '../../actions/fetchData';
import { useDispatch, useSelector } from '../../hooks';

const spanStyle = {
  width: '1.5rem',
  height: '1.5rem',
  textAlign: 'center',
  borderRadius: '50%',
  backgroundColor: 'white',
  lineHeight: '1.45rem',
  position: 'absolute',
  left: '3rem',
  top: '1rem',
};

const buttonResetStyle = {
  backgroundColor: 'transparent',
  borderBottomWidth: 0,
  borderLeftWidth: 0,
  borderRightWidth: 0,
  borderTopWidth: 0,
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  textDecoration: 'underline',
  cursor: 'pointer',
  color: '#9f3a38',
};

const errorMessageStyle = {
  color: '#DB2828',
  backgroundColor: '#FFE8E6',
};

const ACTIVE = 0;
const PAST_DUE = 1;
const WAITING_FOR_REVIEW = 2;

const Queue = () => {
  const contextRef = useRef();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    data: { userAccount: { firstName = '' } = {} } = {},
    error: { statusCode } = {},
  } = useSelector('user');
  const {
    data: { data: queue = [], pastDueClaimCount } = {},
    ...status
  } = useSelector('queue');

  useEffect(() => {
    if (pastDueClaimCount) setActiveIndex(PAST_DUE);
  }, [pastDueClaimCount]);

  const [activeIndex, setActiveIndex] = useState(ACTIVE);
  const [successVisible, setSuccessVisible] = useState(true);

  const handleDismiss = () => setSuccessVisible(false);

  const removeAllInProgressClaims = async () => {
    await postData('/api/centralgrading/v1/removeAllInProgressClaims');
    dispatch(invalidateStore('queue'));
    dispatch(fetchDataIfNeeded('queue'));
  };

  const isClaimedByMe = pathname === '/claimed';

  const renderClaimedByMeSubmissions = () => {
    let filteredQueue;
    switch (activeIndex) {
      case ACTIVE:
        filteredQueue = queue.filter(
          q =>
            !q.archivedClaim &&
            !['Flagged', 'Ready for Review'].includes(q.status)
        );
        break;
      case PAST_DUE:
        filteredQueue = queue.filter(
          q =>
            !q.archivedClaim &&
            q.claimStatus === 'Claim Past Due' &&
            !['Flagged', 'Ready for Review'].includes(q.status)
        );
        break;
      case WAITING_FOR_REVIEW:
        filteredQueue = queue.filter(q =>
          ['Flagged', 'Ready for Review'].includes(q.status)
        );
        break;
      default:
        filteredQueue = queue.filter(
          q => !q.archivedClaim && q.claimStatus !== 'Claim Past Due'
        );
        break;
    }

    return (
      <>
        {pastDueClaimCount === 0 && successVisible && (
          <Message icon positive onDismiss={() => handleDismiss()}>
            <Icon name="check circle" />
            <Message.Content>You have no overdue claims.</Message.Content>
          </Message>
        )}
        {pastDueClaimCount > 0 && activeIndex !== PAST_DUE && (
          <Message icon negative style={errorMessageStyle}>
            <Icon name="bell" />
            <span style={spanStyle}>{pastDueClaimCount}</span>
            <Message.Content>
              You have ({pastDueClaimCount}) claimed submissions that are
              overdue.{' '}
              <button
                style={buttonResetStyle}
                onClick={() => setActiveIndex(PAST_DUE)}>
                See all
              </button>
              .
            </Message.Content>
          </Message>
        )}
        {pastDueClaimCount > 0 && activeIndex === PAST_DUE && (
          <Message icon negative style={errorMessageStyle}>
            <Icon name="bell" />
            <span style={spanStyle}>{pastDueClaimCount}</span>
            <Message.Content>
              You have ({pastDueClaimCount}) claimed submissions that are
              overdue. Please complete grading tasks or{' '}
              <button
                style={buttonResetStyle}
                onClick={() => setIsModalOpen(true)}>
                release claim(s)
              </button>
              .
              <Modal open={isModalOpen}>
                <Modal.Header>Action Required</Modal.Header>
                <Modal.Content>
                  <Modal.Description>
                    <p>
                      Are you sure you want to release ALL your claims in
                      progress?
                    </p>
                  </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                  <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
                  <Button
                    onClick={() => removeAllInProgressClaims()}
                    color="red">
                    Confirm
                  </Button>
                </Modal.Actions>
              </Modal>
            </Message.Content>
          </Message>
        )}
        {filteredQueue.map(item => (
          <QueueItem key={item.id} {...item} />
        ))}
      </>
    );
  };

  return (
    <div ref={contextRef} style={{ marginTop: '1em' }}>
      <Grid>
        <Grid.Column
          computer={4}
          mobile={16}
          tablet={16}
          style={{ marginTop: '1em' }}>
          <QueueFilters />
        </Grid.Column>
        <Grid.Column computer={12} mobile={16} tablet={16}>
          {isClaimedByMe && (
            <Tab
              activeIndex={activeIndex}
              menu={{ secondary: true }}
              onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
              panes={[
                { menuItem: 'Active' },
                { menuItem: 'Past Due' },
                { menuItem: 'Waiting for review' },
              ]}
            />
          )}
          <Segment
            style={{
              border: '1px solid #ddd',
              minHeight: '100px',
            }}>
            <Item.Group>
              {status.isFetching && (
                <Dimmer active inverted>
                  <Loader active content="Loading Queue.." />
                </Dimmer>
              )}
              {!status.isFetching && (
                <>
                  <ScrollToTop key={status.receivedAt} />

                  {queue.length === 0 && (
                    <Container textAlign="center">
                      {statusCode !== 500 ? (
                        <>
                          This queue is empty. Great job{' '}
                          {!!firstName ? firstName : ''}
                        </>
                      ) : (
                        <>
                          An error occurred.
                          <br />
                          We apologize for the inconvenience.
                          <br />
                          If this issue persists please logout and log back in
                          again.
                          <br />
                          If the issue still persists, please contact your
                          manager.
                          <br />
                        </>
                      )}
                    </Container>
                  )}

                  {pastDueClaimCount > 0 && !isClaimedByMe && (
                    <Message icon negative style={errorMessageStyle}>
                      <Icon name="bell" />
                      <span style={spanStyle}>{pastDueClaimCount}</span>
                      <Message.Content>
                        You have ({pastDueClaimCount}) claimed submissions that
                        are overdue.{' '}
                        <Link
                          to={'/claimed'}
                          style={{
                            color: '#9f3a38',
                            textDecoration: 'underline',
                          }}>
                          See all
                        </Link>
                        .
                      </Message.Content>
                    </Message>
                  )}
                  {isClaimedByMe
                    ? renderClaimedByMeSubmissions()
                    : queue.map(item => <QueueItem key={item.id} {...item} />)}
                </>
              )}
            </Item.Group>
          </Segment>
          <QueuePagination />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default Queue;
