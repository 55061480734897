import { Pagination } from 'semantic-ui-react';
import React from 'react';
import { updatedQuery } from 'actions/fetchData';
import { useDispatch, useSelector } from 'hooks';

const QueuePagination = () => {
  const dispatch = useDispatch();

  const {
    data: { totalResults },
  } = useSelector('queue');
  const { offset, pageSize } = useSelector('query');

  const filterQueue = payload => {
    dispatch(updatedQuery(payload));
  };

  if (!totalResults || totalResults <= pageSize) return null;

  return (
    <Pagination
      style={{ marginTop: '1em' }}
      activePage={Math.floor(offset / pageSize + 1)}
      totalPages={Math.ceil(totalResults / pageSize)}
      siblingRange={1}
      onPageChange={(e, { activePage }) => {
        const offset = (activePage - 1) * pageSize;
        filterQueue({
          offset,
        });
      }}
    />
  );
};

export default QueuePagination;
