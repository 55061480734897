import config from 'config';
import { datadogRum } from '@datadog/browser-rum';
import routes from './routes.config';
import DisabledMessage from './components/DisabledMessage';
import PageHeader from './components/PageHeader';
import PrivateRoute from './PrivateRoute';
import React from 'react';
import ScrollToTop from './components/helpers/ScrollToTop';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import ReduxError from './ReduxError';
const { platformDisabled } = config;

// send stats for PROD only
if (
  process.env.REACT_APP_DATADOG_APPLICATION_ID &&
  process.env.REACT_APP_DATADOG_CLIENT_TOKEN
) {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'CG',
    env: 'production',
    version: '1.0.0',
    sampleRate: 100,
    resourceSampleRate: 100,
    silentMultipleInit: true,
    trackInteractions: true,
  });
}

const App = () => (
  <Router>
    <ScrollToTop />
    <div>
      <Route path="/" component={PageHeader} />
      <ErrorBoundary>
        <ReduxError>
          <Switch>
            {
              platformDisabled &&
              <DisabledMessage />
            }
            {!platformDisabled && routes.map(({ isPrivate, ...route }, index) =>
              !!isPrivate ? (
                <PrivateRoute exact key={index} {...route} />
              ) : (
                <Route exact key={index} {...route} />
              )
            )}
          </Switch>
        </ReduxError>
      </ErrorBoundary>
    </div>
  </Router>
);

export default App;
