import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Header,
  Icon,
  Item,
  Message,
  List,
  Loader,
  Table,
  Segment,
} from 'semantic-ui-react';
import moment from 'moment';
import { postData } from '../../actions/fetchData';

const headers = [
  'Cohorts',
  'Graders',
  'Submissions Processed',
  'Potential Plagiarism',
  'Avg. Turnaround',
];

const humanizeSeconds = sec => {
  const parse = {
    days: Math.floor(sec / 86400),
    hours: Math.floor(sec / 3600) % 24,
    minutes: Math.floor(sec / 60) % 60,
    seconds: Math.floor(sec) % 60,
  };

  return Object.keys(parse)
    .reduce((result, key) => {
      if (parse[key] !== 0) return [...result, `${parse[key]}${key[0]}`];
      return result;
    }, [])
    .join(' ');
};

const MetricsRow = week => (
  <Table.Row>
    <Table.Cell>
      <Item>
        <Item.Content>
          <Item.Header as="h4">Week {week.week}</Item.Header>
          <Item.Description>
            {`${moment()
              .isoWeek(week.week)
              .startOf('isoweek')
              .format('MMM D')} - ${moment()
              .isoWeek(week.week)
              .endOf('isoweek')
              .format('MMM D')}`}
          </Item.Description>
        </Item.Content>
      </Item>
    </Table.Cell>
    <Table.Cell textAlign="center">{week.courses}</Table.Cell>
    <Table.Cell textAlign="center">{week.graders}</Table.Cell>
    <Table.Cell textAlign="center">{week.markedDone || 0}</Table.Cell>
    <Table.Cell textAlign="center">{week.markedPlagiarism || 0}</Table.Cell>
    <Table.Cell textAlign="center">
      {week.averageTurnaround ? humanizeSeconds(week.averageTurnaround) : 'N/A'}
    </Table.Cell>
  </Table.Row>
);

const WeeklyTrend = () => {
  const today = moment();
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [metrics, setMetrics] = useState();
  const [range, setRange] = useState({
    start: today
      .clone()
      .startOf('isoweek')
      .subtract(2, 'isoweek'),
    end: today.clone().endOf('isoweek'),
  });

  useEffect(() => {
    setLoading(true);
    postData('/api/centralgrading/v1/weeklyMetrics', {
      since: range.start.toISOString(true),
      until: range.end.toISOString(true),
    })
      .then(res => {
        if (res.status >= 400 && res.status < 500)
          return history.push('/login');
        if (res.status >= 500) throw Error('Server Error');
        return res.json();
      })
      .then(setMetrics)
      .catch(() => setError(true))
      .then(() => setLoading(false));
  }, [range, history]);

  const offsetRange = offset =>
    setRange({
      start: range.start.add(offset, 'week'),
      end: range.end.add(offset, 'week'),
    });

  if (loading)
    return (
      <Segment size="massive">
        <Loader active size="large" />
      </Segment>
    );

  if (error)
    return (
      <Segment size="large">
        <Message error>
          <Message.Header>Unable to fetch data</Message.Header>
        </Message>
      </Segment>
    );

  return (
    <Grid divided="vertically">
      <Grid.Row>
        <Grid.Column>
          <List horizontal>
            <List.Item>
              <Icon link name="arrow left" onClick={() => offsetRange(-3)} />
            </List.Item>
            <List.Item>
              <Header>
                {range.start.format('MMM Do')} -{range.end.format('MMM Do')}{' '}
                {range.end.year()}
              </Header>
            </List.Item>
            <List.Item>
              <Icon
                link={range.end.isoWeek() !== today.isoWeek()}
                disabled={range.end.isoWeek() === today.isoWeek()}
                onClick={() => offsetRange(3)}
                name="arrow right"
              />
            </List.Item>
          </List>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column>
          <Table compact celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell />
                {headers.map(label => (
                  <Table.HeaderCell textAlign="center">
                    {label}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {metrics.sort((a, b) => b.week - a.week).map(MetricsRow)}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

export default WeeklyTrend;
