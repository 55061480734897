import GoogleLoginButton from '../GoogleLoginButton';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Form, Grid, Header, Segment } from 'semantic-ui-react';
import React, { useEffect } from 'react';
import { fetchDataIfNeeded } from 'actions/fetchData';
import { useDispatch, useDocumentVisibility, useSelector } from 'hooks';

const Login = () => {
  const dispatch = useDispatch();
  const documentVisibility = useDocumentVisibility();
  const { push } = useHistory();
  const { data = {}, didInvalidate = false, loginError = false } = useSelector(
    'user'
  );

  useEffect(() => {
    const isLoggedIn = !isEmpty(data) && !loginError;
    if (isLoggedIn) {
      push('/queue');
    }
  }, [data, loginError, push]);

  useEffect(() => {
    if (documentVisibility !== 'visible' || didInvalidate) return;
    dispatch(fetchDataIfNeeded('user'));
  }, [didInvalidate, dispatch, documentVisibility]);

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Segment stacked>
          <Header as="h2" style={{ color: '#333' }} textAlign="center">
            Centralized Grading
          </Header>
          <Form size="large">
            <GoogleLoginButton />
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
};

Login.propTypes = {};

export default Login;
