import { withRouter } from 'react-router-dom';
import React, { Component, createRef } from 'react';
import { Button, Grid, Icon, Loader, Message, Table } from 'semantic-ui-react';
import '../../assets/css/assignment.css';
import moment from 'moment';
import { postData } from '../../actions/fetchData';

class TaMessages extends Component {
  contextRef = createRef();
  resultsPerPage = 20;

  state = {
    messageList: [],
    loading: false,
    error: null,
    success: null,
    offset: 0,
    isLastPage: false,
  };

  componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    this.setState({ loading: true, error: null, success: null });

    try {
      const response = await postData('/api/centralgrading/v1/listMessages', {
        offset: this.state.offset,
        resultsPerPage: this.resultsPerPage,
      });
      if (response.ok === false) {
        throw response;
      }
      const responseBody = await response.json();
      let isLastPage = responseBody.length < this.resultsPerPage;
      this.setState({
        success: true,
        loading: false,
        error: null,
        messageList: responseBody,
        isLastPage: isLastPage,
      });
    } catch (e) {
      console.warn(e);
      this.setState({
        error: 'There was an error trying to get the messages.',
        loading: false,
      });
    }
  };

  handlePrev = async () => {
    const offset =
      this.state.offset > this.resultsPerPage
        ? this.state.offset - this.resultsPerPage
        : 0;
    await this.setState({ offset });
    this.fetchMessages();
  };

  handleNext = async () => {
    let { offset } = this.state;
    await this.setState({ offset: offset + this.resultsPerPage });
    this.fetchMessages();
  };

  render() {
    return (
      <div
        style={{ marginTop: '1em', marginLeft: '5em', marginRight: '5em' }}
        ref={this.contextRef}>
        <Grid>
          <Grid.Column computer={16} mobile={16} tablet={16}>
            {this.state.messageList.length === 0 && !this.state.loading && (
              <Message>There are no messages.</Message>
            )}
            {this.state.messageList.length === 0 && this.state.loading && (
              <Loader style={{ marginTop: '5em' }} active={true} />
            )}
            {this.state.messageList.length > 0 && (
              <React.Fragment>
                <Table
                  fixed
                  striped
                  selectable
                  style={{ border: '1px solid #ddd', marginTop: '1em' }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                      <Table.HeaderCell width={7}>Message</Table.HeaderCell>
                      <Table.HeaderCell width={6} textAlign={'right'}>
                        Time
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {this.state.messageList.map(
                      ({ id, opened, from, shortMessage, date }) => {
                        let style = {};
                        let unReadIcon = <span style={{ padding: '0.55em' }} />;
                        if (!opened) {
                          style = { fontWeight: 'bold' };
                          unReadIcon = (
                            <Icon
                              name={'circle'}
                              size={'small'}
                              style={{ color: 'blue' }}
                            />
                          );
                        }

                        return (
                          <Table.Row
                            onClick={() => {
                              this.props.history.push(`/taMessage/${id}`);
                            }}
                            key={id}>
                            <Table.Cell style={style}>
                              {unReadIcon}
                              {from}
                            </Table.Cell>
                            <Table.Cell style={style}>
                              {shortMessage}
                            </Table.Cell>
                            <Table.Cell style={style} textAlign="right">
                              {moment(date).format('LT')}
                            </Table.Cell>
                          </Table.Row>
                        );
                      }
                    )}
                  </Table.Body>
                </Table>
                <div
                  className="row"
                  style={{
                    display: '-webkit-flex',
                    marginLeft: '100px',
                    marginRight: '100px',
                  }}>
                  <div
                    style={{
                      maxWidth: '50%',
                      flexBasis: '50%',
                      textAlign: 'left',
                    }}>
                    <Button
                      disabled={this.state.offset === 0}
                      loading={this.state.loading}
                      onClick={this.handlePrev}
                      style={{ backgroundColor: '#1B313A', color: 'white' }}>
                      Prev
                    </Button>
                  </div>
                  <div
                    style={{
                      maxWidth: '50%',
                      flexBasis: '50%',
                      textAlign: 'right',
                    }}>
                    <Button
                      onClick={this.handleNext}
                      loading={this.state.loading}
                      disabled={this.state.isLastPage}
                      style={{ backgroundColor: '#1B313A', color: 'white' }}>
                      Next
                    </Button>
                  </div>
                </div>
              </React.Fragment>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default withRouter(TaMessages);
