import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const ReduxError = ({ error, children }) => {
  const { errorBoundaryActive, message } = error;

  if (errorBoundaryActive) {
    throw new Error(message);
  }

  return children;
};

export default withRouter(
  connect(state => {
    const { error } = state;

    return {
      error,
    };
  }, undefined)(ReduxError)
);
