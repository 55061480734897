import React from 'react';
import { Header } from 'semantic-ui-react';

const Metric = ({ label, metric }) => (
  <React.Fragment>
    <p>{label}</p>
    <Header as="h3">{metric === '' ? 'N/A' : metric}</Header>
  </React.Fragment>
);

export default Metric;
