import { orderBy } from 'lodash';

const DEFAULT = {
  data: localStorage.getItem('userAccount')
    ? {
        role: JSON.parse(localStorage.getItem('role')) ?? {},
        roles: JSON.parse(localStorage.getItem('roles')) ?? [],
        userAccount: JSON.parse(localStorage.getItem('userAccount')) ?? {},
      }
    : {},
  didInvalidate: false,
  error: {},
  isFetching: false,
  receivedAt: 0,
  rolesError: false,
};

const user = (state = DEFAULT, action) => {
  switch (action.type) {
    case 'INVALIDATE_USER':
      localStorage.removeItem('role');
      localStorage.removeItem('roles');
      localStorage.removeItem('userAccount');
      return {
        ...DEFAULT,
        data: {},
        didInvalidate: true,
      };
    case 'REVALIDATE_USER':
      return {
        ...state,
        didInvalidate: false,
      };
    case 'REQUEST_USER':
      return {
        ...state,
        isFetching: true,
        loginError: false,
        fromLogin: true,
      };
    case 'RECEIVE_USER':
      const receivedRoles = orderBy(action.payload.data.roles, ['name']);
      if (receivedRoles.length === 1) {
        action.payload.data['role'] = receivedRoles[0];
      } else {
        delete action.payload.data.role;
      }
      Object.keys(action.payload.data).forEach(key => {
        if (typeof action.payload.data[key] === 'string') {
          localStorage.setItem(key, action.payload.data[key]);
        } else {
          localStorage.setItem(key, JSON.stringify(action.payload.data[key]));
        }
      });
      return {
        ...state,
        isFetching: false,
        didInvalidate: false,
        error: {},
        fromLogin: false,
        rolesError: false,
        ...action.payload,
      };
    case 'RECEIVE_ERROR':
      const { isFetching, ...error } = action.payload;
      return {
        ...DEFAULT,
        didInvalidate: true,
        error,
        loginError: state.fromLogin && true,
      };
    case 'CLEAR_ERRORS':
      return {
        ...state,
        error: {},
      };
    case 'USER_SET_ROLE':
      const {
        data,
        data: { roles },
      } = state;
      let role = {};
      if (!action.payload) {
        localStorage.removeItem('role');
      } else {
        role = roles.find(({ id }) => id === action.payload);
        localStorage.setItem('role', JSON.stringify(role));
      }

      return {
        ...state,
        data: {
          ...data,
          role,
        },
      };
    default:
      return state;
  }
};

export default user;
