import { Button } from 'semantic-ui-react';
import React from 'react';

const MenuButton = ({ children, ...props }) => (
  <Button
    style={{
      background: 'white',
      color: '#000000DE',
      minWidth: 200,
      textAlign: 'left',
    }}
    {...props}>
    {children}
  </Button>
);

export default MenuButton;
