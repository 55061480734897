import { Link, useParams } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';
import '../../assets/css/alert.css';
import {
  Button,
  Checkbox,
  Container,
  Form,
  Grid,
  Header,
  Input,
  Loader,
  Segment,
} from 'semantic-ui-react';
import '../../assets/css/assignment.css';
import Alert from '../Alert';
import { fetchDataIfNeeded, postData } from '../../actions/fetchData';
import { useArray, useDispatch, useObjectState, useToggle, useSelector } from 'hooks';

const EditUser = () => {
  const dispatch = useDispatch();
  const { userId: paramUserId } = useParams();
  const userId = parseInt(paramUserId, 10);

  const { data: { courseList = [], programList = [] } = {} } = useSelector(
    'filters'
  );
  const userDetail = useSelector('userDetail');
  const { firstName = '', lastName = '', email = '' } =
    userDetail?.data?.userAccount ?? {};
  const { isFetching } = userDetail ?? {};

  const [active, setActive] = useState(null);
  const [classFilters, ,setClassFiltersValue] = useObjectState({
    classroomTA: '',
    instructor: '',
  })
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [onlyCheckedInstructor, toggleOnlyCheckedInstructor] = useToggle(true);
  const [onlyCheckedTA, toggleOnlyCheckedTA] = useToggle(true);
  const [
    ,
    setRoleList,
    {
      flatBy: flatRoleList,
      includes: includesRoleList,
      push: pushRoleList,
      remove: removeRoleList,
    },
  ] = useArray([], {
    type: 'object',
  });
  const [
    selectedInstructorCourses,
    setSelectedInstructorCourses,
    {
      includes: selectedInstructorCoursesIncludes,
      toggle: toggleSelectedInstructorCourse,
    },
  ] = useArray([]);
  const [
    selectedCourses,
    setSelectedCourses,
    { includes: selectedCoursesIncludes, toggle: toggleSelectedCourse },
  ] = useArray([]);
  const [
    selectedPrograms,
    setSelectedPrograms,
    { toggle: toggleSelectedProgram },
  ] = useArray([]);
  const [success, setSuccess] = useState(false);

  const findUser = useCallback(
    userId => dispatch(fetchDataIfNeeded('userDetail', userId)),
    [dispatch]
  );

  const loadFilters = useCallback(
    () => dispatch(fetchDataIfNeeded('filters')),
    [dispatch]
  );

  const upsertUser = async payload => {
    const response = await postData(
      '/api/centralgrading/v1/upsertUser',
      payload
    );
    if (response.status !== 200) {
      let jsonResponse = await response.json();
      let errorMessage = null;
      if (jsonResponse.errorCode === 'BCS_ACCOUNT_MISSING') {
        errorMessage =
          'A new account with BCS courses needs to be created in Salesforce first.';
      } else if (jsonResponse.errorCode === 'BCS_COURSE_ENROLLMENT_MISSING') {
        errorMessage =
          "This account doesn't have the selected courses as enrollments in BCS and needs to be updated in Salesforce.";
      }
      setError(true);
      setErrorMessage(errorMessage);
    } else {
      setSuccess(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadFilters();
    findUser(userId);
  }, [findUser, loadFilters, userId]);

  useEffect(() => {
    const {
      data: {
        courses = [],
        instructorCourses = [],
        programs = [],
        userData,
        userData: { roles = [] } = {},
      } = {},
    } = userDetail ?? {};
    setActive(!!userData?.active);
    setRoleList(roles);
    setSelectedCourses((courses ?? []).map(({ id }) => id));
    setSelectedInstructorCourses((instructorCourses ?? []).map(({ id }) => id));
    setSelectedPrograms((programs ?? []).map(({ id }) => id));
  }, [
    setActive,
    setRoleList,
    setSelectedCourses,
    setSelectedInstructorCourses,
    setSelectedPrograms,
    userDetail,
  ]);

  const handleChange = role => {
    const roleExists = includesRoleList(role?.name, 'name');
    if (!roleExists) {
      pushRoleList(role);
    } else {
      removeRoleList('name', role?.name);
    }
    setErrorMessage(null);
    setError(null);
  };

  const handleActivateButton = () => {
    setActive(currentActive => !currentActive);
  };

  const hasRole = searchedRole => includesRoleList(searchedRole, 'name');

  const onCourseSelected = (item, value) => {
    toggleSelectedCourse(value?.value);
    setErrorMessage(null);
    setError(null);
  };

  const onInstructorCourseSelected = (item, value) => {
    toggleSelectedInstructorCourse(value?.value);
    setErrorMessage(null);
    setError(null);
  };

  const onProgramSelected = (item, value) => {
    toggleSelectedProgram(value?.value);
  };

  const onSubmitButtonClicked = () => {
    const { firstName, lastName, userName } =
      userDetail?.data?.userAccount ?? {};
    const payload = {
      active,
      courses: !includesRoleList('Classroom TA', 'name') ? [] : (selectedCourses ?? []),
      firstName,
      instructorCourses: !includesRoleList('Instructor', 'name') ? [] : (selectedInstructorCourses ?? []),
      lastName,
      programs: selectedPrograms,
      roleCodeList: flatRoleList('name'),
      username: userName,
    };

    setLoading(true);
    setError(false);
    setSuccess(false);
    upsertUser(payload);
  };

  const roleArray = [
    { id: 1, name: 'Central Grader' },
    { id: 2, name: 'Manager of Central Graders' },
    { id: 3, name: 'Classroom TA' },
    { id: 6, name: 'Instructor' },
    { id: 5, name: 'Support' },
  ];

  const classesContainerStyle = {
    color: '#333',
    maxHeight: 300,
    overflowY: 'auto',
  };

  const headerSectionStyle = {
    alignItems: 'center',
    display: 'flex',
  };

  if (isFetching) return <Loader active />;

  return (
    <div>
      <Container style={{ marginTop: '7em', width: '70%' }}>
        <Button
          as={Link}
          color="grey"
          to={{
            pathname: '/admin',
          }}>
          <i className="left arrow icon"></i>Back to Home
        </Button>
        <Segment style={{ border: '1px solid #ddd' }}>
          <Grid style={{ padding: '0.5em', background: '#fff' }}>
            <div style={{ padding: '0em', width: '100%' }}>
              <Grid.Row
                computer={16}
                mobile={16}
                tablet={16}
                style={{ padding: '1.5em', clear: 'both' }}>
                <div>
                  <Grid.Column
                    computer={12}
                    mobile={16}
                    tablet={16}
                    style={{ width: '88%', float: 'left' }}>
                    <Header size="large">Edit User</Header>
                  </Grid.Column>
                  <Grid.Column
                    computer={4}
                    mobile={16}
                    tablet={16}
                    style={{ width: '12%', float: 'right' }}>
                    <Button
                      basic
                      color={active ? 'orange' : 'green'}
                      onClick={handleActivateButton}
                      size="small">
                      {active ? 'Deactivate' : 'Activate'}
                    </Button>
                  </Grid.Column>
                </div>
              </Grid.Row>
              <Grid.Row
                computer={16}
                mobile={16}
                tablet={16}
                style={{ padding: '2em 1.5em', clear: 'both' }}>
                <div>
                  <Form>
                    <div>
                      <Grid.Column
                        computer={8}
                        mobile={16}
                        tablet={16}
                        style={{ float: 'left' }}>
                        <Form.Field style={{ float: 'left' }}>
                          <label>Email</label>
                          <input
                            placeholder="Email"
                            readOnly={true}
                            value={email}
                          />
                        </Form.Field>
                      </Grid.Column>
                    </div>
                  </Form>
                </div>
              </Grid.Row>
              <div>
                <Grid.Row
                  computer={16}
                  mobile={16}
                  tablet={16}
                  style={{
                    padding: '1em 1.5em',
                    marginTop: '3em',
                    borderTop: '1px solid #ddd',
                    background: '#fff',
                    height: '100px',
                  }}>
                  <React.Fragment>
                    <Form>
                      <div>
                        <Grid.Column
                          computer={6}
                          mobile={16}
                          tablet={16}
                          style={{ float: 'left' }}>
                          <Form.Field style={{ float: 'left' }}>
                            <label>First Name</label>
                            <input
                              placeholder="First Name"
                              readOnly={true}
                              value={firstName}
                            />
                          </Form.Field>
                        </Grid.Column>
                        <Grid.Column
                          computer={6}
                          mobile={16}
                          tablet={16}
                          style={{ float: 'left', marginLeft: '1em' }}>
                          <Form.Field style={{ float: 'left' }}>
                            <label>Last Name</label>
                            <input
                              placeholder="Last Name"
                              readOnly={true}
                              value={lastName}
                            />
                          </Form.Field>
                        </Grid.Column>
                      </div>
                    </Form>
                  </React.Fragment>
                </Grid.Row>
                <Grid.Row
                  computer={16}
                  mobile={16}
                  tablet={16}
                  style={{
                    padding: '1em 1.5em',
                    marginTop: '0em',
                    borderTop: '1px solid #ddd',
                    background: '#fff',
                    height: '250px',
                  }}>
                  <React.Fragment>
                    <Form>
                      <Form.Field>
                        Roles: {flatRoleList('name').join(' - ')}
                      </Form.Field>
                      {roleArray.map(curRole => (
                        <Form.Field key={curRole.id}>
                          <Checkbox
                            label={curRole.name}
                            name={curRole.name}
                            value={curRole.id}
                            checked={includesRoleList(curRole?.name, 'name')}
                            onClick={() => handleChange(curRole)}
                          />
                        </Form.Field>
                      ))}

                      {includesRoleList(
                        'Manager of Central Graders',
                        'name'
                      ) && (
                        <React.Fragment>
                          <Header size="small" style={{ color: '#333' }}>
                            Programs
                          </Header>
                          {programList &&
                            programList.map(p => (
                              <Checkbox
                                key={p.id}
                                style={{
                                  style: 'both',
                                  width: '100%',
                                  marginTop: '1em',
                                  value: p.id,
                                }}
                                label={p.name}
                                value={p.id}
                                checked={selectedPrograms.includes(p.id)}
                                onChange={onProgramSelected}
                              />
                            ))}
                        </React.Fragment>
                      )}

                      {includesRoleList('Classroom TA', 'name') && (
                        <React.Fragment>
                          <Header size="small" style={headerSectionStyle}>
                            Classroom TA - Classes
                            <Checkbox
                              toggle
                              checked={onlyCheckedTA}
                              name="only-checked-ta"
                              label="Only show checked"
                              style={{ marginLeft: 10, marginRight: 20 }}
                              onChange={toggleOnlyCheckedTA}
                            />
                            Filter:
                            <Input
                              style={{ height: 30, marginLeft: 10 }}
                              value={classFilters?.ta}
                              onChange={({ target: { value } }) =>
                                setClassFiltersValue('classroomTA', value)
                              }
                            />
                          </Header>
                          {courseList && (
                            <Container style={classesContainerStyle}>
                              {courseList.map(c => {
                                const checked = selectedCoursesIncludes(c.id);
                                if (!checked && onlyCheckedTA) return null;
                                if (classFilters?.classroomTA && (c?.name.toLowerCase()).indexOf(classFilters?.classroomTA?.toLowerCase() ) === -1) return null;
                                return (
                                  <Checkbox
                                    key={c.id}
                                    style={{
                                      style: 'both',
                                      width: '100%',
                                      marginTop: '1em',
                                    }}
                                    label={c.name}
                                    value={c.id}
                                    checked={checked}
                                    onChange={onCourseSelected}
                                  />
                                );
                              })}
                            </Container>
                          )}
                        </React.Fragment>
                      )}
                      {includesRoleList('Instructor', 'name') && (
                        <React.Fragment>
                          <Header size="small" style={headerSectionStyle}>
                            Instructor - Classes
                            <Checkbox
                              toggle
                              checked={onlyCheckedInstructor}
                              name="only-checked-instructor"
                              label="Only show checked"
                              style={{ marginLeft: 10, marginRight: 20 }}
                              onChange={toggleOnlyCheckedInstructor}
                            />
                            Filter:
                            <Input
                              style={{ height: 30, marginLeft: 10 }}
                              value={classFilters?.instructor}
                              onChange={({ target: { value } }) =>
                                setClassFiltersValue('instructor', value)
                              }
                            />
                          </Header>
                          {courseList && (
                            <Container style={classesContainerStyle}>
                              {courseList.map(c => {
                                const checked = selectedInstructorCoursesIncludes(
                                  c.id
                                );
                                if (!checked && onlyCheckedInstructor)
                                  return null;
                                if (
                                  classFilters?.instructor &&
                                  c?.name
                                    .toLowerCase()
                                    .indexOf(
                                      classFilters?.instructor?.toLowerCase()
                                    ) === -1
                                )
                                  return null;
                                return (
                                  <Checkbox
                                    key={c.id}
                                    style={{
                                      style: 'both',
                                      width: '100%',
                                      marginTop: '1em',
                                    }}
                                    label={c.name}
                                    value={c.id}
                                    checked={checked}
                                    onChange={onInstructorCourseSelected}
                                  />
                                );
                              })}
                            </Container>
                          )}
                        </React.Fragment>
                      )}

                      <div style={{ marginTop: '25px' }}>
                        {!!error && (
                          <Alert type="error">
                            {!!errorMessage
                              ? errorMessage
                              : 'There was an error. Please, try again.'}
                          </Alert>
                        )}
                        {!!success && (
                          <Alert type="success">
                            User updated successfully.
                          </Alert>
                        )}
                      </div>

                      <Grid.Column
                        computer={8}
                        mobile={16}
                        tablet={16}
                        style={{ float: 'right', marginTop: '25px' }}>
                        <Button
                          type="submit"
                          onClick={onSubmitButtonClicked}
                          disabled={loading}
                          loading={loading}
                          style={{
                            float: 'right',
                            background: hasRole('Classroom TA')
                              ? 'grey'
                              : 'blue',
                            color: '#fff',
                          }}>
                          {'Update'}
                          <i className="right arrow icon" />
                        </Button>
                      </Grid.Column>
                    </Form>
                  </React.Fragment>
                </Grid.Row>
              </div>
            </div>
          </Grid>
        </Segment>
      </Container>
    </div>
  );
};

export default EditUser;
