import React, { Component, createRef } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Menu } from 'semantic-ui-react';
import { fetchDataIfNeeded } from '../../actions/fetchData';
import SsmList from '../SsmList';
import moment from 'moment';
import TaMessages from '../TaMessages';
import Admin from '../Admin';

class AdminNavigator extends Component {
  contextRef = createRef();

  componentDidMount() {
    const { loadAdminFilters } = this.props;

    loadAdminFilters();
  }

  render() {
    const { receivedAt, location } = this.props;
    const userRole = this.props.user?.data?.role?.name;
    const isTaMessages =
      !!location && !!location.pathname && location.pathname === '/taMessages';
    const isSsm =
      !!location && !!location.pathname && location.pathname === '/ssm';
    const isAdmin =
      !!location && !!location.pathname && location.pathname === '/admin';

    return (
      <div key={receivedAt} ref={this.contextRef}>
        <Container
          style={{ marginTop: '87px', width: '80%', maxWidth: '1127px' }}>
          <Menu
            style={{
              border: 'none',
              borderBottom: '2px solid #e2e2e3',
              boxShadow: 'none',
            }}>
            <Link to={`/queue`}>
              <Menu.Item
                style={{
                  paddingTop: 18,
                  fontWeight: 'regular',
                }}>
                All
              </Menu.Item>
            </Link>
            {['Central Grader', 'Manager of Central Graders'].includes(
              userRole
            ) && (
              <Link to={`/claimed`}>
                <Menu.Item
                  style={{
                    paddingTop: 18,
                    fontWeight: 'regular',
                  }}>
                  Claimed by Me
                </Menu.Item>
              </Link>
            )}
            <Link
              to={`/overTargetTurnaround?endDate=${moment()
                .subtract(72, 'hours')
                .format()}`}>
              <Menu.Item
                style={{
                  paddingTop: 18,
                  fontWeight: 'regular',
                }}>
                Over Target Turnaround
              </Menu.Item>
            </Link>
            {['Manager of Central Graders'].includes(userRole) && (
              <Link to={'/taMessages'}>
                <Menu.Item
                  style={{
                    paddingTop: 18,
                    fontWeight: isTaMessages ? 'bold' : '',
                    textDecoration: isTaMessages ? 'underline' : '',
                  }}>
                  TA Messages
                </Menu.Item>
              </Link>
            )}
            {['Manager of Central Graders', 'Support'].includes(userRole) && (
              <Link to={'/admin'}>
                <Menu.Item
                  style={{
                    paddingTop: 18,
                    fontWeight: isAdmin ? 'bold' : '',
                    textDecoration: isAdmin ? 'underline' : '',
                  }}>
                  User Admin
                </Menu.Item>
              </Link>
            )}
            {['Manager of Central Graders', 'Support'].includes(userRole) && (
              <Link to={'/ssm'}>
                <Menu.Item
                  style={{
                    paddingTop: 18,
                    fontWeight: isSsm ? 'bold' : '',
                    textDecoration: isSsm ? 'underline' : '',
                  }}>
                  SSM list
                </Menu.Item>
              </Link>
            )}
          </Menu>
          {isTaMessages && <TaMessages />}
          {isSsm && <SsmList />}
          {isAdmin && <Admin />}
        </Container>
      </div>
    );
  }
}

export default withRouter(
  connect(
    state => {
      const { query, user } = state;

      return {
        query,
        user,
      };
    },
    dispatch => ({
      loadAdminFilters: () => {
        dispatch(fetchDataIfNeeded('adminFilters'));
      },
    })
  )(AdminNavigator)
);
